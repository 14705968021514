import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-apollo";
import Swal from "sweetalert2";

import { onQueryError, onMutationSuccessRedirect } from "utils/queryHandlers";

/**
 * Hook that exposes de logic to execute a given mutation,
 * with a confirmation modal.
 */
const useConfirmMutation = ({
  id,
  mutation,
  title,
  text,
  successMessage,
  redirectPath,
}) => {
  const [t] = useTranslation();

  const [mutate, {
    loading,
  }] = useMutation(mutation);

  const handleMutation = useCallback(() => {
    if (!id) {
      return;
    }

    Swal.fire({
      title,
      text,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: t("actions.confirm"),
      cancelButtonText: t("actions.cancel"),
    })
      .then((result) => {
        if (result.value) {
          mutate({
            variables: {
              id,
            },
          })
            .then(() => onMutationSuccessRedirect(successMessage, redirectPath))
            .catch(onQueryError);
        }
      });
  }, [
    mutate,
    id,
    t,
  ]);

  const payload = useMemo(() => ([
    handleMutation,
    {
      loading,
    },
  ]), [
    handleMutation,
    loading,
  ]);

  return payload;
};

export default useConfirmMutation;
