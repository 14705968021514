import gql from "graphql-tag";

const UPDATE_MAINTENANCE_ORDER_MUTATION = gql`
  mutation UpdateMaintenanceOrder($id: Int!, $params: UpdateMaintenanceOrderParams!){
    updateMaintenanceOrder(
      id: $id,
      params: $params,
    ) {
      id
    }
  }
`;

export default UPDATE_MAINTENANCE_ORDER_MUTATION;
