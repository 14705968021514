import gql from "graphql-tag";

const PURCHASE_ORDER_QUERY = gql`
  query PurchaseOrder($id: Int!) {
    purchaseOrder(id: $id) {
      id
      code
      status
      identification
      totalValue
      purchaseOrderItems {
        id
        finalQuantity
        totalValue
        priceQuoteProviderItem {
          id
          available
          discount
          ipi
          unitValue
          totalValue
          value
          priceQuoteItem {
            id
            totalQuantity
            inventoryItem {
              id
              name
              code
              unit {
                id
                unit
              }
            }
            serviceItem {
              id
              description
              serviceItemGroup {
                id
                name
              }
            }
            brand {
              id
              name
            }
          }
        }
      }
      priceQuoteProvider {
        id
        answeredAt
        lastSentAt
        provider {
          id
          phone
          email
          name
        }
        freight
        icms
        numberOfInstallments
        firstDueDate
        totalValue
        paymentMethod {
          id
          name
        }
      }
    }
  }
`;

export default PURCHASE_ORDER_QUERY;
