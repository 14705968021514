/**
 * Logs values to the console (only in development)
 *
 *
 * @param  {...any} args What will be logged into the console
 */
const log = (...args) => {
  if (process.env.NODE_ENV === "development") {
    // eslint-disable-next-line
    console.log(...args);
  }
};

export default log;
