import React from "react";
import {
  Card,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";

const MaintenanceCard = ({
  order,
}) => {
  const [t] = useTranslation();

  const {
    code,
    type,
    description,
    equipment,
    responsible,
  } = order;

  const firstName = responsible?.name?.split(" ")?.shift();

  return (
    <Card className="maintenance-card">
      <Card.Body>
        <div className="maintenance-header">
          <span>
            #
            {code}
          </span>
          <span>{t(`enums.maintenance_order.type.${type}`)}</span>
        </div>
        <div className="maintenance-description">
          <p>{description}</p>
        </div>
        <div className="maintenance-info">
          <span className="label">
            {`${t("attributes.equipment.code")}: `}
          </span>
          <span>{equipment?.code}</span>
        </div>
        <div className="maintenance-info">
          <span className="label">
            {`${t("attributes.maintenance_order.responsible")}: `}
          </span>
          <span>{firstName}</span>
        </div>
      </Card.Body>
    </Card>
  );
};

export default MaintenanceCard;
