import getEnumSelectOptions from "utils/getEnumSelectOptions";

export const PREVENTIVE = "preventive";
export const CORRECTIVE = "corrective";

const maintenanceOrderTypes = {
  PREVENTIVE,
  CORRECTIVE,
};

export const maintenanceOrderTypeOptions = getEnumSelectOptions(
  Object.values(maintenanceOrderTypes), "enums.maintenance_order.type",
);

export const getMaintenanceOrderType = (type) => (
  maintenanceOrderTypeOptions.find(({ value }) => value === type)
);

export default maintenanceOrderTypes;
