import gql from "graphql-tag";

export const PRICE_QUOTE_PROVIDER_FIELDS = gql`
  fragment priceQuoteProviderFields on PriceQuoteProvider {
    id
    answeredAt
    lastSentAt
    totalValue
    estimatedDeliveryDate
    firstDueDate
    freight
    icms
    observations
    priceQuoteProviderItems {
      id
      available
      value
      ipi
      discount
      unitValue
      totalValue
      providerBrand
      priceQuoteItem {
        id
      }
    }
    paymentMethod {
      id
      code
      name
    }
    provider {
      id
      phone
      email
      name
    }
  }
`;

export const PRICE_QUOTE_ITEMS_FIELDS = gql`
  fragment priceQuoteItemFields on PriceQuoteItem {
    id
    inventoryItem {
      id
      name
      code
      unit {
        id
        unit
      }
    }
    serviceItem {
      id
      description
      serviceItemGroup {
        id
        name
      }
    }
    brand {
      id
      name
    }
    totalQuantity
  }
`;
