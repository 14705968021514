import gql from "graphql-tag";

const SEND_PENDING_EMAILS_MUTATION = gql`
  mutation SendPendingPriceQuoteEmails($id: Int!) {
    sendPendingPriceQuoteEmails(id: $id) {
      id
    }
  }
`;

export default SEND_PENDING_EMAILS_MUTATION;
