import React, {
  useMemo,
} from "react";
import { useQuery } from "react-apollo";
import { useTranslation } from "react-i18next";

import Table from "components/Table/Table";
import SideModal from "components/SideModal";
import EmptyData from "components/EmptyData/EmptyData";

import { getItemName, getRequestItemHeaders } from "./getTableHeaders";
import GET_LAST_QUOTATIONS_QUERY from "./graphql/getLastQuotationsQuery";

const RequestItemModal = ({
  purchaseRequestItem,
  onModalClose,
}) => {
  const inventoryItemId = purchaseRequestItem?.inventoryItem?.id;
  const serviceItemId = purchaseRequestItem?.serviceItem?.id;
  const hasItem = (!!inventoryItemId || !!serviceItemId);

  const [t] = useTranslation();

  const headers = useMemo(getRequestItemHeaders, []);

  const {
    data,
    loading,
    error,
  } = useQuery(GET_LAST_QUOTATIONS_QUERY, {
    variables: {
      inventoryItemId,
      serviceItemId,
    },
    skip: !hasItem,
  });

  if (!data) {
    return (
      <EmptyData error={error} loading={loading} />
    );
  }

  return (
    <SideModal
      isOpen={!!purchaseRequestItem}
      onModalClose={onModalClose}
    >
      <h5>
        {getItemName(purchaseRequestItem)}
      </h5>

      <h6>{t("purchase_requests.last_quotations")}</h6>

      <Table
        headers={headers}
        items={data?.getLastQuotations}
        emptyMessage={t("purchase_requests.empty_quotations")}
      />
    </SideModal>
  );
};

export default RequestItemModal;
