import React, {
  useState,
  useCallback,
} from "react";
import {
  Button,
  Modal,
  Row,
  Col,
  Table,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import {
  FormInput,
  FormArrayField,
  Form,
  useFormup,
} from "@formup/core";
import { useMutation } from "react-apollo";

import CurrencyInput from "components/Inputs/CurrencyInput/CurrencyInput";
import extractInventoryChangeInfo from "utils/extractors/extractInventoryChangeInfo";
import { useSnackbar } from "contexts/snackbar/SnackbarContext";
import { onQueryError } from "utils/queryHandlers";
import {
  FINISHED,
} from "constants/maintenanceActivityStatus";
import { EXTERNAL, INTERNAL } from "constants/maintenanceActivityKinds";
import { formatDate, fullDateMask } from "utils/dateFormats";
import TextInput from "components/Inputs/TextInput/TextInput";
import { MAINTENANCES_DETAIL_LOCATION } from "router/locations";
import MAINTENANCE_ORDER_SUMMARY_QUERY from "views/MaintenanceOrderSummary/graphql/maintenanceOrderQuery";
import MAINTENANCE_ORDER_QUERY from "views/MaintenanceOrderDetails/graphql/maintenanceOrderQuery";
import MAINTENANCE_ORDERS_QUERY from "views/MaintenanceBoards/graphql/maintenanceOrdersQuery";
import maintenanceStatus from "constants/maintenanceStatus";

import END_MAINTENANCE_ACTIVITY_MUTATION from "./graphql/endMaintenanceActivityMutation";
import END_EXTERNAL_MAINTENANCE_ACTIVITY_MUTATION from "./graphql/endExternalMaintenanceActivityMutation";
import schema from "./schema";
import InventoryChangesForm from "./InventoryChangesForm";

const EndActivityAction = ({
  activity,
  currentKind = INTERNAL,
}) => {
  const notify = useSnackbar();
  const history = useHistory();
  const [t] = useTranslation();
  const [open, setOpen] = useState(false);

  const {
    id,
    kind,
    status,
    maintenanceOrderId,
  } = activity;

  const handleOpenModal = () => setOpen(true);

  const closeModal = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const [endMaintenanceActivity, {
    loading: endMaintenanceLoading,
  }] = useMutation(END_MAINTENANCE_ACTIVITY_MUTATION);
  const [endExternalMaintenanceActivity, {
    loading: endExternalMaintenanceLoading,
  }] = useMutation(END_EXTERNAL_MAINTENANCE_ACTIVITY_MUTATION);

  const isExternal = currentKind === EXTERNAL;
  const isLoading = endMaintenanceLoading || endExternalMaintenanceLoading;

  const mutation = isExternal
    ? endExternalMaintenanceActivity
    : endMaintenanceActivity;

  const handleSubmitForm = useCallback(({
    comments,
    inventoryChanges,
  }) => {
    mutation({
      variables: {
        id,
        comments: isExternal ? undefined : comments,
        inventoryChanges,
      },
      refetchQueries: [
        {
          query: MAINTENANCE_ORDER_QUERY,
          variables: { id: maintenanceOrderId },
        },
        {
          query: MAINTENANCE_ORDER_SUMMARY_QUERY,
          variables: { id: maintenanceOrderId },
        },
        {
          query: MAINTENANCE_ORDERS_QUERY,
          variables: { status: maintenanceStatus.IN_EXECUTION },
        },
        {
          query: MAINTENANCE_ORDERS_QUERY,
          variables: { status: maintenanceStatus.FINISHED },
        },
      ],
    })
      .then(() => {
        notify.success(t("maintenance_activity_details.successfully_ended"));
        closeModal();

        if (!isExternal) {
          history.push(MAINTENANCES_DETAIL_LOCATION.toUrl({
            id: maintenanceOrderId,
          }));
        }
      })
      .catch(onQueryError);
  }, [
    t,
    id,
    maintenanceOrderId,
    mutation,
    notify,
    closeModal,
    history,
    isExternal,
  ]);

  const inventoryChanges = extractInventoryChangeInfo(
    activity?.maintenanceActivity?.maintenanceMaterials,
  );

  const {
    formikForm,
    submitForm,
  } = useFormup(schema, {
    onSubmit: handleSubmitForm,
    transformOnSubmit: true,
    initialValues: {
      comments: "",
      inventoryChanges,
    },
  });

  if (status === FINISHED || kind !== currentKind) {
    return null;
  }

  return (
    <>
      {
        isExternal
          ? (
            <CurrencyInput
              value={activity?.inventoryCost}
              displayType="text"
              className="clickable primary"
              onClick={handleOpenModal}
            />
          )
          : (
            <Button
              variant="primary"
              onClick={handleOpenModal}
            >
              {t("maintenance_activity_details.end_activity")}
            </Button>
          )
      }

      <Form formikForm={formikForm}>
        <Modal
          show={open}
          onHide={closeModal}
          backdrop="static"
          size="xl"

        >
          <Modal.Header>
            <Modal.Title>
              {t("maintenance_activity_details.end_activity")}
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>

            {
              !isExternal && (
                <Row>
                  <Col md={12} className="model-details">
                    <span>{t("attributes.maintenance_activity.end_time")}</span>
                    <h5>{formatDate(new Date(), fullDateMask)}</h5>
                  </Col>
                </Row>
              )
            }

            <Row>
              <Col md={12} className="model-details">
                <h5>{t("maintenance_activity_details.confirm_inventory_changes")}</h5>

                <Table>
                  <thead>
                    <tr>
                      <th>
                        {t("attributes.inventory_item.code")}
                      </th>
                      <th>
                        {t("attributes.inventory_item.name")}
                      </th>
                      <th>
                        {t("attributes.inventory_item.brand")}
                      </th>
                      <th>
                        {t("attributes.maintenance_material.quantity")}
                      </th>
                      <th>
                        {t("attributes.maintenance_material.unit")}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <FormArrayField name="inventoryChanges">
                      <InventoryChangesForm />
                    </FormArrayField>
                  </tbody>
                </Table>
              </Col>
            </Row>

            {
              !isExternal && (
                <Row>
                  <Col md={12} className="model-details">
                    <FormInput
                      name="comments"
                      component={TextInput}
                      injectFormupData
                    />
                  </Col>
                </Row>
              )
            }
          </Modal.Body>

          <Modal.Footer>
            <Button
              variant="outline-primary"
              onClick={closeModal}
            >
              {t("actions.close")}
            </Button>

            <Button
              variant="primary"
              onClick={submitForm}
              disabled={isLoading}
            >
              {t("actions.finish")}
            </Button>
          </Modal.Footer>
        </Modal>
      </Form>
    </>
  );
};

export default EndActivityAction;
