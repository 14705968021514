/**
 * Converts a result query data array into select options.
 *
 * @param {array} queryItems
 * @param {function} labelName
 * @param {string} idName
 */
const getQuerySelectOptions = (queryItems, labelFormatter, idName = "id") => (
  (queryItems ?? []).map((queryItem) => (
    {
      value: queryItem[idName],
      label: labelFormatter(queryItem),
    }
  ))
);

export default getQuerySelectOptions;
