import React from "react";
import { configureScope, captureException, showReportDialog } from "@sentry/browser";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error });

    configureScope(scope => {
      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, errorInfo[key]);
      });
    });
    captureException(error);
  }

  showReportDialogFunction = () => (process.env.NODE_ENV === "production" ? () => showReportDialog() : null);

  render() {
    if (this.state.error) {
      return (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <a onClick={this.showReportDialogFunction()}>Report feedback</a>
      );
    }
    // when there's not an error, render children untouched
    return this.props.children;
  }
}

export default ErrorBoundary;
