
/**
 * Transforms text blank inputs "" into null fields.
 *
 * @param {*} value
 * @param {*} originalValue
 */
const nullableTransform = (value, originalValue) => ((originalValue ?? "").trim() === "" ? null : value);

export const nullableIntegerTransform = (value, originalValue) => {
  const parsedValue = nullableTransform(value, originalValue);

  return parsedValue === null ? null : parseInt(parsedValue);
};

export default nullableTransform;
