import gql from "graphql-tag";

const PRICE_QUOTE_PROVIDER_QUERY = gql`
  query PriceQuoteProvider($id: Int!) {
    priceQuoteProvider(id: $id) {
      id
      answeredAt
      lastSentAt
      provider {
        id
        phone
        email
        name
      }
      freight
      icms
      numberOfInstallments
      firstDueDate
      totalValue
      paymentMethod {
        id
        name
      }
      priceQuoteProviderItems {
        id
        available
        discount
        ipi
        unitValue
        totalValue
        value
        priceQuoteItem {
          id
          totalQuantity
          inventoryItem {
            id
            name
            code
            unit {
              id
              unit
            }
          }
          serviceItem {
            id
            description
            serviceItemGroup {
              id
              name
            }
          }
          brand {
            id
            name
          }
        }
      }
    }
  }
`;

export default PRICE_QUOTE_PROVIDER_QUERY;
