import gql from "graphql-tag";

const PRICE_QUOTE_QUERY = gql`
  query PriceQuote($id: Int!) {
    priceQuote(id: $id) {
      id
      code
      canCreateOrders
      responsible {
        id
        name
      }
      deliveryDeadline
      address {
        id
        fullAddress
      }
      company {
        id
        companyName
        cnpj
      }
      priceQuoteItems {
        id
        inventoryItem {
          id
          name
          code
          unit {
            id
            unit
          }
        }
        serviceItem {
          id
          description
          serviceItemGroup {
            id
            name
          }
        }
        brand {
          id
          name
        }
        totalQuantity
      }
      priceQuoteProviders {
        id
        answeredAt
        lastSentAt
        provider {
          id
          phone
          email
          name
        }
      }
      purchaseOrders {
        id
        status
        code
        identification
      }
    }
  }
`;

export default PRICE_QUOTE_QUERY;
