import React from "react";
import {
  Card,
  Table,
} from "react-bootstrap";
import { useQuery } from "react-apollo";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import EmptyData from "components/EmptyData/EmptyData";
import StatusIcon from "components/StatusIcon/StatusIcon";
import { DAILY_CHECKLISTS_PAGE_LOCATION } from "router/locations";

import DAILY_CHECKLISTS_QUERY from "./graphql/dailyChecklistsQuery";

const DailyChecklistsCard = () => {
  const [t] = useTranslation();

  const {
    data,
    loading,
    error,
  } = useQuery(DAILY_CHECKLISTS_QUERY);

  if (!data) {
    return (
      <EmptyData
        error={error}
        loading={loading}
      />
    );
  }

  return (
    <Card>
      <Card.Body>
        <h4 className="card-title">{t("dashboard.daily_checklists.title")}</h4>

        <Table hover responsive>
          <thead>
            <tr>
              <th>{t("attributes.shared.equipment_code")}</th>
              <th>{t("attributes.equipment.type")}</th>
              <th>{t("attributes.equipment.detail")}</th>
              <th>{t("attributes.daily_checklists.operator")}</th>
              <th className="text-center">
                {t("attributes.daily_checklists.status")}
              </th>
              <th className="text-center">
                {t("attributes.daily_checklists.details")}
              </th>
            </tr>
          </thead>
          <tbody>
            {
              data.dailyChecklists.length
                ? (
                  data.dailyChecklists.map(({
                    id,
                    equipment: {
                      equipmentType,
                      equipmentDetail,
                      code,
                      status,
                    },
                    operator,
                  }) => (
                    <tr key={id}>
                      <td>
                        {code}
                      </td>
                      <td>
                        {equipmentType?.name}
                      </td>
                      <td>
                        {equipmentDetail?.name}
                      </td>
                      <td>
                        {operator?.name}
                      </td>
                      <td>
                        <StatusIcon status={status} />
                      </td>
                      <td className="text-center">
                        <Link to={DAILY_CHECKLISTS_PAGE_LOCATION.toUrl({ id })}>
                          {t("actions.see_details")}
                        </Link>
                      </td>
                    </tr>
                  ))
                )
                : (
                  <tr>
                    <td className="text-center" colSpan="6">
                      {t("dashboard.daily_checklists.empty_data")}
                    </td>
                  </tr>
                )
            }
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
};

export default DailyChecklistsCard;
