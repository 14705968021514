import React, {
  useMemo,
  useCallback,
} from "react";
import { useFormup } from "@formup/core";
import { useTranslation } from "react-i18next";

import { useSnackbar } from "contexts/snackbar/SnackbarContext";

import { FormProvider } from "./FormContext";
import { maintenanceOrderSchema } from "./schemas";

const FormContainer = ({
  children,
  handleSubmitForm,
  initialValues,
}) => {
  const [t] = useTranslation();
  const notify = useSnackbar();

  const {
    formikForm,
    submitForm,
  } = useFormup(maintenanceOrderSchema, {
    onSubmit: handleSubmitForm,
    transformOnSubmit: true,
    initialValues,
  });

  const onSubmit = useCallback(() => {
    const errors = formikForm?.errors;
    const hasError = !!Object.keys(errors).length;

    if (hasError) {
      notify.error(t("errors.please_check_if_your_form_is_correct"));
      return;
    }

    submitForm();
  }, [
    submitForm,
    formikForm,
    notify,
    t,
  ]);

  const payload = useMemo(() => ({
    formikForm,
    submitForm: onSubmit,
    initialValues,
  }), [
    formikForm,
    onSubmit,
    initialValues,
  ]);

  return (
    <FormProvider value={payload}>
      {children}
    </FormProvider>
  );
};

export default FormContainer;
