import React, { useCallback } from "react";
import {
  Container,
  Card,
  Row,
  Col,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "react-apollo";
import { useHistory, useParams } from "react-router-dom";

import extractPurchaseRequestItems from "utils/extractors/extractPurchaseRequestItems";
import { useSnackbar } from "contexts/snackbar/SnackbarContext";
import { onQueryError } from "utils/queryHandlers";
import EmptyData from "components/EmptyData/EmptyData";
import { MAINTENANCES_PATH } from "router/routes";
import extractMaintenanceOrderInfo from "utils/extractors/extractMaintenanceOrderInfo";
import MAINTENANCE_ORDERS_QUERY from "views/MaintenanceOrders/graphql/maintenanceOrdersQuery";

import MaintenanceForm from "./MaintenanceForm";
import UPDATE_MAINTENANCE_ORDER_MUTATION from "./graphql/updateMaintenanceOrderMutation";
import CREATE_PURCHASE_REQUEST_MUTATION from "./graphql/createPurchaseRequestMutation";
import MAINTENANCE_ORDER_QUERY from "./graphql/maintenanceOrderQuery";
import filterMaintenanceParams from "./filterMaintenanceParams";

const MaintenanceEdit = () => {
  const history = useHistory();
  const [t] = useTranslation();
  const notify = useSnackbar();
  const { id } = useParams();

  const {
    data,
    loading,
    error,
  } = useQuery(MAINTENANCE_ORDER_QUERY, {
    variables: {
      id: parseInt(id),
    },
    fetchPolicy: "no-cache",
    skip: !id,
  });

  const initialValues = extractMaintenanceOrderInfo(data?.maintenanceOrder);

  const [updateMaintenance, {
    loading: updatingMaintenanceOrder,
  }] = useMutation(UPDATE_MAINTENANCE_ORDER_MUTATION);

  const [createPurchaseRequest, {
    loading: creatingPurchaseRequest,
  }] = useMutation(CREATE_PURCHASE_REQUEST_MUTATION);

  const onSuccess = useCallback(() => {
    notify.success(t("edit_maintenance.success"));
    history.push(MAINTENANCES_PATH);
  }, [
    notify,
    history,
    t,
  ]);

  const handleSubmitForm = useCallback(({
    equipmentId: _equipmentId,
    ...params
  }) => {
    const purchaseRequestItems = extractPurchaseRequestItems(params?.activities);
    const permittedParams = filterMaintenanceParams(params);

    if (purchaseRequestItems.length) {
      Promise
        .all([
          updateMaintenance({
            variables: {
              id: parseInt(id),
              params: permittedParams,
            },
            refetchQueries: [
              { query: MAINTENANCE_ORDERS_QUERY },
            ],
          }),
          createPurchaseRequest({
            variables: {
              params: {
                maintenanceOrderId: parseInt(id),
                purchaseRequestItems,
              },
            },
            refetchQueries: [
              { query: MAINTENANCE_ORDERS_QUERY },
            ],
          }),
        ])
        .then(onSuccess)
        .catch(onQueryError);

      return;
    }

    updateMaintenance({
      variables: {
        id: parseInt(id),
        params: permittedParams,
      },
    })
      .then(onSuccess)
      .catch(onQueryError);
  }, [
    updateMaintenance,
    createPurchaseRequest,
    onSuccess,
    id,
  ]);

  if (!data) {
    return (
      <EmptyData
        error={error}
        loading={loading}
      />
    );
  }

  return (
    <Container>
      <Row>
        <Col md={12}>
          <Card>
            <Card.Body>
              <h4 className="card-title">{t("edit_maintenance.title")}</h4>

              <MaintenanceForm
                handleSubmitForm={handleSubmitForm}
                initialValues={initialValues}
                loading={updatingMaintenanceOrder || creatingPurchaseRequest}
                isEdit
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default MaintenanceEdit;
