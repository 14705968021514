import { useQuery } from "react-apollo";

import HAS_PERMISSION_QUERY from "./graphql/hasPermissionQuery";

/**
 * Scoping validates if the current user has the needed permission to see the children content.
 * If a given user does not have the required permission, the content should not be displayed.
 *
 * @param {string} permission A permission from "constants/permissions.json"
 * @param {*} children The children content that may be displayed
 * @param {boolean} skipScope When true, content is always displayed
 */
const Scoping = ({
  permission,
  children,
  skipScope = false,
}) => {
  const { data } = useQuery(HAS_PERMISSION_QUERY, {
    variables: {
      permission,
    },
    skipScope: !permission,
  });

  const hasPermission = permission && data?.hasPermission;

  if (skipScope || hasPermission) {
    return children;
  }

  return null;
};

export default Scoping;
