import gql from "graphql-tag";

const SEND_PURCHASE_ORDER_MUTATION = gql`
  mutation SendPurchaseOrder($id: Int!) {
    sendPurchaseOrder(id: $id) {
      id
    }
  }
`;

export default SEND_PURCHASE_ORDER_MUTATION;
