import { getErrorMessage } from "apollo-sentry-helper";

import history from "settings/history";
import i18n from "translations/i18n";
import { notify } from "contexts/snackbar/SnackbarContainer";

/**
 * Shows an error snackbar displaying the contents of the query error.
 * @param {*} error Query error
 * @param {*} notify Snackbar notify function
 */
export const onQueryError = (error) => {
  notify.error(getErrorMessage(error));
};

/**
 * Shows a snackbar according to network status.
 * @param {*} message Success message
 * @param {*} notify Snackbar notify function
 */
export const onMutationSuccess = (
  message,
) => {
  if (!navigator.onLine) {
    notify.info(i18n.t("actions.you_are_offline"));
  } else if (message) {
    notify.success(message);
  }
};

/**
 * Shows a snackbar according to network status and redirects the
 * user to a specified path.
 * @param {*} message Success message
 * @param {*} redirectPath Path to redirect
 */
export const onMutationSuccessRedirect = (
  message,
  redirectPath,
) => {
  onMutationSuccess(message);
  history.push(redirectPath);
};

/**
 * Shows a snackbar according to network status and goes back
 * to the previous visited path.
 * @param {*} message Success message
 */
export const onMutationSuccessGoBack = (
  message,
) => {
  onMutationSuccess(message, notify);
  history.goBack();
};
