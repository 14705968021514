import React from "react";
import { MdKeyboardArrowUp, MdKeyboardArrowDown } from "react-icons/md";

import SortingOrder, { getNextSortingOrder } from "constants/sortingOrder";

const SortingHeader = ({
  label,
  sortingColumn,
  sorting = {},
  onSortingChange,
}) => {
  const isSortable = !!onSortingChange && !!sortingColumn;
  const isSorting = sorting.orderBy === sortingColumn && !!sorting.sortingOrder;

  const handleClick = () => {
    if (!isSortable) {
      return;
    }

    const sortingOrder = getNextSortingOrder(sorting.sortingOrder);

    if (sortingColumn === sorting.orderBy) {
      onSortingChange({
        orderBy: sortingOrder ? sortingColumn : undefined,
        sortingOrder,
      });
    } else {
      onSortingChange({
        orderBy: sortingColumn,
        sortingOrder: SortingOrder.ASC,
      });
    }
  };

  return (
    <th
      className={isSortable ? "clickable" : undefined}
      onClick={handleClick}
    >
      {label}

      {
        isSortable && (
          isSorting && (
            <>
              {
                sorting.sortingOrder === SortingOrder.ASC
                  ? <MdKeyboardArrowDown size="1rem" className="ml-2" />
                  : <MdKeyboardArrowUp size="1rem" className="ml-2" />
              }
            </>
          )
        )
      }
    </th>
  );
};

export default SortingHeader;
