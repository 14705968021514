import gql from "graphql-tag";

const START_MAINTENANCE_ACTIVITY_MUTATION = gql`
  mutation StartMaintenanceActivity($id: Int!, $operatorId: Int!){
    startMaintenanceActivity(
      id: $id,
      operatorId: $operatorId,
    ) {
      id
    }
  }
`;

export default START_MAINTENANCE_ACTIVITY_MUTATION;
