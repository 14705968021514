
/**
 * Extracts `inventoryItemIds` and `serviceItemIds` variables
 * from an array of purchase request items.
 *
 * To be used in listItemProvidersQuery`.
 *
 * @param {Array<PurchaseRequestItems>} requests
 * @returns A variable object for `listItemProvidersQuery`.
 *
 * Example:
 * {
 *   inventoryItemIds: [1, 2, 3],
 *   serviceItemIds: []
 * }
 */
const extractListItemProvidersVariables = (requests) => ({
  inventoryItemIds: requests
    .filter(({ inventoryItem }) => !!inventoryItem)
    .map(({ inventoryItem }) => inventoryItem.id),
  serviceItemIds: requests
    .filter(({ serviceItem }) => !!serviceItem)
    .map(({ serviceItem }) => serviceItem.id),
});

export default extractListItemProvidersVariables;
