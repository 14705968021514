import gql from "graphql-tag";

const CREATE_PURCHASE_REQUEST_MUTATION = gql`
  mutation CreatePurchaseRequest($params: CreatePurchaseRequestParams!){
    createPurchaseRequest(
      params: $params,
    ) {
      id
    }
  }
`;

export default CREATE_PURCHASE_REQUEST_MUTATION;
