import gql from "graphql-tag";

const GET_LAST_QUOTATIONS_QUERY = gql`
query GetLastQuotations($inventoryItemId: Int, $serviceItemId: Int){
  getLastQuotations(inventoryItemId: $inventoryItemId, serviceItemId: $serviceItemId) {
    id
    unitValue
    priceQuoteItem {
      id
      brand {
        id
        name
      }
    }
    provider {
      id
      name
    }
  }
}
`;

export default GET_LAST_QUOTATIONS_QUERY;
