import gql from "graphql-tag";

const EQUIPMENT_LAST_MAINTENANCES_QUERY = gql`
  query EquipmentLastMaintenancesQuery($id: Int!) {
    equipment(id: $id) {
      id
      lastMaintenances {
        id
        responsible {
          id
          name
        }
        type
        code
        description
        closingDate
      }
    }
  }
`;

export default EQUIPMENT_LAST_MAINTENANCES_QUERY;
