import { useState, useCallback, useMemo } from "react";
import { useQuery } from "react-apollo";

import INVENTORY_ITEM_QUERY from "views/Maintenances/graphql/inventoryItemQuery";

const useInventoryQuantity = () => {
  const [availableQuantities, setAvailableQuantities] = useState({});

  const { refetch } = useQuery(INVENTORY_ITEM_QUERY, {
    skip: true,
    fetchPolicy: "no-cache",
  });

  const fetchAvailableQuantity = useCallback((value) => (
    new Promise((resolve, reject) => {
      refetch({
        id: value.inventoryItemId,
        brandId: value.brandId,
      })
        .then(({ data }) => {
          resolve({
            ...value,
            ...(data?.inventoryItem || {}),
          });
        })
        .catch(reject);
    })
  ), [
    refetch,
  ]);

  const fetchAvailableQuantityEventHandler = useCallback((
    inventoryItemId,
    brandId,
  ) => (event, name) => {
    const path = name?.split(".") || [];
    const type = path[path.length - 1];

    const value = {
      inventoryItemId,
      brandId,
      [type]: event?.value,
    };

    if (!value.inventoryItemId) {
      return;
    }

    refetch({
      id: value.inventoryItemId,
      brandId: value.brandId,
    })
      .then(({ data }) => {
        setAvailableQuantities({
          ...availableQuantities,
          [String(value.inventoryItemId)]: {
            ...availableQuantities[inventoryItemId] || {},
            [String(value.brandId)]: data?.inventoryItem || {},
          },
        });
      })
      .catch(() => {});
  }, [
    refetch,
    availableQuantities,
    setAvailableQuantities,
  ]);

  const payload = useMemo(() => ({
    availableQuantities,
    fetchAvailableQuantityEventHandler,
    fetchAvailableQuantity,
  }), [
    availableQuantities,
    fetchAvailableQuantityEventHandler,
    fetchAvailableQuantity,
  ]);

  return payload;
};

export default useInventoryQuantity;
