import gql from "graphql-tag";

const CREATE_PURCHASE_ORDER_MUTATION = gql`
  mutation CreatePurchaseOrder($params: CreatePurchaseOrderParams!) {
    createPurchaseOrder(params: $params) {
      id
    }
  }
`;

export default CREATE_PURCHASE_ORDER_MUTATION;
