/**
 * Transforms a value from a Select component, extracting the
 * selected value and coalescing to the value object itself.
 *
 * E.g.
 * { value: "foo", label: "bar" } -> "foo"
 *
 * @param {*} _value The value
 * @param {*} originalValue The original value on the form
 */

const selectTransform = (_value, originalValue) => (
  originalValue?.value ?? originalValue
);

export default selectTransform;
