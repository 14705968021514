import gql from "graphql-tag";

const BRANDS_QUERY = gql`
  query Brands($inventoryItemId: Int!, $search: String) {
    brands(inventoryItemId: $inventoryItemId, search: $search) {
      id
      name
    }
  }
`;

export default BRANDS_QUERY;
