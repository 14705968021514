/**
 * These constants allows param conversion from backend to frontend.
 *
 * They do not follow uppercase convention to allow object access by name.
 */

export const dia = "day";

export const hora = "hour";

export const km = "km";

export const litro = "liter";

export const m3 = "m3";

export default {
  dia,
  hora,
  km,
  litro,
  m3,
};
