import React from "react";
import {
  Container,
  Card,
  Row,
  Col,
} from "react-bootstrap";
import { useQuery } from "react-apollo";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { formatDate } from "utils/dateFormats";
import EquipmentDetailsCard from "components/EquipmentDetailsCard/EquipmentDetailsCard";
import EmptyData from "components/EmptyData/EmptyData";

import MAINTENANCE_ORDER_QUERY from "./graphql/maintenanceOrderQuery";
import MaintenanceActivities from "./MaintenanceActivities";

const MaintenanceOrderDetails = () => {
  const [t] = useTranslation();
  const { id } = useParams();

  const {
    data,
    loading,
    error,
  } = useQuery(MAINTENANCE_ORDER_QUERY, {
    variables: {
      id: parseInt(id),
    },
    skip: !id,
  });

  const maintenanceOrder = data?.maintenanceOrder;

  return (
    <Container>
      <Row>
        <Col md={12}>
          <EquipmentDetailsCard equipmentId={maintenanceOrder?.equipment?.id} />
        </Col>
      </Row>

      <Row>
        <Col md={12}>
          <Card>
            <Card.Body>
              <h4 className="card-title">
                {t("maintenance_order_details.title")}
              </h4>

              {
                maintenanceOrder
                  ? (
                    <>
                      <Row>
                        <Col md={2} className="model-details">
                          <span>{t("attributes.maintenance_order.code")}</span>
                          <h5>{maintenanceOrder?.code}</h5>
                        </Col>

                        <Col md={3} className="model-details">
                          <span>{t("attributes.maintenance_order.type")}</span>
                          <h5>{t(`enums.maintenance_order.type.${maintenanceOrder?.type}`)}</h5>
                        </Col>

                        <Col md={3} className="model-details">
                          <span>{t("attributes.maintenance_order.closing_date")}</span>
                          <h5>{formatDate(maintenanceOrder?.closingDate)}</h5>
                        </Col>

                        <Col md={4} className="model-details">
                          <span>{t("attributes.maintenance_order.responsible")}</span>
                          <h5>{maintenanceOrder?.responsible?.name}</h5>
                        </Col>
                      </Row>

                      <Row>
                        <Col md={12} className="model-details">
                          <span>{t("attributes.maintenance_order.description")}</span>
                          <h5>{maintenanceOrder?.description}</h5>
                        </Col>
                      </Row>

                      <Row>
                        <Col md={12} className="model-details">
                          <span>{t("attributes.maintenance_order.comments")}</span>
                          <pre>{maintenanceOrder?.comments || "-"}</pre>
                        </Col>
                      </Row>

                      <Row>
                        <Col md={12}>
                          <MaintenanceActivities activities={maintenanceOrder?.activities} />
                        </Col>
                      </Row>
                    </>
                  )
                  : (
                    <EmptyData
                      error={error}
                      loading={loading}
                    />
                  )
              }
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default MaintenanceOrderDetails;
