import i18n from "translations/i18n";
import yup from "settings/yup";

const createProviderSchema = yup
  .object()
  .shape({
    name: yup
      .string()
      .required()
      .label(i18n.t("attributes.provider.name")),
    cpfCnpj: yup
      .string()
      .required()
      .label(i18n.t("attributes.provider.cpf_cnpj")),
    address: yup
      .string()
      .label(i18n.t("attributes.provider.address")),
    number: yup
      .string()
      .label(i18n.t("attributes.provider.number")),
    complement: yup
      .string()
      .label(i18n.t("attributes.provider.complement")),
    neighborhood: yup
      .string()
      .label(i18n.t("attributes.provider.neighborhood")),
    city: yup
      .string()
      .label(i18n.t("attributes.provider.city")),
    state: yup
      .string()
      .label(i18n.t("attributes.provider.state")),
    cep: yup
      .string()
      .label(i18n.t("attributes.provider.cep")),
    ie: yup
      .string()
      .label(i18n.t("attributes.provider.ie")),
    phone: yup
      .string()
      .label(i18n.t("attributes.provider.phone")),
    email: yup
      .string()
      .email()
      .label(i18n.t("attributes.provider.email")),
  });

export default createProviderSchema;
