import gql from "graphql-tag";

const MAINTENANCE_ORDER_QUERY = gql`
  query MaintenanceOrderQuery($id: Int!) {
    maintenanceOrder(id: $id) {
      id
      code
      type
      description
      closingDate
      comments
      responsible {
        id
        name
      }
      equipment {
        id
      }
      activities {
        id
        number
        kind
        status
        maintenanceActivity {
          id
          serviceItem {
            id
            description
            serviceItemGroup {
              id
              name
            }
          }
          maintenanceMaterials {
            id
            quantity
            isMissing
            inventoryItem {
              id
              code
              name
              unit {
                id
                unit
              }
            }
          }
        }
      }
    }
  }
`;

export default MAINTENANCE_ORDER_QUERY;
