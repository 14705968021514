import React from "react";
import { useTranslation } from "react-i18next";
import { ListGroup } from "react-bootstrap";
import { getErrorsList } from "apollo-sentry-helper";

import log from "utils/logger";
import Loader from "components/Loader/Loader";

const EmptyData = ({
  showError = true,
  loading,
  error,
}) => {
  if (error) {
    log("GraphQL error in EmptyData", error);
  }

  const [t] = useTranslation();

  const networkError = error?.networkError;

  const graphQLErrors = error?.graphQLErrors || (
    Array.isArray(error)
    && error.length
    && error
  ) || [];

  return (
    <div className="empty-data">
      {
        loading && (
          <Loader />
        )
      }
      {
        showError
        && error
        && (
          <div className="error-container">
            <h3>{t("errors.an_error_occurred")}</h3>

            {
              networkError && (
                <div className="network-error-container">
                  <p className="text-center">
                    {t("errors.you_dont_have_internet")}
                  </p>
                  <p className="text-center">
                    {t("errors.check_your_connection_and_try_again")}
                  </p>
                </div>
              )
            }

            {
              graphQLErrors && (
                <ListGroup className="w-100">
                  {
                    getErrorsList(graphQLErrors).map((message) => (
                      <ListGroup.Item className="text-center" key={message}>
                        {message}
                      </ListGroup.Item>
                    ))
                  }
                </ListGroup>
              )
            }
          </div>
        )
      }
    </div>
  );
};

export default EmptyData;
