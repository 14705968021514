import React, {
  useState,
  useCallback,
} from "react";
import {
  Table,
  Button,
  Modal,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import ImageGallery from "react-image-gallery";
import { useHistory } from "react-router-dom";
import { useQuery } from "react-apollo";

import StatusIcon, { ATTENTION_STATUSES } from "components/StatusIcon/StatusIcon";
import ServiceItemModal from "components/ServiceItemModal/ServiceItemModal";
import { useSnackbar } from "contexts/snackbar/SnackbarContext";
import { MAINTENANCES_NEW_PATH } from "router/routes";
import extractCorrectiveMaintenanceInfo from "utils/extractors/extractCorrectiveMaintenanceInfo";
import APP_URL_QUERY from "graphql/appUrlQuery";

// Renders the select service button on the selected row
const renderSelectService = (t) => (onClick) => (
  <span onClick={onClick} className="form-text help-text clickable primary">
    {t("maintenances.select_service")}
  </span>
);

const DailyChecklistItemsTable = ({
  equipment,
  items,
}) => {
  const [t] = useTranslation();
  const history = useHistory();
  const [selectedItems, setSelectedItems] = useState([]);
  const [openedItem, setOpenedItem] = useState(null);
  const notify = useSnackbar();

  const { data } = useQuery(APP_URL_QUERY);

  const onNewMaintenanceOrder = useCallback(() => {
    if (selectedItems.some((item) => !item?.activity)) {
      notify.error(t("daily_checklists.error_creating_maintenance_order"));
      return;
    }

    const maintenanceOrder = extractCorrectiveMaintenanceInfo({
      equipment,
      checklistItems: selectedItems,
    });

    history.push({
      pathname: MAINTENANCES_NEW_PATH,
      state: {
        maintenanceOrder,
      },
    });
  }, [
    equipment,
    history,
    notify,
    selectedItems,
    t,
  ]);

  const onSelectService = useCallback((item) => (value) => {
    setSelectedItems((prev) => ([
      ...(prev).filter((cur) => cur.id !== item.id),
      {
        ...item,
        activity: value,
      },
    ]));
  }, [
    setSelectedItems,
  ]);

  const onAddItem = useCallback((item) => () => {
    if (selectedItems.find((cur) => cur.id === item.id)) {
      setSelectedItems((selectedItems).filter((cur) => cur.id !== item.id));
    } else {
      setSelectedItems([...selectedItems, item]);
    }
  }, [selectedItems, setSelectedItems]);

  const handleClose = () => {
    setOpenedItem(null);
  };

  const handleShow = ({
    description,
    comments,
    photos,
  }) => {
    setOpenedItem({
      description,
      comments,
      photos: photos.map(({ photoUrl }) => {
        const fullUrl = `${data?.appUrl}${photoUrl}`;

        return ({
          original: fullUrl,
          thumbnail: fullUrl,
        });
      }),
    });
  };

  const renderItemActivity = (item) => {
    if (item.activity) {
      return (
        <span className="form-text help-text">
          {item.activity.description}
        </span>
      );
    }

    return (
      <div>
        <ServiceItemModal
          customRender={renderSelectService(t)}
          callback={onSelectService(item)}
        >
          <div>
            <p className="text-bold m-0">
              {t("daily_checklists.checklist_item")}
            </p>

            <span className="form-text text-muted mb-3">
              {`${item?.group ? `${item?.group} - ` : ""} ${item?.description || ""}`}
            </span>
          </div>
        </ServiceItemModal>
      </div>
    );
  };

  return (
    <>
      <Table hover responsive className="checklist-table">
        <thead>
          <tr>
            <th>{t("attributes.daily_checklist_items.group")}</th>
            <th>{t("attributes.daily_checklist_items.description")}</th>
            <th>{t("attributes.daily_checklist_items.comments")}</th>
            <th className="text-center">
              {t("attributes.daily_checklist_items.photos")}
            </th>
            <th className="text-center">
              {t("attributes.daily_checklist_items.status")}
            </th>
            <th className="text-center">
              {t("equipments.maintenance_plans.select")}
            </th>
          </tr>
        </thead>
        <tbody>
          {
            items.length
              ? (
                items.map((item) => {
                  const selectedItem = selectedItems.find((cur) => cur.id === item.id);
                  const requireAttention = ATTENTION_STATUSES.includes(item.status);

                  return (
                    <tr key={item.id}>
                      <td>
                        {item.group}
                      </td>
                      <td>
                        {item.description}

                        {
                          selectedItem && (
                            renderItemActivity(selectedItem)
                          )
                        }
                      </td>
                      <td>
                        {item.comments}
                      </td>
                      <td className="text-center">
                        {
                          item.photos.length && (
                            <Button
                              size="sm"
                              variant="outline-primary"
                              onClick={() => handleShow({
                                description: item?.description,
                                comments: item?.comments,
                                photos: item?.photos,
                              })}
                            >
                              {t("actions.see_photos")}
                            </Button>
                          )
                        }
                      </td>
                      <td>
                        <StatusIcon status={item.status} />
                      </td>
                      <td className="d-flex justify-content-center align-items-center">
                        {
                          requireAttention && (
                            <input
                              type="checkbox"
                              onClick={onAddItem(item)}
                            />
                          )
                        }
                      </td>
                    </tr>
                  );
                })
              )
              : (
                <tr>
                  <td className="text-center" colSpan="5">
                    {t("daily_checklists.empty_data")}
                  </td>
                </tr>
              )
          }
        </tbody>
      </Table>

      <div className="d-flex justify-content-end align-items-center">
        <Button
          variant="primary"
          onClick={onNewMaintenanceOrder}
          disabled={!selectedItems.length}
        >
          {t("daily_checklists.new_corrective_maintenance")}
        </Button>
      </div>

      <Modal
        show={!!openedItem}
        onHide={handleClose}
        backdrop="static"
        size="lg"
      >
        <Modal.Body>
          <ImageGallery items={openedItem?.photos || []} />
        </Modal.Body>

        <Modal.Footer>
          <div className="photos-modal-footer">
            <div>
              <p className="text-bold">
                {openedItem?.description}
              </p>
              <span>
                {openedItem?.comments}
              </span>
            </div>

            <Button variant="primary" onClick={handleClose}>
              {t("actions.close")}
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DailyChecklistItemsTable;
