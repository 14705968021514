import React from "react";
import { Table as BootstrapTable } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import get from "lodash.get";
import classnames from "classnames";

import SortingHeader from "components/SortingHeader";

const Table = ({
  headers = [],
  items = [],
  emptyMessage,
  onRowClick,
  selectedItems,
  disabledItems,
  showCheckbox = false,
  hover = true,
  rowKey = "id",
  children,
  sorting = {},
  onSortingChange,
}) => {
  const [t] = useTranslation();

  return (
    <BootstrapTable hover={hover} responsive>
      <thead>
        <tr>
          {
            showCheckbox && (
              <th>
                {t("tables.selection")}
              </th>
            )
          }
          {
            headers.map(({ label, key, sortingColumn }) => (
              <SortingHeader
                label={label}
                key={key}
                sortingColumn={sortingColumn}
                sorting={sorting}
                onSortingChange={onSortingChange}
              />
            ))
          }
        </tr>
      </thead>
      <tbody>
        {
          items.length
            ? (
              items.map((item) => {
                const isSelected = !!selectedItems
                  ?.find(({ [rowKey]: key }) => key === item[rowKey]);
                const isDisabled = !!disabledItems
                  ?.find(({ [rowKey]: key }) => key === item[rowKey]);

                const rowClass = classnames({
                  "row-selected": isSelected,
                  "row-disabled": isDisabled,
                  "row-selectable": !!onRowClick,
                });

                return (
                  <tr
                    key={get(item, rowKey)}
                    onClick={onRowClick?.(item)}
                    className={rowClass}
                  >
                    {
                      showCheckbox && (
                        <td>
                          {!isDisabled && (
                            <input type="checkbox" checked={isSelected} readOnly />
                          )}
                        </td>
                      )
                    }
                    {
                      headers.map((header) => (
                        <td
                          key={`${item[rowKey]}-${header.key}`}
                          className={header.tdClass ?? ""}
                        >
                          {
                            header.formatter
                              ? header.formatter(get(item, header.key), item)
                              : get(item, header.key) || "-"
                          }
                        </td>
                      ))
                    }
                  </tr>
                );
              })
            )
            : (
              <tr>
                <td className="text-center" colSpan={headers.length}>
                  {emptyMessage ?? t("messages.empty_data")}
                </td>
              </tr>
            )
        }

        {children}
      </tbody>
    </BootstrapTable>
  );
};

export default Table;
