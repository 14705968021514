import React from "react";
import { Button } from "react-bootstrap";
import { MdAdd } from "react-icons/md";
import isFunction from "lodash.isfunction";

const ServiceItemModalButton = ({
  onClick,
  customRender,
}) => {
  if (customRender && isFunction(customRender)) {
    return customRender(onClick);
  }

  return (
    <Button
      variant="light"
      className="btn-simple"
      onClick={onClick}
    >
      <MdAdd size="2em" />
    </Button>
  );
};

export default ServiceItemModalButton;
