import React, {
  useCallback,
} from "react";
import {
  Row,
  Col,
  Button,
} from "react-bootstrap";
import {
  FormInput,
} from "@formup/core";
import { MdAdd, MdDelete } from "react-icons/md";
import { useTranslation } from "react-i18next";
import useDidMount from "@rooks/use-did-mount";
import * as R from "ramda";

import TextInput from "components/Inputs/TextInput/TextInput";
import Selector, { queries } from "components/Inputs/Selector/Selector";
import extractUnitInfo from "utils/extractors/extractUnitInfo";
import getInventoryItemUnitHelp from "utils/getHelpTexts/getInventoryItemUnitHelp";
import { useFormContext } from "views/Maintenances/MaintenanceForm/context/FormContext";
import useInventoryQuantity from "hooks/useInventoryQuantity";

const MaintenanceMaterialsForm = ({
  items,
  arrayHelpers,
}) => {
  const [t] = useTranslation();

  const {
    formikForm,
  } = useFormContext();

  const {
    availableQuantities,
    fetchAvailableQuantityEventHandler,
  } = useInventoryQuantity();

  const materials = (formikForm?.values?.activities || []).reduce((acc, item) => (
    [
      ...acc,
      ...(item?.maintenanceActivity?.maintenanceMaterials || []),
    ]
  ), []);

  const groupedMaterials = R.groupBy((material) => `${material.inventoryItemId?.value}-${material.brandId?.value}`, materials);

  useDidMount(() => {
    items.forEach((item) => {
      const inventoryItemId = item?.value?.inventoryItemId?.value;
      const brandId = item?.value?.brandId?.value;

      fetchAvailableQuantityEventHandler(inventoryItemId, brandId)();
    });
  });

  const onRemove = useCallback((index) => () => {
    arrayHelpers.remove(index);
  }, [arrayHelpers]);

  return (
    <div className="maintenance-materials-form">
      <div className="d-flex justify-content-between align-items-center">
        <h5>{t("attributes.maintenance_activity.maintenance_materials")}</h5>

        <Button
          variant="light"
          className="btn-simple"
          onClick={() => arrayHelpers.push({
            inventoryItemId: null,
            quantity: 0,
            reservedQuantity: 0,
          })}
        >
          <MdAdd size="2em" />
        </Button>
      </div>

      <div className="maintenance-materials">
        {
          items.length
            ? (
              items.map((item, index) => {
                const inventoryItemId = item?.value?.inventoryItemId?.value;
                const brandId = item?.value?.brandId?.value;

                const inventoryItem = availableQuantities[String(inventoryItemId)]
                  ?.[String(brandId)];

                const reservedQuantity = R.pipe(
                  R.map((i) => i.reservedQuantity ?? 0),
                  R.sum,
                )(groupedMaterials[`${inventoryItemId}-${brandId}`] || []);

                const availableQuantity = inventoryItem?.availableBrandQuantity;

                const remainingQuantity = (
                  availableQuantity !== undefined
                    ? Math.max(availableQuantity - reservedQuantity, 0)
                    : undefined
                );

                return (
                  <Row key={item.path}>
                    <Col md={3}>
                      <FormInput
                        component={Selector}
                        query={queries.INVENTORY_ITEMS}
                        queryObject="inventoryItems"
                        injectFormupData
                        getExtraValues={extractUnitInfo}
                        name={item.getPath("inventoryItemId")}
                        getHelpText={getInventoryItemUnitHelp}
                        afterChange={fetchAvailableQuantityEventHandler(inventoryItemId, brandId)}
                      />
                    </Col>

                    <Col md={2}>
                      <FormInput
                        component={Selector}
                        query={queries.BRANDS}
                        queryObject="brands"
                        injectFormupData
                        name={item.getPath("brandId")}
                        extraParams={{ inventoryItemId: item?.value?.inventoryItemId?.value }}
                        afterChange={fetchAvailableQuantityEventHandler(inventoryItemId, brandId)}
                      />
                    </Col>

                    <Col md={2}>
                      <FormInput
                        component={TextInput}
                        injectFormupData
                        name={item.getPath("quantity")}
                        type="number"
                        min={0}
                      />
                    </Col>

                    <Col md={2}>
                      <FormInput
                        component={TextInput}
                        injectFormupData
                        name={item.getPath("reservedQuantity")}
                        type="number"
                        min={0}
                        max={remainingQuantity}
                        helpText={remainingQuantity !== undefined ? t("maintenances.available_quantity_help", { quantity: remainingQuantity }) : ""}
                      />
                    </Col>

                    <Col md={2}>
                      <FormInput
                        component={TextInput}
                        injectFormupData
                        name={item.getPath("requestedQuantity")}
                        type="number"
                        label={t("attributes.maintenance_material.requested_quantity")}
                        disabled
                      />
                    </Col>

                    <Col md={1}>
                      <Button
                        variant="light"
                        className="btn-simple"
                        onClick={onRemove(index)}
                      >
                        <MdDelete size="2em" />
                      </Button>
                    </Col>
                  </Row>
                );
              })
            )
            : (
              <div className="text-center m-5">
                {t("messages.empty_data")}
              </div>
            )
        }
      </div>
    </div>
  );
};

export default MaintenanceMaterialsForm;
