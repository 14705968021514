import gql from "graphql-tag";

const PRICE_QUOTES_QUERY = gql`
  query PriceQuotesQuery($pagination: PaginationParams) {
    countPriceQuotes
    priceQuotes(pagination: $pagination) {
      id
      code
      deliveryDeadline
      sentQuotations
      answeredQuotations
      canCreateOrders
      responsible {
        id
        name
      }
      bestQuotation {
        id
      }
      priceQuoteItems {
        id
        inventoryItem {
          id
          name
          unit {
            id
            unit
          }
        }
        serviceItem {
          id
          description
        }
        brand {
          id
          name
        }
        totalQuantity
      }
    }
  }
`;

export default PRICE_QUOTES_QUERY;
