import gql from "graphql-tag";

const END_MAINTENANCE_ACTIVITY_MUTATION = gql`
  mutation EndMaintenanceActivity($id: Int!, $comments: String, $inventoryChanges: [MaintenanceChangesParams]){
    endMaintenanceActivity(
      id: $id,
      comments: $comments,
      inventoryChanges: $inventoryChanges,
    ) {
      id
    }
  }
`;

export default END_MAINTENANCE_ACTIVITY_MUTATION;
