import { ApolloClient } from "apollo-client";
import { ApolloLink } from "apollo-link";
import { InMemoryCache } from "apollo-cache-inmemory";
import { createLink } from "apollo-absinthe-upload-link";
import { buildSentryErrorLink } from "apollo-sentry-helper";
import { onError } from "apollo-link-error";
import HttpStatus from "http-status-codes";

const uri = process.env.NODE_ENV === "production"
  ? `${window.location.protocol}//${window.location.hostname}/graphql`
  : "http://localhost:4000/graphql";

const httpLink = createLink({
  uri,
  // Includes HTTP only cookies in the request
  credentials: "include",
});

const authorizationLink = onError((queryError) => {
  const { graphQLErrors } = queryError;

  if (graphQLErrors) {
    graphQLErrors.forEach((error) => {
      if (error.status === HttpStatus.UNAUTHORIZED) {
        window.location = error.redirect_url;
      }
    });
  }
});

const sentryErrorLink = buildSentryErrorLink();

const link = ApolloLink.from([
  sentryErrorLink,
  authorizationLink,
  httpLink,
]);

const apolloClient = new ApolloClient({
  link,
  cache: new InMemoryCache(),
  defaultOptions: {
    query: {
      fetchPolicy: "cache-and-network",
    },
    watchQuery: {
      fetchPolicy: "cache-and-network",
    },
  },
});

export default apolloClient;
