import React from "react";
import {
  Container,
  Card,
  Row,
  Col,
  Table,
} from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useQuery } from "react-apollo";
import { useTranslation } from "react-i18next";

import { formatDate, fullDateMask } from "utils/dateFormats";
import EmptyData from "components/EmptyData/EmptyData";
import EndActivityAction from "components/EndActivityAction/EndActivityAction";

import MAINTENANCE_ACTIVITY_QUERY from "./graphql/maintenanceActivityQuery";

const MaintenanceActivityDetails = () => {
  const [t] = useTranslation();
  const { id } = useParams();

  const {
    data,
    loading,
    error,
  } = useQuery(MAINTENANCE_ACTIVITY_QUERY, {
    variables: {
      id: parseInt(id),
    },
    skip: !id,
  });

  const activity = data?.maintenanceActivityOrder;

  return (
    <Container>
      <Row>
        <Col md={12}>
          <Card>
            <Card.Body>
              <h4 className="card-title">
                {t("maintenance_activity_details.title")}
              </h4>

              {
                activity
                  ? (
                    <div>
                      <Row>
                        <Col md={4} className="model-details">
                          <span>{t("attributes.maintenance_activity.status")}</span>
                          <h5>{t(`enums.maintenance_activity_order.status.${activity.status}`)}</h5>
                        </Col>

                        <Col md={4} className="model-details">
                          <span>{t("attributes.maintenance_activity.start_time")}</span>
                          <h5>{formatDate(activity?.maintenanceWork?.startTime, fullDateMask)}</h5>
                        </Col>

                        <Col md={4} className="model-details">
                          <span>{t("attributes.maintenance_activity.responsible")}</span>
                          <h5>{activity?.maintenanceWork?.operator?.name}</h5>
                        </Col>
                      </Row>

                      <Row>
                        <Col md={8} className="model-details">
                          <span>{t("attributes.maintenance_activity.description")}</span>
                          <h5>{activity?.maintenanceActivity?.serviceItem?.description}</h5>
                        </Col>

                        <Col md={4} className="model-details">
                          <span>{t("attributes.service_item.group")}</span>
                          <h5>
                            {activity?.maintenanceActivity?.serviceItem?.serviceItemGroup?.name}
                          </h5>
                        </Col>
                      </Row>

                      <Row>
                        <Col md={12}>
                          <h5>{t("attributes.maintenance_activity.maintenance_materials")}</h5>

                          <Table hover responsive>
                            <thead>
                              <tr>
                                <th>{t("attributes.inventory_item.code")}</th>
                                <th>{t("attributes.inventory_item.name")}</th>
                                <th>{t("attributes.maintenance_material.quantity")}</th>
                                <th>{t("attributes.maintenance_material.unit")}</th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                activity?.maintenanceActivity?.maintenanceMaterials?.length
                                  ? (
                                    activity?.maintenanceActivity?.maintenanceMaterials?.map(({
                                      id: materialId,
                                      quantity,
                                      inventoryItem,
                                    }) => (
                                      <tr key={materialId}>
                                        <td>
                                          {inventoryItem?.code}
                                        </td>
                                        <td>
                                          {inventoryItem?.name}
                                        </td>
                                        <td>
                                          {quantity}
                                        </td>
                                        <td>
                                          {inventoryItem?.unit?.unit}
                                        </td>
                                      </tr>
                                    ))
                                  )
                                  : (
                                    <tr>
                                      <td className="text-center" colSpan="5">
                                        {t("maintenance_activity_details.maintenance_materials.empty_data")}
                                      </td>
                                    </tr>
                                  )
                              }
                            </tbody>
                          </Table>
                        </Col>
                      </Row>
                    </div>
                  )
                  : (
                    <EmptyData
                      error={error}
                      loading={loading}
                    />
                  )
              }

              {
                activity && (
                  <EndActivityAction
                    activity={activity}
                  />
                )
              }
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default MaintenanceActivityDetails;
