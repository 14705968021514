
import i18n from "translations/i18n";
import yup from "settings/yup";

const schema = yup.object().shape({
  group: yup
    .string()
    .required()
    .label(i18n.t("attributes.maintenance_activity.group")),
  description: yup
    .string()
    .required()
    .label(i18n.t("attributes.maintenance_activity.description")),
});

export default schema;
