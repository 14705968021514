import gql from "graphql-tag";

const SERVICE_ITEMS_QUERY = gql`
  query ServiceItems($search: String, $groupId: Int) {
    serviceItems(search: $search, groupId: $groupId) {
      id
      description
    }
  }
`;

export default SERVICE_ITEMS_QUERY;
