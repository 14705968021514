
import gql from "graphql-tag";

const MAINTENANCE_ORDER_QUERY = gql`
query MaintenanceOrderQuery($id: Int!) {
  maintenanceOrder(id: $id) {
    id
    code
    type
    description
    closingDate
    comments
    responsible {
      id
      name
    }
    equipment {
      id
      code
    }
    measurements {
      dia
      km
      m3
      litro
      hora
      planIds
    }
    activities {
      id
      number
      kind
      status
      maintenanceActivity {
        id
        serviceItem {
          id
          description
          serviceItemGroup {
            id
            name
          }
        }
        isRequested
        maintenanceMaterials {
          id
          quantity
          reservedQuantity
          requestedQuantity
          brand {
            id
            name
          }
          inventoryItem {
            id
            code
            name
            unit {
              id
              unit
            }
          }
        }
      }
    }
  }
}
`;

export default MAINTENANCE_ORDER_QUERY;
