import gql from "graphql-tag";

const EQUIPMENT_MAINTENANCES_REPORT_QUERY = gql`
  query EquipmentMaintenancesReport {
    equipmentsMaintenancesReport {
      doneChecklists
      equipmentsWithMaintenancePlans
    }
  }
`;

export default EQUIPMENT_MAINTENANCES_REPORT_QUERY;
