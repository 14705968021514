import React, { useCallback } from "react";
import AsyncSelect from "react-select/async";
import { useQuery } from "react-apollo";
import classnames from "classnames";
import { useTranslation } from "react-i18next";
import isFunction from "lodash.isfunction";

import BaseInput from "components/Inputs/BaseInput";

import OPERATORS_QUERY from "./graphql/operatorsQuery";
import EQUIPMENTS_QUERY from "./graphql/equipmentsQuery";
import SERVICE_ITEMS_QUERY from "./graphql/serviceItemsQuery";
import SERVICE_ITEM_GROUPS_QUERY from "./graphql/serviceItemGroupsQuery";
import INVENTORY_ITEMS_QUERY from "./graphql/inventoryItemsQuery";
import BRANDS_QUERY from "./graphql/brandsQuery";
import MAINTENANCE_ORDERS_QUERY from "./graphql/maintenanceOrdersQuery";
import PROVIDERS_QUERY from "./graphql/providersQuery";

export const queries = {
  OPERATORS: OPERATORS_QUERY,
  EQUIPMENTS: EQUIPMENTS_QUERY,
  SERVICE_ITEMS: SERVICE_ITEMS_QUERY,
  SERVICE_ITEM_GROUPS: SERVICE_ITEM_GROUPS_QUERY,
  INVENTORY_ITEMS: INVENTORY_ITEMS_QUERY,
  BRANDS: BRANDS_QUERY,
  MAINTENANCE_ORDERS: MAINTENANCE_ORDERS_QUERY,
  PROVIDERS: PROVIDERS_QUERY,
};

const Selector = ({
  query = OPERATORS_QUERY,
  queryParam = "search",
  queryObject = "operators",
  objectLabel = "name",
  objectValue = "id",
  isClearable = true,
  getExtraValues = null,
  extraParams = {},
  preventFilter = false,
  loadOptionsRef = null,
  className,
  ...props
}) => {
  const [t] = useTranslation();
  const { refetch } = useQuery(query, {
    skip: true,
    fetchPolicy: "no-cache",
  });

  const loadOptions = useCallback((inputValue, params = {}) => new Promise((resolve, reject) => {
    const variables = {
      [queryParam]: inputValue,
      ...params,
      ...extraParams,
    };

    refetch(variables)
      .then(({
        data,
        errors,
      }) => {
        if (!data || preventFilter) {
          reject(errors);
        }

        const result = data[queryObject];
        const parsedResult = result.map((object) => {
          const hasExtra = getExtraValues && isFunction(getExtraValues);

          const extraValues = hasExtra
            ? getExtraValues(object)
            : {};

          return {
            label: object[objectLabel],
            value: object[objectValue],
            ...extraValues,
          };
        });

        resolve(parsedResult);
      })
      .catch((error) => {
        reject(error);
      });
  }), [
    getExtraValues,
    extraParams,
    objectLabel,
    objectValue,
    preventFilter,
    queryObject,
    queryParam,
    refetch,
  ]);

  if (loadOptionsRef) {
    loadOptionsRef.current = loadOptions;
  }

  const fieldClasses = classnames(className, "select");

  return (
    <BaseInput
      className={fieldClasses}
      Component={AsyncSelect}
      isClearable={isClearable}
      defaultOptions
      loadOptions={loadOptions}
      placeholder={t("selector.placeholder")}
      noOptionsMessage={() => t("selector.empty_data")}
      loadingMessage={() => t("selector.loading")}
      {...props}
    />
  );
};

export default Selector;
