import i18n from "translations/i18n";
import { getItemName, getItemType, getItemUnit } from "views/PurchaseRequests/getTableHeaders";

const getTableHeaders = () => ([
  {
    label: i18n.t("purchase_requests.table_headers.request"),
    key: "request",
    formatter: (_, item) => getItemName(item),
  },
  {
    label: i18n.t("purchase_requests.table_headers.type"),
    key: "type",
    formatter: (_, item) => getItemType(item),
  },
  {
    label: i18n.t("purchase_requests.table_headers.quantity"),
    key: "totalQuantity",
  },
  {
    label: i18n.t("purchase_requests.table_headers.unit"),
    key: "unit",
    formatter: (_, item) => getItemUnit(item),
  },
]);

export const getPriceQuoteItemTableHeaders = () => ([
  {
    label: i18n.t("purchase_requests.table_headers.request"),
    key: "request",
    formatter: (_, item) => getItemName(item),
  },
  {
    label: i18n.t("purchase_requests.table_headers.type"),
    key: "type",
    formatter: (_, item) => getItemType(item),
  },
  {
    label: i18n.t("purchase_requests.table_headers.quantity"),
    key: "totalQuantity",
  },
  {
    label: i18n.t("purchase_requests.table_headers.unit"),
    key: "unit",
    formatter: (_, item) => getItemUnit(item),
  },
]);

export default getTableHeaders;
