import React from "react";
import {
  OverlayTrigger,
  Tooltip as BootstrapTooltip,
} from "react-bootstrap";

const Tooltip = ({
  placement = "top",
  text,
  children,
}) => (
  <OverlayTrigger
    placement={placement}
    overlay={(
      <BootstrapTooltip id="tooltip">
        {text}
      </BootstrapTooltip>
    )}
  >
    {children}
  </OverlayTrigger>
);

export default Tooltip;
