import React, { useState, useCallback } from "react";
import {
  Modal,
  Row,
  Col,
  Button,
  Tabs,
  Tab,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import * as R from "ramda";

import booleanFormatter from "utils/formatters/booleanFormatter";
import { EXTERNAL } from "constants/maintenanceActivityKinds";
import Table from "components/Table/Table";
import { useFormContext } from "views/Maintenances/MaintenanceForm/context/FormContext";

const InventoryReservationModal = ({
  items,
  loading,
  closeModal,
  ...props
}) => {
  const [t] = useTranslation();
  const {
    submitForm,
  } = useFormContext();

  const [selectedItems, setSelectedItems] = useState([]);

  const toggleItem = useCallback((item) => () => {
    const selectedIndex = selectedItems.findIndex(({ path }) => path === item.path);

    if (selectedIndex === -1) {
      setSelectedItems([...selectedItems, item]);
      return;
    }

    setSelectedItems(selectedItems.filter(({ path }) => path !== item.path));
  }, [selectedItems]);

  const materials = selectedItems.reduce((acc, item) => (
    [
      ...acc,
      ...(
        item?.maintenanceActivity?.maintenanceMaterials || []).map((activity, index) => ({
        ...activity,
        path: `${item.path}.maintenanceActivity.maintenanceMaterials[${index}]`,
      })),
    ]
  ), []);

  const groupedMaterials = R.groupBy((material) => `${material.inventoryItemId?.value}-${material.brandId?.value}`, materials);

  const externalActivities = items.filter(({ kind }) => kind.value === EXTERNAL);

  const requestedActivities = externalActivities.filter(
    ({ maintenanceActivity }) => maintenanceActivity?.isRequested,
  );

  const missingMaterials = items
    .reduce((acc, item) => (
      [
        ...acc,
        ...(
          item?.maintenanceActivity?.maintenanceMaterials || []).map((activity, index) => ({
          ...activity,
          path: `${item.path}.maintenanceActivity.maintenanceMaterials[${index}]`,
        })),
      ]
    ), [])
    .filter(({ quantity, reservedQuantity }) => quantity > reservedQuantity);

  const requestedMaterials = missingMaterials.filter(({
    quantity,
    reservedQuantity,
    requestedQuantity,
  }) => reservedQuantity + requestedQuantity === quantity);

  return (
    <Modal {...props}>
      <Modal.Body>
        <Tabs defaultActiveKey="confirm_inventory_reservations">
          <Tab eventKey="confirm_inventory_reservations" title={t("maintenances.confirm_inventory_reservations")}>
            <Tab.Container>
              <Row className="p-3">
                <Col md={6}>
                  <h5>{t("maintenances.activities")}</h5>

                  <Table
                    headers={[
                      {
                        label: t("attributes.maintenance_activity.group"),
                        key: "maintenanceActivity.serviceItemId.serviceItemGroup.name",
                      },
                      {
                        label: t("attributes.maintenance_activity.description"),
                        key: "maintenanceActivity.serviceItemId.description",
                      },
                    ]}
                    items={items}
                    selectedItems={selectedItems}
                    onRowClick={toggleItem}
                    rowKey="path"
                    hover={false}
                  />
                </Col>

                <Col md={6}>
                  <h5>{t("maintenances.materials")}</h5>

                  <Table
                    headers={[
                      {
                        label: t("attributes.maintenance_material.inventory_item"),
                        key: "[0].inventoryItemId.label",
                      },
                      {
                        label: t("attributes.maintenance_material.brand"),
                        key: "[0].brandId.label",
                      },
                      {
                        label: t("attributes.maintenance_material.unit"),
                        key: "[0].inventoryItemId.unit",
                      },
                      {
                        label: t("attributes.maintenance_material.quantity"),
                        key: "quantity",
                        formatter: (_value, array) => R.pipe(
                          R.map(({ quantity }) => quantity ?? 0),
                          R.sum,
                        )(array || []),
                      },
                      {
                        label: t("attributes.maintenance_material.reserved_quantity"),
                        key: "reserved_quantity",
                        formatter: (_value, array) => R.pipe(
                          R.map(({ reservedQuantity }) => reservedQuantity ?? 0),
                          R.sum,
                        )(array || []),
                      },
                    ]}
                    items={Object.values(groupedMaterials)}
                  />
                </Col>
              </Row>
            </Tab.Container>
          </Tab>

          <Tab eventKey="create_purchase_requests" title={t("maintenances.create_purchase_requests")}>
            <Tab.Container>
              <Row className="p-3">
                <Col md={6}>
                  <h5>{t("maintenances.external_activities")}</h5>

                  <Table
                    headers={[
                      {
                        label: t("attributes.maintenance_activity.group"),
                        key: "maintenanceActivity.serviceItemId.serviceItemGroup.name",
                      },
                      {
                        label: t("attributes.maintenance_activity.description"),
                        key: "maintenanceActivity.serviceItemId.description",
                      },
                      {
                        label: t("attributes.maintenance_activity.has_purchase_request"),
                        key: "maintenanceActivity.isRequested",
                        formatter: booleanFormatter,
                      },
                    ]}
                    items={externalActivities}
                    disabledItems={requestedActivities}
                    hover={false}
                  />
                </Col>

                <Col md={6}>
                  <h5>{t("maintenances.missing_materials")}</h5>

                  <Table
                    headers={[
                      {
                        label: t("attributes.maintenance_material.inventory_item"),
                        key: "inventoryItemId.label",
                      },
                      {
                        label: t("attributes.maintenance_material.brand"),
                        key: "brandId.label",
                      },
                      {
                        label: t("attributes.maintenance_material.unit"),
                        key: "inventoryItemId.unit",
                      },
                      {
                        label: t("attributes.maintenance_material.quantity"),
                        key: "quantity",
                      },
                      {
                        label: t("attributes.maintenance_material.reserved_quantity"),
                        key: "reservedQuantity",
                      },
                      {
                        label: t("attributes.maintenance_material.requested_quantity"),
                        key: "requestedQuantity",
                      },
                    ]}
                    items={missingMaterials}
                    disabledItems={requestedMaterials}
                    hover={false}
                  />
                </Col>
              </Row>
            </Tab.Container>
          </Tab>
        </Tabs>

        <Button
          variant="primary"
          onClick={submitForm}
          disabled={loading}
        >
          {t("actions.save")}
        </Button>

        <Button
          variant="light"
          onClick={closeModal}
          disabled={loading}
        >
          {t("actions.close")}
        </Button>
      </Modal.Body>
    </Modal>
  );
};

export default InventoryReservationModal;
