import { formatDate, yearMonthDay } from "utils/dateFormats";

/**
 * Transforms a date value into a ISO string.
 *
 * @param {string} date A date string
 * @param {string} fallback A fallback
 */

const dateTransform = (date, fallback) => formatDate(date, yearMonthDay, fallback);

export default dateTransform;
