const colorClasses = [
  "color-blue",
  "color-purple",
  "color-orange",
  "color-pink",
  "color-green",
];

const getColorClass = (index) => colorClasses[index % colorClasses.length];

export default getColorClass;
