import React, {
  useCallback,
  useState,
} from "react";
import {
  Button,
  Row,
  Col,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import get from "lodash.get";
import {
  FormInput,
  FormArrayField,
  Form,
} from "@formup/core";
import { useQuery } from "react-apollo";
import useDidMount from "@rooks/use-did-mount";

import defaultOperatorParams from "constants/defaultOperatorParams";
import TextInput from "components/Inputs/TextInput/TextInput";
import Selector, { queries } from "components/Inputs/Selector/Selector";
import OptionSelector from "components/Inputs/OptionSelector/OptionSelector";
import DateSelector from "components/Inputs/DateSelector/DateSelector";
import { useFormContext } from "views/Maintenances/MaintenanceForm/context/FormContext";
import { maintenanceOrderTypeOptions } from "constants/maintenanceOrderTypes";
import EQUIPMENT_MEASUREMENTS_QUERY from "views/Maintenances/graphql/equipmentMeasurementsQuery";
import MEASUREMENTS from "constants/measurements";

import MaintenanceActivitiesForm from "./maintenanceActivities/MaintenanceActivitiesForm";
import InventoryReservationModal from "./InventoryReservationModal";

const MaintenanceForm = ({
  isEdit = false,
  loading,
}) => {
  const [t] = useTranslation();

  const [showModal, setShowModal] = useState(false);

  const {
    refetch,
  } = useQuery(EQUIPMENT_MEASUREMENTS_QUERY, {
    skip: true,
    fetchPolicy: "no-cache",
  });

  const { formikForm } = useFormContext();

  const toggleModal = useCallback(() => {
    setShowModal((prev) => !prev);
  }, []);

  const onEquipmentChange = useCallback(async (event) => {
    refetch({
      id: event?.value,
    })
      .then(({ data }) => {
        const measurements = data?.equipment?.measurements || {};

        Object.keys(measurements).forEach((key) => {
          const name = MEASUREMENTS[key];
          const fieldName = `measurements.${name}`;
          const fieldValue = get(formikForm?.values, fieldName);

          if (name) {
            formikForm.setFieldValue(fieldName, fieldValue || measurements[key]);
          }
        });
      })
      .catch(() => {});
  }, [
    formikForm,
    refetch,
  ]);

  useDidMount(() => {
    const value = formikForm?.values?.equipmentId;

    if (value) {
      onEquipmentChange(value);
    }
  });

  const items = formikForm?.values?.activities?.map((item, index) => ({ ...item, path: `activities[${index}]` })) || [];

  return (
    <Form formikForm={formikForm}>
      <Row>
        <Col md={6}>
          <FormInput
            name="equipmentId"
            component={Selector}
            injectFormupData
            query={queries.EQUIPMENTS}
            queryObject="equipments"
            objectLabel="code"
            afterChange={onEquipmentChange}
            isDisabled={isEdit}
          />
        </Col>

        <Col md={6}>
          <FormInput
            name="responsibleId"
            component={Selector}
            injectFormupData
            extraParams={defaultOperatorParams}
          />
        </Col>
      </Row>

      <Row>
        <Col md={4}>
          <FormInput
            name="code"
            disabled
            component={TextInput}
            injectFormupData
          />
        </Col>

        <Col md={4}>
          <FormInput
            name="closingDate"
            component={DateSelector}
            injectFormupData
          />
        </Col>

        <Col md={4}>
          <FormInput
            name="type"
            component={OptionSelector}
            options={maintenanceOrderTypeOptions}
            injectFormupData
          />
        </Col>
      </Row>

      <Row>
        <Col md={12}>
          <FormInput
            name="description"
            component={TextInput}
            injectFormupData
          />
        </Col>
      </Row>

      <Row>
        <Col md={12}>
          <FormInput
            name="comments"
            component={TextInput}
            injectFormupData
            as="textarea"
          />
        </Col>
      </Row>

      <hr />
      <h5 className="card-title">{t("attributes.maintenance_order.measurements")}</h5>
      <p>{t("maintenances.measurements_help")}</p>

      <Row>
        <Col md={4} lg={2}>
          <FormInput
            name="measurements.m3"
            component={TextInput}
            injectFormupData
            type="number"
            disabled
          />
        </Col>

        <Col md={4} lg={2}>
          <FormInput
            name="measurements.km"
            component={TextInput}
            injectFormupData
            type="number"
            disabled
          />
        </Col>

        <Col md={4} lg={2}>
          <FormInput
            name="measurements.hour"
            component={TextInput}
            injectFormupData
            type="number"
            disabled
          />
        </Col>

        <Col md={4} lg={2}>
          <FormInput
            name="measurements.day"
            component={TextInput}
            injectFormupData
            type="number"
            disabled
          />
        </Col>

        <Col md={4} lg={2}>
          <FormInput
            name="measurements.liter"
            component={TextInput}
            injectFormupData
            type="number"
            disabled
          />
        </Col>
      </Row>

      <hr />

      <FormArrayField name="activities">
        <MaintenanceActivitiesForm />
      </FormArrayField>

      <InventoryReservationModal
        show={showModal}
        backdrop="static"
        size="xxl"
        items={items}
        loading={loading}
        closeModal={toggleModal}
      />

      <Button
        variant="primary"
        onClick={toggleModal}
        disabled={loading}
      >
        {t("actions.save")}
      </Button>
    </Form>
  );
};

export default MaintenanceForm;
