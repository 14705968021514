import React from "react";

import FormContainer from "./context/FormContainer";
import Form from "./form/MaintenanceForm";

const MaintenanceForm = ({
  handleSubmitForm,
  initialValues,
  ...props
}) => (
  <FormContainer
    handleSubmitForm={handleSubmitForm}
    initialValues={initialValues}
  >
    <Form {...props} />
  </FormContainer>
);

export default MaintenanceForm;
