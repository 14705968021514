import React, { useCallback } from "react";
import {
  Container,
  Card,
  Row,
  Col,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useMutation, useQuery } from "react-apollo";

import { onQueryError } from "utils/queryHandlers";
import { useSnackbar } from "contexts/snackbar/SnackbarContext";
import convertToQuoteItems from "utils/converters/convertToQuoteItems";
import { PRICE_QUOTES_DETAIL_LOCATION } from "router/locations";
import extractListItemProvidersVariables from "utils/extractors/extractListItemProvidersVariables";
import EmptyData from "components/EmptyData/EmptyData";
import convertToQuoteProviders from "utils/converters/convertToQuoteProviders";

import PriceQuoteForm from "./PriceQuoteForm";
import CREATE_PRICE_QUOTE_MUTATION from "./graphql/createPriceQuote";
import LIST_ITEM_PROVIDERS from "./graphql/listItemProvidersQuery";

const PriceQuoteNew = () => {
  const [t] = useTranslation();
  const history = useHistory();
  const notify = useSnackbar();

  const { purchaseRequests } = history.location.state || {};

  const [createPriceQuote, {
    loading,
  }] = useMutation(CREATE_PRICE_QUOTE_MUTATION);

  const {
    data: listItemProvidersData,
    loading: queryLoading,
    error: queryError,
  } = useQuery(LIST_ITEM_PROVIDERS, {
    variables: {
      ...extractListItemProvidersVariables(purchaseRequests),
    },
  });

  const handleFormError = useCallback(() => {
    notify.error(t("errors.please_check_if_your_form_is_correct"));
  }, [
    notify,
    t,
  ]);

  const handleSubmitForm = useCallback((params) => {
    createPriceQuote({
      variables: { params },
    })
      .then(({ data }) => {
        notify.success(t("price_quotes_new.success"));

        history.push(PRICE_QUOTES_DETAIL_LOCATION.toUrl({ id: data?.createPriceQuote?.id }));
      })
      .catch(onQueryError);
  }, [
    createPriceQuote,
    history,
    notify,
    t,
  ]);

  const priceQuoteItems = convertToQuoteItems(purchaseRequests);

  if (!listItemProvidersData) {
    return (
      <EmptyData
        loading={queryLoading}
        error={queryError}
      />
    );
  }

  return (
    <Container>
      <Row>
        <Col md={12}>
          <Card>
            <Card.Body>
              <h4 className="card-title">
                {t("price_quotes_new.title")}
              </h4>

              <PriceQuoteForm
                loading={loading}
                handleFormError={handleFormError}
                handleSubmitForm={handleSubmitForm}
                priceQuoteItems={priceQuoteItems}
                initialValues={{
                  priceQuoteItemsAttributes: priceQuoteItems,
                  priceQuoteProvidersAttributes: convertToQuoteProviders(
                    listItemProvidersData?.listItemProviders,
                  ),
                }}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default PriceQuoteNew;
