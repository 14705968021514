import gql from "graphql-tag";

const DAILY_CHECKLISTS_QUERY = gql`
  query DailyChecklistQuery($id: Int!) {
    dailyChecklist(id: $id) {
      id
      doneAt
      equipment {
        id
        code
      }
      operator {
        id
        name
      }
      dailyChecklistItems {
        id
        description
        comments
        group
        status
        photos {
          id
          photoUrl
        }
      }
    }
  }
`;

export default DAILY_CHECKLISTS_QUERY;
