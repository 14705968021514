import React, { useCallback, useRef } from "react";
import {
  Modal,
  Row,
  Col,
  Button,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import {
  FormInput,
  useFormup,
  Form,
} from "@formup/core";
import { useMutation } from "react-apollo";

import createProviderSchema from "settings/yup/schemas/createProviderSchema";
import { onQueryError } from "utils/queryHandlers";
import { useSnackbar } from "contexts/snackbar/SnackbarContext";
import TextInput from "components/Inputs/TextInput/TextInput";
import MaskedInput, { masks } from "components/Inputs/MaskedInput/MaskedInput";
import OptionSelector from "components/Inputs/OptionSelector/OptionSelector";
import stateOptions from "constants/stateOptions";

import CREATE_PROVIDER_MUTATION from "./graphql/createProviderMutation";

const CreateProviderModal = ({
  show,
  toggleModal,
}) => {
  const [t] = useTranslation();
  const notify = useSnackbar();
  const formRef = useRef(null);

  const [createProvider, {
    loading,
  }] = useMutation(CREATE_PROVIDER_MUTATION);

  const handleFormError = useCallback(() => {
    notify.error(t("errors.please_check_if_your_form_is_correct"));
  }, [
    notify,
    t,
  ]);

  const handleSubmitForm = useCallback((params) => {
    createProvider({
      variables: {
        params,
      },
    })
      .then(() => {
        if (formRef.current) {
          formRef.current.resetForm();
        }

        notify.success(t("price_quote_details.provider_created_success"));

        toggleModal();
      })
      .catch(onQueryError);
  }, [
    createProvider,
    toggleModal,
    notify,
    t,
  ]);

  const {
    formikForm,
    submitForm,
  } = useFormup(createProviderSchema, {
    onSubmit: handleSubmitForm,
    onError: handleFormError,
    transformOnSubmit: true,
  });

  formRef.current = formikForm;

  return (
    <Modal
      show={show}
      backdrop="static"
      size="lg"
      onHide={toggleModal}
    >
      <Modal.Body>
        <h5>{t("price_quote_details.create_provider")}</h5>

        <Form formikForm={formikForm}>
          <Row>
            <Col md={4}>
              <FormInput
                name="name"
                component={TextInput}
                injectFormupData
              />
            </Col>
            <Col md={4}>
              <FormInput
                name="cpfCnpj"
                component={TextInput}
                injectFormupData
              />
            </Col>
            <Col md={4}>
              <FormInput
                name="ie"
                component={TextInput}
                injectFormupData
              />
            </Col>
          </Row>

          <h5 className="mt-3">{t("price_quote_details.contact_info")}</h5>

          <Row>
            <Col md={6}>
              <FormInput
                name="phone"
                component={MaskedInput}
                injectFormupData
                mask={masks.phone}
              />
            </Col>
            <Col md={6}>
              <FormInput
                name="email"
                component={TextInput}
                injectFormupData
              />
            </Col>
          </Row>

          <h5 className="mt-3">{t("price_quote_details.address")}</h5>

          <Row>
            <Col md={6}>
              <FormInput
                name="address"
                component={TextInput}
                injectFormupData
              />
            </Col>
            <Col md={3}>
              <FormInput
                name="number"
                component={TextInput}
                injectFormupData
              />
            </Col>
            <Col md={3}>
              <FormInput
                name="complement"
                component={TextInput}
                injectFormupData
              />
            </Col>
          </Row>

          <Row>
            <Col md={3}>
              <FormInput
                name="neighborhood"
                component={TextInput}
                injectFormupData
              />
            </Col>
            <Col md={3}>
              <FormInput
                name="city"
                component={TextInput}
                injectFormupData
              />
            </Col>
            <Col md={3}>
              <FormInput
                name="state"
                component={OptionSelector}
                options={stateOptions}
                injectFormupData
              />
            </Col>
            <Col md={3}>
              <FormInput
                name="cep"
                component={MaskedInput}
                injectFormupData
                mask={masks.cep}
              />
            </Col>
          </Row>
        </Form>

        <Button
          variant="primary"
          onClick={submitForm}
          disabled={loading}
        >
          {t("actions.save")}
        </Button>

        <Button
          variant="outline-primary"
          onClick={toggleModal}
          disabled={loading}
        >
          {t("actions.close")}
        </Button>
      </Modal.Body>
    </Modal>
  );
};

export default CreateProviderModal;
