import gql from "graphql-tag";

const PROVIDERS_QUERY = gql`
  query ProvidersQuery($search: String) {
    providers(search: $search) {
      id
      name
    }
  }
`;

export default PROVIDERS_QUERY;
