import React, { useCallback } from "react";
import NumberFormat from "react-number-format";
import isFunction from "lodash.isfunction";

import TextInput from "components/Inputs/TextInput/TextInput";

const defaultOptions = {
  prefix: "R$ ",
  thousandSeparator: ".",
  decimalSeparator: ",",
  decimalScale: 2,
};

const CurrencyInput = ({
  value,
  onChange,
  ...props
}) => {
  const handleOnChange = useCallback((event) => {
    if (onChange && isFunction(onChange)) {
      onChange(event.value);
    }
  }, [
    onChange,
  ]);

  return (
    <NumberFormat
      {...props}
      customInput={TextInput}
      value={parseFloat(value)}
      prefix={defaultOptions.prefix}
      thousandSeparator={defaultOptions.thousandSeparator}
      decimalSeparator={defaultOptions.decimalSeparator}
      decimalScale={defaultOptions.decimalScale}
      fixedDecimalScale
      onValueChange={handleOnChange}
    />
  );
};

export default CurrencyInput;
