import React, {
  useState,
  useCallback,
  useEffect,
} from "react";
import {
  ListGroup,
} from "react-bootstrap";
import { FormInput } from "@formup/core";
import { useTranslation } from "react-i18next";
import { groupBy, path } from "ramda";
import classnames from "classnames";
import get from "lodash.get";

import ServiceItemModal from "components/ServiceItemModal/ServiceItemModal";
import getColorClass from "utils/getColorClass";
import { useFormContext } from "views/Maintenances/MaintenanceForm/context/FormContext";
import { defaultKind } from "constants/maintenanceActivityKinds";

import MaintenanceActivityRow from "./MaintenanceActivityRow";
import MaintenanceActivityModal from "./MaintenanceActivityModal";

const MaintenanceActivitiesForm = ({
  items,
  arrayHelpers,
}) => {
  const [t] = useTranslation();
  const [selected, setSelected] = useState(null);
  const { formikForm } = useFormContext();

  const onAddServiceItem = (serviceItem) => {
    arrayHelpers.push({
      kind: defaultKind,
      number: items.length + 1,
      maintenanceActivity: {
        serviceItemId: serviceItem,
      },
    });
  };

  const onModalClose = useCallback(() => {
    setSelected(null);
  }, [setSelected]);

  useEffect(() => {
    if (selected) {
      document.body.classList.add("modal-open");
      return;
    }

    document.body.classList.remove("modal-open");
  }, [selected]);

  const onRemove = useCallback((itemPath) => () => {
    arrayHelpers.remove(itemPath);
    setSelected(null);
  }, [arrayHelpers, setSelected]);

  const groupedItems = groupBy(
    path(["value", "maintenanceActivity", "serviceItemId", "serviceItemGroup", "name"]),
    items.map((item, index) => ({
      ...item,
      index,
    })) || [],
  );

  const activitiesErrors = get(formikForm?.errors || {}, "activities");

  const activitiesForm = classnames("maintenance-activities-form", {
    "has-errors": !!activitiesErrors,
  });

  return (
    <div className={activitiesForm}>
      <div className="form-header">
        <h5 className="card-title">{t("attributes.maintenance_order.activities")}</h5>

        <ServiceItemModal
          callback={onAddServiceItem}
        />
      </div>

      <div className="service-items-groups">
        {
          Object.keys(groupedItems).length
            ? (
              Object.keys(groupedItems).map((key, groupIndex) => {
                const colorClass = getColorClass(groupIndex);
                const groupClasses = classnames("service-item-group", colorClass);

                return (
                  <div key={key} className={groupClasses}>
                    <h5>{key}</h5>

                    <ListGroup variant="flush">
                      {groupedItems[key].map((item) => (
                        <ListGroup.Item
                          key={item.path}
                          action
                          onClick={() => setSelected(item)}
                        >
                          <FormInput
                            component={MaintenanceActivityRow}
                            nestedErrors={get(formikForm?.errors || {}, item.path)}
                            injectFormupData
                            name={item.getPath("maintenanceActivity.serviceItemId")}
                          />
                        </ListGroup.Item>
                      ))}
                    </ListGroup>
                  </div>
                );
              })
            )
            : (
              <div className="text-center m-5">
                <span>{t("messages.empty_data")}</span>

                { activitiesErrors && (
                  <p>{activitiesErrors}</p>
                )}
              </div>
            )
        }
      </div>

      <MaintenanceActivityModal
        activity={selected}
        onModalClose={onModalClose}
        onRemove={onRemove}
      />
    </div>
  );
};

export default MaintenanceActivitiesForm;
