import gql from "graphql-tag";

const INVENTORY_ITEM_QUERY = gql`
query InventoryItemQuery($id: Int!, $brandId: Int) {
  inventoryItem(id: $id, brandId: $brandId) {
    id
    availableQuantity
    availableBrandQuantity
  }
}
`;

export default INVENTORY_ITEM_QUERY;
