import React from "react";
import { useTranslation } from "react-i18next";
import classnames from "classnames";

const MaintenanceActivityRow = ({
  error: _error,
  formupData: _data,
  value,
  nestedErrors,
}) => {
  const [t] = useTranslation();
  const descriptionClasses = classnames("description", {
    "has-errors": !!nestedErrors,
  });

  return (
    <div className="maintenance-activity-row">
      <div className="border" />

      <span className={descriptionClasses}>
        {value?.description}

        {
          nestedErrors && (
            <div className="error-message">
              <i className="fa fwr fa-exclamation-triangle" />
              {t("messages.this_item_contain_errors")}
            </div>
          )
        }
      </span>
    </div>
  );
};

export default MaintenanceActivityRow;
