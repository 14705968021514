import React from "react";
import classnames from "classnames";
import { Spinner } from "react-bootstrap";

const Loader = ({
  fullPage = false,
}) => {
  const loaderClasses = classnames("loader", {
    "full-page": fullPage,
  });

  return (
    <div className={loaderClasses}>
      <Spinner
        animation="grow"
        variant="primary"
      />
    </div>
  );
};

export default Loader;
