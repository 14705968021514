import React from "react";

import { RouteProvider } from "./RouteContext";

const RouteContainer = ({
  componentProps,
  routeProps,
  children,
}) => {
  const value = {
    componentProps,
    routeProps,
  };

  return (
    <RouteProvider value={value}>
      {children}
    </RouteProvider>
  );
};

export default RouteContainer;
