import gql from "graphql-tag";

const APPROVE_PURCHASE_ORDER_MUTATION = gql`
  mutation ApprovePurchaseOrder($id: Int!) {
    approvePurchaseOrder(id: $id) {
      id
    }
  }
`;

export default APPROVE_PURCHASE_ORDER_MUTATION;
