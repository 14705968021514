import React from "react";
import { ApolloProvider } from "react-apollo";
import { Router, Route } from "react-router-dom";
import { QueryParamProvider } from "use-query-params";

import CurrentUserContainer from "contexts/currentUser/CurrentUserContainer";
import SnackbarContainer from "contexts/snackbar/SnackbarContainer";
import ErrorBoundary from "components/ErrorBoundary/ErrorBoundary";
import AppRouter from "components/AppRouter/AppRouter";
import apolloClient from "settings/apolloClient";
import history from "settings/history";

const App = () => (
  <ErrorBoundary>
    <ApolloProvider client={apolloClient}>
      <SnackbarContainer>
        <CurrentUserContainer>
          <Router history={history}>
            <QueryParamProvider ReactRouterRoute={Route}>
              <AppRouter />
            </QueryParamProvider>
          </Router>
        </CurrentUserContainer>
      </SnackbarContainer>
    </ApolloProvider>
  </ErrorBoundary>
);

export default App;
