import gql from "graphql-tag";

const MAINTENANCE_PLAN_QUERY = gql`
  query MaintenancePlanQuery($id: Int!) {
    maintenancePlan(id: $id) {
      id
      code
      maintenanceActivities {
        id
        serviceItem {
          id
          description
          serviceItemGroup {
            id
            name
          }
        }
        maintenanceMaterials {
          id
          inventoryItem {
            id
            name
            code
            unit {
              id
              unit
            }
          }
          quantity
        }
      }
    }
  }
`;

export default MAINTENANCE_PLAN_QUERY;
