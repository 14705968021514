export const ALL = "all";

export const PRODUCT = "product";

export const SERVICE = "service";

/**
 * Only types accepted in backend.
 */
const validTypes = [PRODUCT, SERVICE];

export const formatType = (type) => {
  if (validTypes.includes(type)) {
    return type;
  }

  return undefined;
};
