import getEnumSelectOptions from "utils/getEnumSelectOptions";

export const INTERNAL = "internal";
export const EXTERNAL = "external";

const maintenanceActivityKinds = {
  INTERNAL,
  EXTERNAL,
};

export const maintenanceActivityKindOptions = getEnumSelectOptions(
  Object.values(maintenanceActivityKinds),
  "enums.maintenance_order.kind",
);

export const defaultKind = maintenanceActivityKindOptions.find(({ value }) => value === INTERNAL);

export const getMaintenanceActivityKind = (type) => maintenanceActivityKindOptions.find(
  ({ value }) => value === type,
);

export default maintenanceActivityKinds;
