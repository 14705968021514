import React from "react";
import InputMask from "react-text-mask";

import BaseInput from "components/Inputs/BaseInput";

export const masks = {
  phone: ["(", /\d/, /\d/, ")", " ", /\d/, /\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/],
  cep: [/\d/, /\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/],
};

const MaskedInput = ({
  value,
  ...props
}) => (
  <BaseInput
    value={value ?? ""}
    Component={InputMask}
    guide={false}
    inputClassName="form-control"
    showMask
    {...props}
  />
);

export default MaskedInput;
