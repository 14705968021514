import { EXTERNAL } from "constants/maintenanceActivityKinds";

const parseValue = (value) => parseFloat(value || 0);

/**
 * Extracts purchase request items from maintenance activity orders.
 *
 * @param {*} activities
 */
const extractPurchaseRequestItems = (activities = []) => (
  activities.reduce((acc, cur) => {
    const isExternal = cur.kind === EXTERNAL;
    const isRequested = cur?.maintenanceActivity?.isRequested;

    const maintenanceActivity = isExternal && !isRequested
      ? [{
        maintenanceActivityId: cur.maintenanceActivity.id,
        serviceItemId: (
          cur.maintenanceActivity.serviceItemId
          || cur.maintenanceActivity.serviceItem?.id
        ),
        quantity: 1,
      }]
      : [];

    const missingMaterials = (cur.maintenanceActivity.maintenanceMaterials || [])
      .filter(({
        quantity,
        reservedQuantity,
        requestedQuantity,
      }) => quantity > (parseValue(reservedQuantity) + parseValue(requestedQuantity)))
      .map((material) => ({
        maintenanceMaterialId: material.id,
        inventoryItemId: material.inventoryItemId || material.inventoryItem?.id,
        brandId: material.brandId || material.brand?.id,
        quantity: (
          parseValue(material.quantity)
          - parseValue(material.reservedQuantity)
          - parseValue(material.requestedQuantity)
        ),
      }));

    return [
      ...acc,
      ...maintenanceActivity,
      ...missingMaterials,
    ];
  }, [])
);

export default extractPurchaseRequestItems;
