import React from "react";
import {
  Container,
  Row,
  Col,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-apollo";

import MaintenancePlansCard from "./MaintenancePlansCard";
import DailyChecklistsCard from "./DailyChecklistsCard";
import ChartCard from "./ChartCard";
import EQUIPMENT_MAINTENANCES_REPORT_QUERY from "./graphql/equipmentMaintenancesReportQuery";

const Dashboard = () => {
  const [t] = useTranslation();

  const {
    data,
  } = useQuery(EQUIPMENT_MAINTENANCES_REPORT_QUERY);

  return (
    <Container>
      <Row>
        <Col lg={6}>
          <ChartCard
            title={t("dashboard.charts.maintenance_plans")}
            value={data?.equipmentsMaintenancesReport?.equipmentsWithMaintenancePlans}
          />
        </Col>

        <Col lg={6}>
          <ChartCard
            title={t("dashboard.charts.daily_checklists")}
            value={data?.equipmentsMaintenancesReport?.doneChecklists}
          />
        </Col>
      </Row>

      <Row>
        <Col xs={12}>
          <MaintenancePlansCard />
        </Col>

        <Col xs={12}>
          <DailyChecklistsCard />
        </Col>
      </Row>
    </Container>
  );
};

export default Dashboard;
