import React from "react";
import {
  Card,
  Table,
} from "react-bootstrap";
import { useQuery } from "react-apollo";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { formatDate } from "utils/dateFormats";
import EmptyData from "components/EmptyData/EmptyData";
import MaintenanceOrderActions from "components/MaintenanceOrderActions";

import EQUIPMENT_LAST_MAINTENANCES_QUERY from "./graphql/equipmentLastMaintenancesQuery";

const LastMaintenancesCard = () => {
  const [t] = useTranslation();
  const { id } = useParams();

  const {
    data,
    loading,
    error,
  } = useQuery(EQUIPMENT_LAST_MAINTENANCES_QUERY, {
    variables: {
      id: parseInt(id),
    },
    skip: !id,
  });

  return (
    <Card>
      <Card.Body>
        {
          data
            ? (
              <>
                <h4 className="card-title">{t("equipments.last_maintenances.title")}</h4>

                <Table hover>
                  <thead>
                    <tr>
                      <th>{t("attributes.maintenance_order.code")}</th>
                      <th>{t("attributes.maintenance_order.type")}</th>
                      <th>{t("attributes.maintenance_order.description")}</th>
                      <th>{t("attributes.maintenance_order.responsible")}</th>
                      <th>{t("attributes.maintenance_order.closing_date")}</th>
                      <th>{t("actions.actions")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      data.equipment?.lastMaintenances.length
                        ? (
                          data.equipment?.lastMaintenances.map(({
                            id: maintenanceId,
                            code,
                            type,
                            description,
                            closingDate,
                            responsible,
                          }) => (
                            <tr key={maintenanceId}>
                              <td>
                                {code}
                              </td>
                              <td>
                                {t(`enums.maintenance_order.type.${type}`)}
                              </td>
                              <td>
                                {description}
                              </td>
                              <td>
                                {responsible?.name}
                              </td>
                              <td>
                                {formatDate(closingDate)}
                              </td>
                              <td>
                                <MaintenanceOrderActions id={maintenanceId} />
                              </td>
                            </tr>
                          ))
                        )
                        : (
                          <tr>
                            <td className="text-center" colSpan="5">
                              {t("equipments.last_maintenances.empty_data")}
                            </td>
                          </tr>
                        )
                    }
                  </tbody>
                </Table>
              </>
            )
            : (
              <EmptyData
                error={error}
                loading={loading}
              />
            )
        }
      </Card.Body>
    </Card>
  );
};

export default LastMaintenancesCard;
