import React, { useMemo, useCallback, useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Button,
} from "react-bootstrap";
import { useQuery, useMutation } from "react-apollo";
import { useParams, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { MdCompareArrows } from "react-icons/md";
import Swal from "sweetalert2";

import EmptyData from "components/EmptyData/EmptyData";
import Table from "components/Table/Table";
import companyNameFormatter from "utils/formatters/companyNameFormatter";
import { formatDate } from "utils/dateFormats";
import { onQueryError } from "utils/queryHandlers";
import { useSnackbar } from "contexts/snackbar/SnackbarContext";
import { PURCHASE_ORDERS_DETAIL_LOCATION, PRICE_QUOTES_COMPARE_LOCATION } from "router/locations";
import CreateProviderAction from "components/CreateProviderAction";

import PRICE_QUOTE_QUERY from "./graphql/priceQuoteQuery";
import SEND_PENDING_EMAILS_MUTATION from "./graphql/sendPendingEmailsMutation";
import RESEND_EMAIL_MUTATION from "./graphql/resendEmailMutation";
import { getRequestTableHeaders, getProviderTableHeaders, getPurchaseOrderHeaders } from "./getTableHeaders";
import AddProvidersModal from "./AddProvidersModal";

const PriceQuoteDetails = () => {
  const history = useHistory();
  const params = useParams();
  const [t] = useTranslation();
  const notify = useSnackbar();
  const [addModalOpen, setAddModalOpen] = useState(false);

  const id = parseInt(params?.id);

  const handleCompare = useCallback(() => {
    history.push(PRICE_QUOTES_COMPARE_LOCATION.toUrl({
      id,
    }));
  }, [
    history,
    id,
  ]);

  const [sendPendingEmails, {
    loading: sendPendingEmailsLoading,
  }] = useMutation(SEND_PENDING_EMAILS_MUTATION);

  const [resendEmail] = useMutation(RESEND_EMAIL_MUTATION);

  const toggleAddModal = useCallback(() => {
    setAddModalOpen((prev) => !prev);
  }, [setAddModalOpen]);

  const handleSendPendingEmails = useCallback(() => {
    if (!id) {
      return;
    }

    sendPendingEmails({
      variables: { id },
      refetchQueries: [
        { query: PRICE_QUOTE_QUERY, variables: { id } },
      ],
    })
      .then(() => {
        notify.success(t("price_quote_details.pending_emails_sent"));
      })
      .catch(onQueryError);
  }, [
    sendPendingEmails,
    notify,
    id,
    t,
  ]);

  const onResendEmail = useCallback((priceQuoteProviderId) => {
    resendEmail({
      variables: { id: priceQuoteProviderId },
      refetchQueries: [
        { query: PRICE_QUOTE_QUERY, variables: { id } },
      ],
    })
      .then(() => {
        notify.success(t("price_quote_details.email_resent"));
      })
      .catch(onQueryError);
  }, [
    resendEmail,
    notify,
    id,
    t,
  ]);

  const handleResendEmail = useCallback((item) => () => {
    const priceQuoteProviderId = item.id;

    if (item.answeredAt) {
      Swal.fire({
        title: t("price_quote_details.delete_and_resend_email_confirm"),
        text: t("actions.this_action_cant_be_undone"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: t("actions.confirm"),
        cancelButtonText: t("actions.cancel"),
      })
        .then((result) => {
          if (result.value) {
            onResendEmail(priceQuoteProviderId);
          }
        });
    } else {
      onResendEmail(priceQuoteProviderId);
    }
  }, [
    onResendEmail,
    t,
  ]);

  const seePurchaseOrder = useCallback((item) => () => {
    const url = PURCHASE_ORDERS_DETAIL_LOCATION.toUrl({
      id: item.id,
    });

    history.push(url);
  }, [
    history,
  ]);

  const {
    data,
    loading,
    error,
  } = useQuery(PRICE_QUOTE_QUERY, {
    variables: {
      id,
    },
    skip: !id,
  });

  const requestHeaders = useMemo(getRequestTableHeaders, []);
  const providerHeaders = useMemo(() => getProviderTableHeaders(handleResendEmail), [
    handleResendEmail,
  ]);
  const purchaseOrderHeaders = useMemo(() => getPurchaseOrderHeaders(seePurchaseOrder), [
    seePurchaseOrder,
  ]);

  if (!data) {
    return (
      <EmptyData
        error={error}
        loading={loading}
      />
    );
  }

  const priceQuote = data?.priceQuote || {};
  const purchaseOrders = priceQuote?.purchaseOrders || [];

  return (
    <Container>
      <Row>
        <Col md={12}>
          <Card>
            <Card.Body>
              <h4 className="card-title">
                {t("price_quote_details.code", { code: priceQuote?.code })}
              </h4>

              <Row>
                <Col md={12}>
                  <h5 className="mt-3">{t("price_quotes_new.requests")}</h5>

                  <Table
                    headers={requestHeaders}
                    items={priceQuote?.priceQuoteItems}
                    hover={false}
                  />
                </Col>
              </Row>

              <Row>
                <Col md={5}>
                  <h5>{t("attributes.price_quotes.company")}</h5>
                  <span>{companyNameFormatter(priceQuote?.company)}</span>
                </Col>

                <Col md={5}>
                  <h5>{t("attributes.price_quotes.address")}</h5>
                  <span>{priceQuote?.address?.fullAddress}</span>
                </Col>

                <Col md={2}>
                  <h5>{t("attributes.price_quotes.delivery_deadline")}</h5>
                  <span>{formatDate(priceQuote?.deliveryDeadline)}</span>
                </Col>
              </Row>

              <div className="d-flex justify-content-between align-items-center">
                <h5 className="mt-3">{t("price_quotes_new.providers")}</h5>

                <CreateProviderAction />
              </div>

              <Row>
                <Col md={12}>
                  <Table
                    headers={providerHeaders}
                    items={priceQuote?.priceQuoteProviders}
                    hover={false}
                    size="sm"
                  />

                  <div className="d-flex justify-content-between align-items-center">
                    <Button
                      variant="outline-primary"
                      disabled={!priceQuote?.canCreateOrders}
                      onClick={handleCompare}
                    >
                      <MdCompareArrows
                        size="1.5em"
                        className="mr-3"
                      />

                      {t("price_quotes.compare")}
                    </Button>

                    <div>
                      <Button
                        variant="outline-primary"
                        onClick={toggleAddModal}
                      >
                        {t("price_quote_details.add_providers")}
                      </Button>

                      <Button
                        variant="outline-primary"
                        onClick={handleSendPendingEmails}
                        disabled={sendPendingEmailsLoading}
                      >
                        {t("price_quote_details.send_pending_emails")}
                      </Button>
                    </div>
                  </div>
                </Col>
              </Row>

            </Card.Body>
          </Card>
        </Col>
      </Row>

      {
        purchaseOrders.length > 0 && (
          <Row>
            <Col md={12}>
              <Card>
                <Card.Body>
                  <h4 className="card-title">
                    {t("price_quote_details.purchase_orders")}
                  </h4>

                  <Table
                    headers={purchaseOrderHeaders}
                    items={purchaseOrders}
                    hover={false}
                  />
                </Card.Body>
              </Card>
            </Col>
          </Row>
        )
      }

      <AddProvidersModal
        priceQuoteId={id}
        show={addModalOpen}
        toggleModal={toggleAddModal}
      />
    </Container>
  );
};

export default PriceQuoteDetails;
