import gql from "graphql-tag";

const UPDATE_MAINTENANCE_WORK_MUTATION = gql`
mutation UpdateMaintenanceWork($id: Int!, $totalPrice: Float!){
  updateMaintenanceWork(
    id: $id,
    totalPrice: $totalPrice
  ) {
    id
  }
}
`;

export default UPDATE_MAINTENANCE_WORK_MUTATION;
