import React from "react";
import { Form } from "react-bootstrap";

import BaseInput from "components/Inputs/BaseInput";

const TextInput = ({
  value,
  ...props
}) => (
  <BaseInput
    {...props}
    value={value ?? ""}
    Component={Form.Control}
  />
);

export default TextInput;
