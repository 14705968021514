import React from "react";
import {
  Container,
  Card,
  Row,
  Col,
} from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useQuery } from "react-apollo";
import { useTranslation } from "react-i18next";

import { formatDate, fullDateMask } from "utils/dateFormats";
import EquipmentDetailsCard from "components/EquipmentDetailsCard/EquipmentDetailsCard";
import EmptyData from "components/EmptyData/EmptyData";

import DAILY_CHECKLISTS_QUERY from "./graphql/dailyChecklistQuery";
import DailyChecklistItemsTable from "./DailyChecklistItemsTable";

const DailyChecklistDetails = () => {
  const [t] = useTranslation();
  const { id } = useParams();

  const {
    data,
    loading,
    error,
  } = useQuery(DAILY_CHECKLISTS_QUERY, {
    variables: {
      id: parseInt(id),
    },
    skip: !id,
  });

  const dailyChecklist = data?.dailyChecklist;

  return (
    <Container>
      <Row>
        <Col md={12}>
          <EquipmentDetailsCard equipmentId={data?.dailyChecklist?.equipment?.id} />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <Card>
            <Card.Body>
              <h4 className="card-title">
                {t("daily_checklists.title")}
              </h4>

              {
                dailyChecklist
                  ? (
                    <>
                      <Row className="model-details">
                        <Col md={6}>
                          <span>{t("attributes.daily_checklist.operator")}</span>
                          <h5>{dailyChecklist?.operator?.name}</h5>
                        </Col>

                        <Col md={6}>
                          <span>{t("attributes.daily_checklist.done_at")}</span>
                          <h5>{formatDate(dailyChecklist?.doneAt, fullDateMask)}</h5>
                        </Col>
                      </Row>

                      <Row>
                        <Col md={12}>
                          <DailyChecklistItemsTable
                            items={dailyChecklist?.dailyChecklistItems}
                            equipment={dailyChecklist?.equipment}
                          />
                        </Col>
                      </Row>
                    </>
                  )
                  : (
                    <EmptyData
                      error={error}
                      loading={loading}
                    />
                  )
              }
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default DailyChecklistDetails;
