import React, { useState } from "react";
import classnames from "classnames";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import handleStopPropagation from "utils/handleStopPropagation";

const ReadMoreText = ({
  text,
}) => {
  const [visible, setVisible] = useState(false);
  const [t] = useTranslation();

  const classes = classnames("read-more-text", {
    visible,
  });

  return (
    <>
      <div className={classes}>
        {text}
      </div>

      <Button
        size="sm"
        variant="outline-primary"
        onClick={handleStopPropagation(() => setVisible(!visible))}
      >
        {
          visible
            ? t("actions.see_less")
            : t("actions.see_more")
        }
      </Button>
    </>
  );
};

export default ReadMoreText;
