import yup from "settings/yup";
import selectTransform from "settings/yup/transforms/selectTransform";
import isValidId from "settings/yup/tests/isValidId";

const providerIdSchema = yup
  .number()
  .required()
  .test(isValidId)
  .transform(selectTransform);

const addProvidersSchema = yup
  .object()
  .shape({
    newProviderIds: yup
      .array()
      .required()
      .of(providerIdSchema),
  });

export default addProvidersSchema;
