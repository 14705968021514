import React from "react";
import {
  Card,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-apollo";
import { Link } from "react-router-dom";

import { MAINTENANCES_DETAIL_LOCATION } from "router/locations";
import {
  AWAITING_MATERIALS,
  READY_TO_START,
  IN_EXECUTION,
  FINISHED,
} from "constants/maintenanceStatus";

import MaintenanceCard from "./MaintenanceCard";
import MAINTENANCE_ORDERS_QUERY from "./graphql/maintenanceOrdersQuery";

const ICONS = {
  [AWAITING_MATERIALS]: "fa fa-hourglass-end text-warning fa-2x",
  [READY_TO_START]: "fa fa-flag text-success fa-2x",
  [IN_EXECUTION]: "fa fa-wrench text-info fa-2x",
  [FINISHED]: "fa fa-check-square-o text-success fa-2x",
};

const Board = ({
  status,
}) => {
  const [t] = useTranslation();
  const board = status.toLowerCase();

  const { data } = useQuery(MAINTENANCE_ORDERS_QUERY, {
    variables: {
      filters: {
        status,
      },
    },
  });

  const maintenanceOrders = data?.maintenanceOrders || [];

  return (
    <Card className="board">
      <Card.Body>
        <div className="board-title card-title">
          <h5>
            {t(`maintenance_boards.boards.${board}`)}
          </h5>

          <i className={ICONS[status]} />
        </div>

        <div className="card-content">
          {
            maintenanceOrders.map((order) => (
              <Link
                key={order.id}
                to={MAINTENANCES_DETAIL_LOCATION.toUrl({
                  id: order.id,
                })}
              >
                <MaintenanceCard order={order} />
              </Link>
            ))
          }
        </div>
      </Card.Body>
    </Card>
  );
};

export default Board;
