import { format } from "date-fns";

import getDateFnsLocale from "translations/dateFns";

/**
 * Time mask.
 * E.g. 12:00:00
 */
export const timeMask = "HH:mm";

/**
 * Hours and minutes.
 * E.g. 12:00
 */
export const hoursAndMinutes = "HH:mm";

/**
 * Date mask.
 * E.g. 01 April 2020
 */
export const dateMask = "d MMMM yyyy";

/**
 * Full date mask.
 * E.g. 1 April 2020 - 12:00:00
 */
export const fullDateMask = `${dateMask} - ${timeMask}`;

/**
 * Mask with year, month, day, for backend usage.
 * E.g. 2020-12-25
 */
export const yearMonthDay = "yyyy-MM-dd";

/**
 * Mask for date pickers.
 * E.g. 25/12/2020
 */
export const datePickerDateMask = "dd/MM/yyyy";

/**
 * Date time mask.
 * E.g. 1 April 2020 - 12:00:00
 */
export const dateTimeMask = `${datePickerDateMask} - ${timeMask}`;

/**
 * Mask for short month name, day and hour.
 * E.g. May 3th - 08:00 PM
 */
export const monthNameDayHour = "MMM do - p";

/**
 * Mask for detailed time.
 * E.g. 08:00 PM
 */
export const detailedTime = "p";

/**
 * Safely formats a date with a mask.
 * @param {*} date the date.
 * @param {*} mask the mask.
 * @param {*} fallback fallback return value if date is invalid.
 */
export const formatDate = (
  date,
  mask = datePickerDateMask,
  fallback = "",
) => {
  if (!date) {
    return fallback;
  }

  return format(new Date(date), mask, {
    locale: getDateFnsLocale(),
  });
};

/**
 * Safely formats a date to fullDate mask.
 * @param {*} date the date.
 */
export const formatFullDate = (date) => formatDate(date, fullDateMask);

/**
 * Safely formats a date to a time mask.
 * @param {*} date the date.
 */
export const formatTime = (date) => formatDate(date, timeMask);
