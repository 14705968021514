import React from "react";
import {
  Card,
  Row,
  Col,
} from "react-bootstrap";
import { useQuery } from "react-apollo";
import { useTranslation } from "react-i18next";

import EmptyData from "components/EmptyData/EmptyData";

import EQUIPMENT_QUERY from "./graphql/equipmentQuery";

const EquipmentDetailsCard = ({
  equipmentId,
}) => {
  const [t] = useTranslation();

  const {
    data,
    loading,
    error,
  } = useQuery(EQUIPMENT_QUERY, {
    variables: {
      id: parseInt(equipmentId),
    },
    skip: !equipmentId,
  });

  const equipment = data?.equipment;

  return (
    <Card>
      <Card.Body>
        {
          equipment
            ? (
              <Row className="model-details">
                <Col lg={2} md={4}>
                  <span>{t("attributes.equipment.code")}</span>
                  <h5>{equipment.code}</h5>
                </Col>

                <Col lg={2} md={4}>
                  <span>{t("attributes.equipment.type")}</span>
                  <h5>{equipment.equipmentType.name}</h5>
                </Col>

                <Col lg={3} md={4}>
                  <span>{t("attributes.equipment.detail")}</span>
                  <h5>{equipment.equipmentDetail.name}</h5>
                </Col>

                <Col lg={3} md={6}>
                  <span>{t("attributes.equipment.brand")}</span>
                  <h5>{equipment.brand || "-"}</h5>
                </Col>

                <Col lg={2} md={6}>
                  <span>{t("attributes.equipment.year")}</span>
                  <h5>{equipment.modelYear || "-"}</h5>
                </Col>
              </Row>
            )
            : (
              <EmptyData
                error={error}
                loading={loading}
              />
            )
        }
      </Card.Body>
    </Card>
  );
};

export default EquipmentDetailsCard;
