import React, { useMemo, useCallback, useState } from "react";
import {
  Container,
  Card,
  Row,
  Col,
  Table,
  Accordion,
  Button,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-apollo";
import Pagination from "react-js-pagination";
import {
  MdCompareArrows,
  MdShoppingCart,
  MdInfo,
  MdKeyboardArrowDown,
  MdKeyboardArrowUp,
} from "react-icons/md";
import { useHistory } from "react-router-dom";

import AutomaticTable from "components/Table/Table";
import usePagination from "hooks/usePagination";
import Loader from "components/Loader/Loader";
import OptionSelector from "components/Inputs/OptionSelector/OptionSelector";
import { PAGINATION_OPTIONS } from "constants/defaultPaginationOptions";
import EmptyData from "components/EmptyData/EmptyData";
import { formatDate } from "utils/dateFormats";
import Tooltip from "components/Tooltip/Tooltip";
import handleStopPropagation from "utils/handleStopPropagation";
import { PURCHASE_ORDERS_NEW_PATH } from "router/routes";
import { PRICE_QUOTES_DETAIL_LOCATION, PRICE_QUOTES_COMPARE_LOCATION } from "router/locations";
import SortingHeader from "components/SortingHeader";

import PRICE_QUOTES_QUERY from "./graphql/priceQuotesQuery";
import { getPriceQuoteItemTableHeaders } from "./getTableHeaders";

const PriceQuotes = () => {
  const [t] = useTranslation();
  const history = useHistory();
  const [active, setActive] = useState(null);
  const [sorting, setSorting] = useState({});

  const {
    page,
    perPage,
    setPage,
    handlePerPageChange,
  } = usePagination();

  const {
    data,
    error,
    loading,
  } = useQuery(PRICE_QUOTES_QUERY, {
    variables: {
      pagination: {
        page,
        perPage,
        ...sorting,
      },
    },
  });

  const handleSeeDetails = useCallback((id) => () => {
    history.push(PRICE_QUOTES_DETAIL_LOCATION.toUrl({
      id,
    }));
  }, [
    history,
  ]);

  const toggleActive = useCallback((id) => () => {
    if (active === id) {
      setActive(null);
    } else {
      setActive(id);
    }
  }, [
    active,
    setActive,
  ]);

  const handleCompare = useCallback((id) => () => {
    history.push(PRICE_QUOTES_COMPARE_LOCATION.toUrl({
      id,
    }));
  }, [
    history,
  ]);

  const handleCreatePurchaseOrder = useCallback((priceQuote) => () => {
    history.push({
      pathname: PURCHASE_ORDERS_NEW_PATH,
      state: {
        quotationId: priceQuote?.bestQuotation?.id,
      },
    });
  }, [
    history,
  ]);

  const requestHeaders = useMemo(getPriceQuoteItemTableHeaders, []);

  if (!data) {
    return (
      <EmptyData
        error={error}
        loading={loading}
      />
    );
  }

  return (
    <Container>
      <Row>
        <Col md={12}>
          <Card>
            <Card.Body>
              <h4 className="card-title">
                {t("price_quotes.title")}
              </h4>

              <Row>
                <Col md={4}>
                  <OptionSelector
                    value={{ label: perPage, value: perPage }}
                    onChange={handlePerPageChange}
                    options={PAGINATION_OPTIONS}
                    label={t("tables.results_per_page")}
                  />
                </Col>
              </Row>

              <Row>
                <Col md={12}>
                  {
                    loading
                      ? (
                        <Loader />
                      )
                      : (
                        <Table>
                          <thead>
                            <tr>
                              <th>
                                <span />
                              </th>

                              <SortingHeader
                                label={t("attributes.price_quotes.code")}
                                sortingColumn="code"
                                sorting={sorting}
                                onSortingChange={setSorting}
                              />

                              <SortingHeader
                                label={t("attributes.price_quotes.responsible")}
                                sortingColumn="users.name"
                                sorting={sorting}
                                onSortingChange={setSorting}
                              />

                              <SortingHeader
                                label={t("attributes.price_quotes.sent_quotations")}
                              />

                              <SortingHeader
                                label={t("attributes.price_quotes.answered_quotations")}
                              />

                              <SortingHeader
                                label={t("attributes.price_quotes.delivery_deadline")}
                                sortingColumn="code"
                                sorting={sorting}
                                onSortingChange={setSorting}
                              />

                              <SortingHeader
                                label={t("actions.actions")}
                              />
                            </tr>
                          </thead>
                          <Accordion as="tbody">
                            {
                              data?.priceQuotes?.length
                                ? (
                                  data?.priceQuotes.map((priceQuote) => (
                                    <React.Fragment key={priceQuote.id}>
                                      <Accordion.Toggle
                                        as="tr"
                                        eventKey={priceQuote.id}
                                        className="clickable"
                                        onClick={toggleActive(priceQuote.id)}
                                      >
                                        <td>
                                          {
                                            active === priceQuote.id
                                              ? (
                                                <MdKeyboardArrowUp size="1.5em" />
                                              )
                                              : (
                                                <MdKeyboardArrowDown size="1.5em" />
                                              )
                                          }
                                        </td>
                                        <td>
                                          {priceQuote.code}
                                        </td>
                                        <td>
                                          {priceQuote.responsible?.name}
                                        </td>
                                        <td>
                                          {priceQuote.sentQuotations}
                                        </td>
                                        <td>
                                          {priceQuote.answeredQuotations}
                                        </td>
                                        <td>
                                          {formatDate(priceQuote.deliveryDeadline)}
                                        </td>
                                        <td className="d-flex justify-content-around align-items-center">
                                          <Tooltip text={t("actions.see_details")}>
                                            <Button
                                              variant="light"
                                              className="btn-simple"
                                              onClick={handleStopPropagation(
                                                handleSeeDetails(priceQuote.id),
                                              )}
                                            >
                                              <MdInfo size="1.5em" />
                                            </Button>
                                          </Tooltip>

                                          <Tooltip text={t("price_quotes.compare")}>
                                            <Button
                                              variant="light"
                                              className="btn-simple"
                                              disabled={!priceQuote?.canCreateOrders}
                                              onClick={handleStopPropagation(
                                                handleCompare(priceQuote.id),
                                              )}
                                            >
                                              <MdCompareArrows size="1.5em" />
                                            </Button>
                                          </Tooltip>

                                          <Tooltip text={t("price_quotes.generate_purchase_order")}>
                                            <Button
                                              variant="light"
                                              className="btn-simple"
                                              onClick={handleStopPropagation(
                                                handleCreatePurchaseOrder(priceQuote),
                                              )}
                                              disabled={(
                                                !priceQuote?.bestQuotation
                                                || !priceQuote?.canCreateOrders
                                              )}
                                            >
                                              <MdShoppingCart size="1.5em" />
                                            </Button>
                                          </Tooltip>
                                        </td>
                                      </Accordion.Toggle>

                                      <tr className="accordion-row">
                                        <td colSpan="7">
                                          <Accordion.Collapse
                                            eventKey={priceQuote.id}
                                          >
                                            <Card className="card-default m-3">
                                              <Card.Body>
                                                <AutomaticTable
                                                  headers={requestHeaders}
                                                  items={priceQuote?.priceQuoteItems}
                                                  hover={false}
                                                />
                                              </Card.Body>
                                            </Card>
                                          </Accordion.Collapse>
                                        </td>
                                      </tr>
                                    </React.Fragment>
                                  ))
                                )
                                : (
                                  <tr>
                                    <td className="text-center" colSpan="7">
                                      {t("messages.empty_data")}
                                    </td>
                                  </tr>
                                )
                            }
                          </Accordion>
                        </Table>
                      )
                  }
                </Col>
              </Row>

              <Row>
                <Col md={12}>
                  <div className="d-flex justify-content-center align-items-center">
                    <Pagination
                      activePage={page}
                      itemsCountPerPage={perPage}
                      totalItemsCount={data?.countPriceQuotes || 0}
                      onChange={setPage}
                      activeClass="active"
                      disabledClass="disabled"
                    />
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default PriceQuotes;
