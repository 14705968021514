import gql from "graphql-tag";

const CURRENT_USER_QUERY = gql`
  query GetCurrentUser {
    currentUser {
      id
      name
      email
      group
    }
  }
`;

export default CURRENT_USER_QUERY;
