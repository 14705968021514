import gql from "graphql-tag";

const MAINTENANCE_ORDERS_QUERY = gql`
  query MaintenanceOrders($search: String) {
    maintenanceOrders(
      filters: {
        search: $search,
        onlyByCode: true,
      }
    ) {
      id
      code
    }
  }
`;

export default MAINTENANCE_ORDERS_QUERY;
