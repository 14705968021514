import update from "immutability-helper";

import { getMaintenanceOrderType, PREVENTIVE } from "constants/maintenanceOrderTypes";
import { defaultKind } from "constants/maintenanceActivityKinds";
import i18n from "translations/i18n";

let materialQuantities = {};

const buildInventoryItemKey = (inventoryItemId, brandId) => `${inventoryItemId}${brandId}`;

const extractActivitiesInfo = ({ maintenanceActivities }) => {
  const activities = maintenanceActivities.map((activity, index) => {
    const {
      serviceItem,
      maintenanceMaterials: materials,
    } = activity || {};

    const maintenanceMaterials = materials.map((material) => {
      const brandId = material?.brand?.id;
      const key = buildInventoryItemKey(material?.inventoryItem?.id, brandId);
      const quantity = material?.quantity ?? 0;
      const availableQuantity = materialQuantities[key]?.availableBrandQuantity ?? 0;
      const reservedQuantity = availableQuantity > quantity ? quantity : availableQuantity;

      materialQuantities = update(materialQuantities, {
        [key]: {
          availableBrandQuantity: {
            $set: availableQuantity - reservedQuantity,
          },
        },
      });

      return {
        inventoryItemId: {
          value: material?.inventoryItem?.id,
          label: material?.inventoryItem?.name,
          unit: material?.inventoryItem?.unit?.unit,
        },
        brandId: (
          brandId
            ? {
              value: brandId,
              label: material?.brand?.name,
            }
            : undefined
        ),
        quantity,
        reservedQuantity,
      };
    });

    return {
      number: index + 1,
      kind: defaultKind,
      maintenanceActivity: {
        serviceItemId: {
          value: serviceItem?.id,
          description: serviceItem?.description,
          serviceItemGroup: {
            id: serviceItem?.serviceItemGroup?.id,
            name: serviceItem?.serviceItemGroup?.name,
          },
        },
        maintenanceMaterials,
      },
    };
  });

  return activities;
};

/**
 * Extractor function to build the maintenance form initial values.
 *
 * Receives an equipment and its plans and converts into the maintenance form initial values.
 *
 * @param {object} equipment A maintenance plan's equipment
 * @param {maintenancePlans} maintenancePlans Equipment's maintenance plans
 */
const extractPreventiveMaintenanceInfo = ({
  equipment,
  maintenancePlans,
  materials,
}) => {
  materialQuantities = materials.reduce((acc, cur) => {
    const key = buildInventoryItemKey(cur.inventoryItemId, cur.brandId);

    return {
      ...acc,
      [key]: cur,
    };
  }, {});

  const activities = maintenancePlans.reduce((acc, cur) => {
    const maintenanceActivities = extractActivitiesInfo(cur);

    return [...acc, ...maintenanceActivities];
  }, []);

  const planCodes = maintenancePlans.map(({ code }) => code).join(" | ");

  return {
    equipmentId: {
      value: equipment?.id,
      label: equipment?.code,
    },
    type: getMaintenanceOrderType(PREVENTIVE),
    description: `${i18n.t("attributes.equipment.plans")}: ${planCodes}`,
    activities,
    measurements: {
      planIds: maintenancePlans.map(({ id }) => id),
    },
  };
};

export const getMaintenanceMaterials = (maintenancePlans) => Object.values(
  maintenancePlans
    .flatMap((maintenancePlan) => maintenancePlan.maintenanceActivities)
    .flatMap((activity) => activity.maintenanceMaterials)
    .reduce((acc, { inventoryItem, brand }) => {
      const inventoryItemId = inventoryItem?.id;
      const brandId = brand?.id;
      const key = [`${inventoryItemId}-${brandId}`];

      return {
        ...acc,
        [key]: {
          inventoryItemId,
          brandId,
        },
      };
    }, {}),
);

export default extractPreventiveMaintenanceInfo;
