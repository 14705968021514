import gql from "graphql-tag";

const CLOSE_MAINTENANCE_ORDER_MUTATION = gql`
mutation CloseMaintenanceOrder($id: Int!){
  closeMaintenanceOrder(
    id: $id,
  ) {
    id
  }
}
`;

export default CLOSE_MAINTENANCE_ORDER_MUTATION;
