import gql from "graphql-tag";

const CREATE_MAINTENANCE_ORDER_MUTATION = gql`
  mutation CreateMaintenanceOrder($params: CreateMaintenanceOrderParams!){
    createMaintenanceOrder(
      params: $params,
    ) {
      id
      activities {
        id
        kind
        maintenanceActivity {
          id
          isRequested
          serviceItem {
            id
          }
          maintenanceMaterials {
            id
            quantity
            requestedQuantity
            reservedQuantity
            inventoryItem {
              id
            }
            brand {
              id
            }
          }
        }
      }
    }
  }
`;

export default CREATE_MAINTENANCE_ORDER_MUTATION;
