import React, {
  useCallback,
} from "react";
import {
  Container,
  Card,
  Row,
  Col,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-apollo";
import { useHistory } from "react-router-dom";

import extractPurchaseRequestItems from "utils/extractors/extractPurchaseRequestItems";
import { MAINTENANCES_PATH } from "router/routes";
import { useSnackbar } from "contexts/snackbar/SnackbarContext";
import { onQueryError } from "utils/queryHandlers";
import MAINTENANCE_ORDERS_QUERY from "views/MaintenanceOrders/graphql/maintenanceOrdersQuery";

import MaintenanceForm from "./MaintenanceForm";
import CREATE_MAINTENANCE_ORDER_MUTATION from "./graphql/createMaintenanceOrderMutation";
import CREATE_PURCHASE_REQUEST_MUTATION from "./graphql/createPurchaseRequestMutation";
import filterMaintenanceParams from "./filterMaintenanceParams";

const MaintenanceNew = () => {
  const history = useHistory();
  const [t] = useTranslation();
  const notify = useSnackbar();

  const [createMaintenance, {
    loading,
  }] = useMutation(CREATE_MAINTENANCE_ORDER_MUTATION);

  const [createPurchaseRequest, {
    loading: creatingPurchaseRequest,
  }] = useMutation(CREATE_PURCHASE_REQUEST_MUTATION);

  const onSuccess = useCallback(() => {
    notify.success(t("new_maintenance.success"));
    history.push(MAINTENANCES_PATH);
  }, [
    notify,
    history,
    t,
  ]);

  const handleSubmitForm = useCallback((params) => {
    const permittedParams = filterMaintenanceParams(params);

    createMaintenance({
      variables: {
        params: permittedParams,
      },
      refetchQueries: [
        { query: MAINTENANCE_ORDERS_QUERY },
      ],
    })
      .then(({ data }) => {
        const maintenanceOrderId = data?.createMaintenanceOrder?.id;
        const purchaseRequestItems = extractPurchaseRequestItems(
          data?.createMaintenanceOrder?.activities,
        );

        if (purchaseRequestItems.length) {
          createPurchaseRequest({
            variables: {
              params: {
                maintenanceOrderId,
                purchaseRequestItems,
              },
            },
          })
            .then(onSuccess)
            .catch(onQueryError);

          return;
        }

        onSuccess();
      })
      .catch(onQueryError);
  }, [
    createMaintenance,
    createPurchaseRequest,
    onSuccess,
  ]);

  return (
    <Container>
      <Row>
        <Col md={12}>
          <Card>
            <Card.Body>
              <h4 className="card-title">{t("new_maintenance.title")}</h4>

              <MaintenanceForm
                handleSubmitForm={handleSubmitForm}
                initialValues={history.location.state?.maintenanceOrder}
                loading={loading || creatingPurchaseRequest}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default MaintenanceNew;
