import React, { useCallback, useMemo, useEffect } from "react";
import {
  Row,
  Col,
  Button,
  Alert,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-apollo";
import {
  FormInput,
  useFormup,
  Form,
  FormArrayField,
} from "@formup/core";
import { MdDelete } from "react-icons/md";

import priceQuoteSchema from "settings/yup/schemas/priceQuoteSchema";
import OptionSelector from "components/Inputs/OptionSelector/OptionSelector";
import DateSelector from "components/Inputs/DateSelector/DateSelector";
import Selector, { queries } from "components/Inputs/Selector/Selector";
import TextInput from "components/Inputs/TextInput/TextInput";
import Table from "components/Table/Table";
import getQuerySelectOptions from "utils/getQuerySelectOptions";
import companyNameFormatter from "utils/formatters/companyNameFormatter";
import CreateProviderAction from "components/CreateProviderAction";

import COMPANIES_QUERY from "./graphql/companiesQuery";
import getTableHeaders from "./getTableHeaders";
import { getCompanyAddressOptions } from "./getCompanyAddresses";

const PriceQuoteForm = ({
  priceQuoteItems,
  handleSubmitForm,
  handleFormError,
  initialValues,
}) => {
  const [t] = useTranslation();
  const headers = useMemo(getTableHeaders, []);

  const {
    data: companiesData,
  } = useQuery(COMPANIES_QUERY);

  const {
    formikForm,
    submitForm,
  } = useFormup(priceQuoteSchema, {
    onSubmit: handleSubmitForm,
    onError: handleFormError,
    transformOnSubmit: true,
    initialValues,
  });

  const companies = useMemo(() => (
    companiesData?.companies ?? []
  ), [companiesData?.companies]);

  const companyOptions = getQuerySelectOptions(companies, companyNameFormatter);

  const selectedCompany = useMemo(() => (
    companies.find(({ id }) => id === formikForm?.values?.companyId?.value)
  ), [
    formikForm,
    companies,
  ]);

  const addressOptions = useMemo(() => (
    getCompanyAddressOptions(selectedCompany)
  ), [selectedCompany]);

  const onCompanyChange = useCallback((company) => {
    /**
     * Get the original company object, because `company` only has value and label.
     *
     * `nextCompany` is a graphql response object with all the companies addresses.
     */
    const nextCompany = companies.find(({ id }) => id === company?.value);
    const nextAddressesOptions = getCompanyAddressOptions(nextCompany);

    /**
     * If the selected company has only one delivery address,
     * keep it selected, otherwise, clear the selection.
     */
    if (nextAddressesOptions.length === 1) {
      formikForm.setFieldValue("addressId", nextAddressesOptions[0]);
    } else {
      formikForm.setFieldValue("addressId", "");
    }
  }, [
    formikForm,
    companies,
  ]);

  useEffect(() => {
    /**
     * If there are only one company keep it selected.
     */
    if (companyOptions.length === 1 && !formikForm?.values?.companyId) {
      formikForm.setFieldValue("companyId", companyOptions[0]);

      onCompanyChange(companyOptions[0]);
    }
  }, [
    onCompanyChange,
    companyOptions,
    formikForm,
    companies,
  ]);

  return (
    <>
      <Form formikForm={formikForm}>
        <h5 className="mt-3">{t("price_quotes_new.requests")}</h5>

        <Table
          headers={headers}
          items={priceQuoteItems}
          hover={false}
        />

        <h5 className="mt-3">{t("price_quotes_new.general_info")}</h5>

        <Row>
          <Col md={3}>
            <FormInput
              name="code"
              type="number"
              component={TextInput}
              injectFormupData
              helpText={t("price_quotes_new.code_help")}
            />
          </Col>

          <Col md={3}>
            <FormInput
              name="companyId"
              component={OptionSelector}
              options={companyOptions}
              injectFormupData
              afterChange={onCompanyChange}
            />
          </Col>

          <Col md={3}>
            <FormInput
              name="addressId"
              component={OptionSelector}
              options={addressOptions}
              injectFormupData
              disabled={!selectedCompany}
            />
          </Col>

          <Col md={3}>
            <FormInput
              name="deliveryDeadline"
              component={DateSelector}
              injectFormupData
            />
          </Col>
        </Row>

        <Row>
          <Col>
            <FormInput
              name="observations"
              as="textarea"
              component={TextInput}
              injectFormupData
            />
          </Col>
        </Row>

        <Row>
          <Col md={12}>
            <FormArrayField name="priceQuoteProvidersAttributes">
              {(items, arrayHelpers) => (
                <>
                  <div className="d-flex justify-content-between align-items-center">
                    <h5 className="mt-3">{t("price_quotes_new.providers")}</h5>

                    <CreateProviderAction />
                  </div>

                  {
                    !!initialValues?.priceQuoteProvidersAttributes?.length && (
                      <Alert variant="info">
                        <strong>{t("price_quotes_new.preload_providers_title")}</strong>
                        <div>{t("price_quotes_new.preload_providers_message")}</div>
                      </Alert>
                    )
                  }

                  {
                    !items.length && (
                      <Alert variant="warning">
                        <strong>{t("price_quotes_new.providers_title")}</strong>
                        <div>{t("price_quotes_new.providers_message")}</div>
                      </Alert>
                    )
                  }

                  {items.map((item, index) => (
                    <div
                      className="d-flex justify-content-between align-items-center"
                      key={item.path}
                    >
                      <div className="w-100">
                        <FormInput
                          name={item.getPath("providerId")}
                          component={Selector}
                          injectFormupData
                          queryObject="providers"
                          query={queries.PROVIDERS}
                          className="mb-1 mr-3"
                        />
                      </div>

                      <Button
                        variant="light"
                        className="btn-simple"
                        onClick={() => arrayHelpers.remove(index)}
                      >
                        <MdDelete size="2em" />
                      </Button>
                    </div>
                  ))}

                  <div className="d-flex justify-content-end flex-row">
                    <Button
                      variant="outline-primary"
                      onClick={() => arrayHelpers.push()}
                    >
                      {t("price_quotes_new.add_provider")}
                    </Button>
                  </div>
                </>
              )}
            </FormArrayField>
          </Col>
        </Row>
      </Form>

      <Button
        variant="primary"
        onClick={submitForm}
      >
        {t("actions.save")}
      </Button>
    </>
  );
};

export default PriceQuoteForm;
