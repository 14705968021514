import gql from "graphql-tag";

const CREATE_PRICE_QUOTE_MUTATION = gql`
  mutation CreatePriceQuote($params: CreatePriceQuoteParams!) {
    createPriceQuote(params: $params) {
      id
    }
  }
`;

export default CREATE_PRICE_QUOTE_MUTATION;
