import Location from "react-app-location";

import yup from "settings/yup";

import {
  EQUIPMENTS_PAGE_PATH,
  DAILY_CHECKLISTS_PAGE_PATH,
  MAINTENANCES_DETAIL_PATH,
  MAINTENANCES_SUMMARY_PATH,
  MAINTENANCE_ACTIVITIES_PATH,
  MAINTENANCES_EDIT_PATH,
  PRICE_QUOTES_DETAIL_PATH,
  PRICE_QUOTES_COMPARE_PATH,
  PURCHASE_ORDERS_DETAIL_PATH,
} from "./routes";

export const EQUIPMENTS_PAGE_LOCATION = new Location(EQUIPMENTS_PAGE_PATH, {
  id: yup
    .number()
    .integer()
    .positive()
    .required(),
});

export const DAILY_CHECKLISTS_PAGE_LOCATION = new Location(DAILY_CHECKLISTS_PAGE_PATH, {
  id: yup
    .number()
    .integer()
    .positive()
    .required(),
});

export const MAINTENANCES_DETAIL_LOCATION = new Location(MAINTENANCES_DETAIL_PATH, {
  id: yup
    .number()
    .integer()
    .positive()
    .required(),
});

export const MAINTENANCES_EDIT_LOCATION = new Location(MAINTENANCES_EDIT_PATH, {
  id: yup
    .number()
    .integer()
    .positive()
    .required(),
});

export const MAINTENANCES_SUMMARY_LOCATION = new Location(MAINTENANCES_SUMMARY_PATH, {
  id: yup
    .number()
    .integer()
    .positive()
    .required(),
});

export const MAINTENANCE_ACTIVITIES_LOCATION = new Location(MAINTENANCE_ACTIVITIES_PATH, {
  id: yup
    .number()
    .integer()
    .positive()
    .required(),
});

export const PRICE_QUOTES_DETAIL_LOCATION = new Location(PRICE_QUOTES_DETAIL_PATH, {
  id: yup
    .number()
    .integer()
    .positive()
    .required(),
});

export const PRICE_QUOTES_COMPARE_LOCATION = new Location(PRICE_QUOTES_COMPARE_PATH, {
  id: yup
    .number()
    .integer()
    .positive()
    .required(),
});

export const PURCHASE_ORDERS_DETAIL_LOCATION = new Location(PURCHASE_ORDERS_DETAIL_PATH, {
  id: yup
    .number()
    .integer()
    .positive()
    .required(),
});
