import React from "react";
import {
  DropdownButton,
  Dropdown,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Scoping from "components/Scoping/Scoping";
import permissions from "constants/permissions.json";
import {
  MAINTENANCES_DETAIL_LOCATION,
  MAINTENANCES_SUMMARY_LOCATION,
  MAINTENANCES_EDIT_LOCATION,
} from "router/locations";

const MaintenanceOrderActions = ({
  id,
}) => {
  const [t] = useTranslation();

  return (
    <DropdownButton
      id="dropdown-basic-button"
      variant="outline-primary"
      size="sm"
      title={t("actions.actions")}
    >
      <Dropdown.Item
        as={Link}
        to={MAINTENANCES_DETAIL_LOCATION.toUrl({
          id,
        })}
      >
        {t("actions.see_details")}
      </Dropdown.Item>

      <Scoping
        permission={permissions.SUMMARY_MAINTENANCE_ORDERS}
      >
        <Dropdown.Item
          as={Link}
          to={MAINTENANCES_SUMMARY_LOCATION.toUrl({
            id,
          })}
        >
          {t("actions.see_summary")}
        </Dropdown.Item>
      </Scoping>

      <Scoping
        permission={permissions.UPDATE_MAINTENANCE_ORDERS}
      >
        <Dropdown.Item
          as={Link}
          to={MAINTENANCES_EDIT_LOCATION.toUrl({
            id,
          })}
        >
          {t("actions.edit")}
        </Dropdown.Item>
      </Scoping>
    </DropdownButton>
  );
};

export default MaintenanceOrderActions;
