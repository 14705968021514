import { getMaintenanceOrderType, CORRECTIVE } from "constants/maintenanceOrderTypes";
import { defaultKind } from "constants/maintenanceActivityKinds";

const extractActivitiesInfo = ({ activity }, index) => ({
  number: index + 1,
  kind: defaultKind,
  maintenanceActivity: {
    serviceItemId: {
      value: activity?.id,
      description: activity?.description,
      serviceItemGroup: {
        id: activity?.serviceItemGroup?.id,
        name: activity?.serviceItemGroup?.name,
      },
    },
  },
});

/**
  * Extractor function to build the maintenance form initialValues.
  *
  * Receives the equipment and the checklist items from the daily checklist. Converts into
  * the initial values needed for the maintenance form.
  *
  * @param {object} equipment Checklist's equipment
  * @param {array} checklistItems Selected checklist items
  */
const extractCorrectiveMaintenanceInfo = ({
  equipment,
  checklistItems,
}) => {
  const activities = checklistItems.map(extractActivitiesInfo);

  return {
    equipmentId: {
      value: equipment?.id,
      label: equipment?.code,
    },
    type: getMaintenanceOrderType(CORRECTIVE),
    activities,
  };
};

export default extractCorrectiveMaintenanceInfo;
