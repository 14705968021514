import gql from "graphql-tag";

const REJECT_PURCHASE_ORDER_MUTATION = gql`
  mutation RejectPurchaseOrder($id: Int!, $reason: String!) {
    rejectPurchaseOrder(id: $id, reason: $reason) {
      id
    }
  }
`;

export default REJECT_PURCHASE_ORDER_MUTATION;
