import i18n from "translations/i18n";

const getInventoryItemUnitHelp = (value) => {
  const unit = value?.unit;

  return unit
    ? `${i18n.t("attributes.maintenance_material.unit")}: ${unit}`
    : null;
};

export default getInventoryItemUnitHelp;
