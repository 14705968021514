import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { MAINTENANCES_PATH } from "router/routes";

import CLOSE_MAINTENANCE_ORDER from "./graphql/closeMaintenanceOrderMutation";
import useConfirmMutation from "../useConfirmMutation";

/**
 * Hook that exposes de logic to close a given maintenance order,
 * with a confirmation modal.
 */
const useCloseMaintenanceOrder = (id) => {
  const [t] = useTranslation();

  const [handleClose, { loading }] = useConfirmMutation({
    id,
    mutation: CLOSE_MAINTENANCE_ORDER,
    title: t("maintenance_order_summary.do_you_want_to_close_this_maintenance_order"),
    text: t("actions.this_action_cant_be_undone"),
    successMessage: t("maintenance_order_summary.maintenance_order_closed"),
    redirectPath: MAINTENANCES_PATH,
  });

  const payload = useMemo(() => ([
    handleClose,
    {
      loading,
    },
  ]), [
    handleClose,
    loading,
  ]);

  return payload;
};

export default useCloseMaintenanceOrder;
