import gql from "graphql-tag";

const EQUIPMENTS_NEXT_MAINTENANCE_PLANS_QUERY = gql`
  query EquipmentsNextMaintenancePlans {
    equipmentsNextMaintenancePlans {
      id
      code
      expiredMaintenances
      expiringMaintenances
    }
  }
`;

export default EQUIPMENTS_NEXT_MAINTENANCE_PLANS_QUERY;
