import gql from "graphql-tag";

const INVENTORY_ITEMS_QUERY = gql`
  query InventoryItems($search: String) {
    inventoryItems(search: $search) {
      id
      name
      code
      unit {
        id
        name
        unit
      }
    }
  }

`;

export default INVENTORY_ITEMS_QUERY;
