import React, {
  useState,
  useCallback,
  useRef,
} from "react";
import {
  Button,
  Modal,
  Row,
  Col,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import {
  useFormup,
  FormInput,
  Form,
} from "@formup/core";
import isFunction from "lodash.isfunction";

import Selector, { queries } from "components/Inputs/Selector/Selector";

import schema from "./schema";
import ServiceItemModalButton from "./ServiceItemModalButton";

/**
  * Allows a user to either select a service item or create a service item and its group.
  *
  * @param {function} callback Called after user confirms, passes selected value as param
  * @param {function} customRender If present, is used to render the display action button
  * @param {*} children Children elements rendered in the modal
  */
const ServiceItemModal = ({
  callback,
  customRender = null,
  children,
}) => {
  const [t] = useTranslation();
  const [open, setOpen] = useState(false);
  const formRef = useRef(null);
  const loadOptionsRef = useRef(null);
  const defaultServiceOptionsRef = useRef([]);

  const openModal = useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  const closeModal = useCallback(() => {
    if (formRef.current) {
      formRef.current.resetForm();
    }

    setOpen(false);
  }, [
    setOpen,
    formRef,
  ]);

  const handleOnFinish = useCallback((payload = {}) => {
    closeModal();

    if (callback && isFunction(callback)) {
      callback({
        ...payload,
        value: payload?.id,
      });
    }
  }, [
    closeModal,
    callback,
  ]);

  const handleSubmitForm = ({ group, description }) => {
    handleOnFinish({
      id: description?.value,
      description: description?.label,
      serviceItemGroup: {
        id: group?.value,
        name: group?.label,
      },
    });
  };

  const {
    formikForm,
    submitForm,
  } = useFormup(schema, {
    onSubmit: handleSubmitForm,
  });

  formRef.current = formikForm;

  const onGroupChange = useCallback(async (group) => {
    formikForm.setFieldValue("description", "");

    const groupId = parseInt(group?.value);
    const hasExtra = !!group?.value
      && !Number.isNaN(groupId);

    const extraParams = hasExtra
      ? { groupId }
      : {};

    if (loadOptionsRef.current) {
      defaultServiceOptionsRef.current = await loadOptionsRef.current("", extraParams).catch(() => []);
    }
  }, [formikForm]);

  return (
    <div>
      <ServiceItemModalButton
        onClick={openModal}
        customRender={customRender}
      />

      <Form formikForm={formikForm}>
        <Modal
          show={open}
          onHide={closeModal}
          backdrop="static"
          size="lg"

        >
          <Modal.Header>
            <Modal.Title>
              {t("maintenances.select_service")}
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Row>
              <Col md={12}>
                {children}
              </Col>
            </Row>

            <Row>
              <Col md={6}>
                <FormInput
                  name="group"
                  component={Selector}
                  query={queries.SERVICE_ITEM_GROUPS}
                  queryObject="serviceItemGroups"
                  afterChange={onGroupChange}
                  injectFormupData
                />
              </Col>

              <Col md={6}>
                <FormInput
                  name="description"
                  component={Selector}
                  query={queries.SERVICE_ITEMS}
                  queryObject="serviceItems"
                  objectLabel="description"
                  cacheOptions={false}
                  defaultOptions={defaultServiceOptionsRef.current}
                  isDisabled={!formikForm?.values?.group}
                  loadOptionsRef={loadOptionsRef}
                  injectFormupData
                />
              </Col>
            </Row>
          </Modal.Body>

          <Modal.Footer>
            <Button
              variant="outline-primary"
              onClick={closeModal}
            >
              {t("actions.close")}
            </Button>

            <Button
              variant="primary"
              onClick={submitForm}
            >
              {t("components.service_item_modal.select_service")}
            </Button>
          </Modal.Footer>
        </Modal>
      </Form>
    </div>
  );
};

export default ServiceItemModal;
