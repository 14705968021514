import React from "react";
import { useTranslation } from "react-i18next";
import { FormInput } from "@formup/core";

import Selector, { queries } from "components/Inputs/Selector/Selector";
import TextInput from "components/Inputs/TextInput/TextInput";

const InventoryChangesForm = ({
  items,
}) => {
  const [t] = useTranslation();

  return (
    items.length
      ? (
        items.map((item) => (
          <tr key={item.path}>
            <td>
              {item?.value?.inventoryItemId?.code}
            </td>
            <td>
              {item?.value?.inventoryItemId?.label}
            </td>
            <td>
              <FormInput
                component={Selector}
                query={queries.BRANDS}
                queryObject="brands"
                injectFormupData
                name={item.getPath("brandId")}
                extraParams={{ inventoryItemId: item?.value?.inventoryItemId?.value }}
              />
            </td>
            <td>
              <FormInput
                name={item.getPath("quantity")}
                component={TextInput}
                injectFormupData
              />
            </td>
            <td>
              {item?.value?.inventoryItemId?.unit}
            </td>
          </tr>
        ))
      )
      : (
        <tr>
          <td className="text-center" colSpan="5">
            {t("maintenance_activity_details.maintenance_materials.empty_data")}
          </td>
        </tr>
      )
  );
};

export default InventoryChangesForm;
