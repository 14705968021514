import React from "react";
import {
  Container,
  Row,
  Col,
  Button,
} from "react-bootstrap";
import classnames from "classnames";
import { MdClose } from "react-icons/md";

const SideModal = ({
  isOpen,
  onModalClose,
  children,
  headerChildren,
}) => {
  const overlayClasses = classnames("side-modal-overlay", {
    "is-open": !!isOpen,
  });

  const modalClasses = classnames("side-modal", {
    "is-open": !!isOpen,
  });

  return (
    <>
      <div className={overlayClasses} />

      <div className={modalClasses}>
        {
          isOpen && (
            <Container className="side-modal-container" fluid>
              <Row>
                <Col>
                  <div className="d-flex justify-content-between align-items-center">
                    <Button
                      variant="light"
                      className="btn-simple"
                      onClick={onModalClose}
                    >
                      <MdClose size="2em" />
                    </Button>

                    {headerChildren}
                  </div>
                </Col>
              </Row>

              {children}
            </Container>
          )
        }
      </div>
    </>
  );
};

export default SideModal;
