import * as Sentry from "@sentry/browser";

export const configureSentry = () => {
  if (process.env.NODE_ENV === "production") {
    Sentry.init({
      environment: process.env.NODE_ENV,
      dsn: "https://cca4a6e0bb974639917995607464b595@sentry.jungsoft.com.br/33",
    });

    Sentry.configureScope(scope => {
      scope.setTag("app", "frontend");
    });
  }
};
