import React, {
  useState,
  useCallback,
  useMemo,
} from "react";
import {
  Container,
  Card,
  Row,
  Col,
  Form,
  Button,
} from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Pagination from "react-js-pagination";
import { useQuery } from "react-apollo";
import { Radio, RadioGroup } from "react-ui-icheck";
import xorBy from "lodash.xorby";

import usePagination from "hooks/usePagination";
import Loader from "components/Loader/Loader";
import TextInput from "components/Inputs/TextInput/TextInput";
import OptionSelector from "components/Inputs/OptionSelector/OptionSelector";
import DateSelector from "components/Inputs/DateSelector/DateSelector";
import { PAGINATION_OPTIONS } from "constants/defaultPaginationOptions";
import Table from "components/Table/Table";
import EmptyData from "components/EmptyData/EmptyData";
import dateTransform from "settings/yup/transforms/dateTransform";
import Selector, { queries } from "components/Inputs/Selector/Selector";
import {
  PRICE_QUOTES_NEW_PATH,
} from "router/routes";
import {
  PRODUCT,
  SERVICE,
  ALL,
  formatType,
} from "constants/purchaseRequestItemTypes";
import { AWAITING_QUOTATION, purchaseRequestItemStatusOptions } from "constants/purchaseRequestItemStatus";

import PURCHASE_REQUEST_ITEMS_QUERY from "./graphql/purchaseRequestItemsQuery";
import getTableHeaders from "./getTableHeaders";
import RequestItemModal from "./RequestItemModal";

const PurchaseRequests = () => {
  const history = useHistory();
  const [t] = useTranslation();
  const [status, setStatus] = useState(null);
  const [requester, setRequester] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [type, setType] = useState(ALL);
  const [maintenanceOrderId, setMaintenanceOrderId] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [isSelecting, setSelecting] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [sorting, setSorting] = useState({});

  const {
    page,
    perPage,
    setPage,
    handlePerPageChange,
  } = usePagination();

  const onModalClose = useCallback(() => {
    setSelectedRow(null);
  }, [
    setSelectedRow,
  ]);

  const onRowClick = useCallback((row) => () => {
    if (isSelecting) {
      if (row.status === AWAITING_QUOTATION) {
        setSelectedItems((prev) => xorBy(prev, [row], "id"));
      }

      return;
    }

    setSelectedRow(row);
  }, [
    setSelectedRow,
    isSelecting,
  ]);

  const handleInputChange = (callback) => ({ target: { value } }) => {
    callback(value);
  };

  const toggleSelection = useCallback(() => {
    setSelectedItems([]);
    setSelecting(!isSelecting);
  }, [
    isSelecting,
    setSelecting,
    setSelectedItems,
  ]);

  const generateQuotation = useCallback(() => {
    if (isSelecting) {
      history.push({
        pathname: PRICE_QUOTES_NEW_PATH,
        state: {
          purchaseRequests: selectedItems,
        },
      });

      setSelecting(false);
    }
  }, [
    history,
    isSelecting,
    setSelecting,
    selectedItems,
  ]);

  const {
    data,
    error,
    loading,
  } = useQuery(PURCHASE_REQUEST_ITEMS_QUERY, {
    variables: {
      pagination: {
        page,
        perPage,
        ...sorting,
      },
      filters: {
        status: status?.value,
        requester,
        startDate: dateTransform(startDate, null),
        endDate: dateTransform(endDate, null),
        kind: formatType(type),
        maintenanceOrderId,
      },
    },
  });

  const headers = useMemo(getTableHeaders, []);

  const items = data?.purchaseRequestItems || [];
  const disabledItems = items.filter((item) => item.status !== AWAITING_QUOTATION);

  if (loading || error) {
    return (
      <EmptyData
        error={error}
        loading={loading}
      />
    );
  }

  return (
    <Container>
      <Row>
        <Col md={12}>
          <Card>
            <Card.Body>
              <h4 className="card-title">
                {t("purchase_requests.title")}
              </h4>

              <Row>
                <Col md={3}>
                  <OptionSelector
                    value={{ label: perPage, value: perPage }}
                    onChange={handlePerPageChange}
                    options={PAGINATION_OPTIONS}
                    label={t("tables.results_per_page")}
                  />
                </Col>

                <Col md={3}>
                  <TextInput
                    onChange={handleInputChange(setRequester)}
                    label={t("purchase_requests.table_headers.requester")}
                    value={requester}
                  />
                </Col>

                <Col md={6}>
                  <Row>
                    <Col md={6}>
                      <DateSelector
                        onChange={setStartDate}
                        label={t("purchase_requests.filters.start_date")}
                        value={startDate}
                      />
                    </Col>

                    <Col md={6}>
                      <DateSelector
                        onChange={setEndDate}
                        label={t("purchase_requests.filters.end_date")}
                        value={endDate}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label className="label">
                      {t("purchase_requests.filters.type")}
                    </Form.Label>

                    <RadioGroup
                      name="radio"
                      className="d-flex align-items-center"
                      radioWrapClassName="form-check form-check-inline"
                      radioWrapTag="div"
                      value={type}
                      onChange={handleInputChange(setType)}
                    >
                      {
                        [ALL, PRODUCT, SERVICE].map((filter) => (
                          <Radio
                            label={t(`enums.purchase_request_items.type_filter.${filter}`)}
                            labelTag="div"
                            labelTagClassName="d-inline"
                            radioClassName="iradio_square-green m-2"
                            value={filter}
                            key={filter}
                          />
                        ))
                      }
                    </RadioGroup>
                  </Form.Group>
                </Col>

                <Col md={3}>
                  <Selector
                    label={t("purchase_requests.filters.source")}
                    component={Selector}
                    query={queries.MAINTENANCE_ORDERS}
                    onChange={(event) => setMaintenanceOrderId(event?.value)}
                    queryObject="maintenanceOrders"
                    objectLabel="code"
                  />
                </Col>

                <Col md={3}>
                  <OptionSelector
                    isClearable
                    value={status}
                    onChange={setStatus}
                    options={purchaseRequestItemStatusOptions}
                    label={t("purchase_requests.filters.status")}
                  />
                </Col>
              </Row>

              <Row>
                <Col>
                  <Button
                    variant={isSelecting ? "warning" : "primary"}
                    onClick={toggleSelection}
                  >
                    {t(isSelecting ? "actions.cancel_select" : "actions.select_items")}
                  </Button>

                  {isSelecting && (
                    <Button
                      variant="primary"
                      onClick={generateQuotation}
                    >
                      {t("actions.generate_quotation")}
                    </Button>
                  )}
                </Col>
              </Row>

              <Row>
                <Col md={12}>
                  {
                    loading
                      ? (
                        <Loader />
                      )
                      : (
                        <Table
                          headers={headers}
                          items={items}
                          onRowClick={onRowClick}
                          selectedItems={selectedItems}
                          disabledItems={disabledItems}
                          showCheckbox={isSelecting}
                          sorting={sorting}
                          onSortingChange={setSorting}
                        />
                      )
                  }
                </Col>
              </Row>

              <Row>
                <Col md={12}>
                  <div className="d-flex justify-content-center align-items-center">
                    <Pagination
                      activePage={page}
                      itemsCountPerPage={perPage}
                      totalItemsCount={data?.countPurchaseRequestItems || 0}
                      onChange={setPage}
                      activeClass="active"
                      disabledClass="disabled"
                    />
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <RequestItemModal
        purchaseRequestItem={selectedRow}
        onModalClose={onModalClose}
      />
    </Container>
  );
};

export default PurchaseRequests;
