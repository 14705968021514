import React, { useCallback, useState } from "react";
import {
  Button,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";

import CreateProviderModal from "./CreateProviderModal";

const CreateProviderAction = () => {
  const [t] = useTranslation();

  const [createModalOpen, setCreateModalOpen] = useState(false);

  const toggleCreateModal = useCallback(() => {
    setCreateModalOpen((prev) => !prev);
  }, [setCreateModalOpen]);

  return (
    <>
      <Button
        variant="primary"
        size="sm"
        onClick={toggleCreateModal}
      >
        {t("price_quote_details.create_provider")}
      </Button>

      <CreateProviderModal
        show={createModalOpen}
        toggleModal={toggleCreateModal}
      />
    </>
  );
};

export default CreateProviderAction;
