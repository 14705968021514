import React from "react";

import i18n from "translations/i18n";
import { formatDate, datePickerDateMask } from "utils/dateFormats";
import CurrencyInput from "components/Inputs/CurrencyInput/CurrencyInput";

export const getItemName = (purchaseRequest) => (
  purchaseRequest?.inventoryItem?.name
  || purchaseRequest?.serviceItem?.description
);

export const getItemType = (purchaseRequest) => {
  const isProduct = !!purchaseRequest?.inventoryItem?.name;
  const value = isProduct ? "product" : "service";

  return i18n.t(`enums.purchase_request_items.type.${value}`);
};

export const getItemUnit = (purchaseRequest) => (
  purchaseRequest?.inventoryItem?.unit?.unit || "SERVICO"
);

export const getItemBrand = (purchaseRequest) => (
  purchaseRequest?.brand?.name || "-"
);

export const getItemCode = (purchaseRequest) => (
  purchaseRequest?.inventoryItem?.code || "-"
);

const getTableHeaders = () => ([
  {
    label: i18n.t("purchase_requests.table_headers.code"),
    key: "code",
    formatter: (_, item) => getItemCode(item),
    sortingColumn: "request_code",
  },
  {
    label: i18n.t("purchase_requests.table_headers.request"),
    key: "request",
    formatter: (_, item) => getItemName(item),
    sortingColumn: "request_name",
  },
  {
    label: i18n.t("purchase_requests.table_headers.type"),
    key: "type",
    formatter: (_, item) => getItemType(item),
  },
  {
    label: i18n.t("purchase_requests.table_headers.quantity"),
    key: "quantity",
    sortingColumn: "quantity",
  },
  {
    label: i18n.t("purchase_requests.table_headers.unit"),
    key: "unit",
    formatter: (_, item) => getItemUnit(item),
    sortingColumn: "request_unit",
  },
  {
    label: i18n.t("purchase_requests.table_headers.request_date"),
    key: "purchaseRequest.createdAt",
    formatter: (value) => formatDate(value, datePickerDateMask, "-"),
    sortingColumn: "purchase_requests_created_at",
  },
  {
    label: i18n.t("purchase_requests.table_headers.requester"),
    key: "purchaseRequest.responsible.name",
    sortingColumn: "users.name",
  },
  {
    label: i18n.t("purchase_requests.table_headers.status"),
    key: "status",
    formatter: (value) => (
      <div className={value}>
        {i18n.t(`enums.purchase_request_items.status.${value}`)}
      </div>
    ),
    tdClass: "status-label",
  },
]);

export const getRequestItemHeaders = () => ([
  {
    label: i18n.t("purchase_requests.table_headers.supplier"),
    key: "provider.name",
  },
  {
    label: i18n.t("purchase_requests.table_headers.brand"),
    key: "brand",
    formatter: (_, { priceQuoteItem }) => getItemBrand(priceQuoteItem),
  },
  {
    label: i18n.t("purchase_requests.table_headers.value"),
    key: "unitValue",
    formatter: (value) => (
      <CurrencyInput
        value={value}
        displayType="text"
      />
    ),
  },
]);

export default getTableHeaders;
