import React, { useState } from "react";
import classnames from "classnames";
import {
  Navbar,
  Container,
} from "react-bootstrap";

const AppNavbar = ({
  pageName,
  handleMiniClick,
}) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    document.documentElement.classList.toggle("nav-open");
    setSidebarOpen((prev) => !prev);
  };

  return (
    <Navbar
      className="navbar-absolute fixed-top"
      expand="lg"
    >
      <Container fluid>
        <div className="navbar-wrapper">
          <div className="navbar-minimize navbar-action">
            <button
              className="center"
              id="minimize-sidebar"
              type="button"
              onClick={handleMiniClick}
            >
              <i className="nc-icon nc-minimal-right text-center visible-on-sidebar-mini" />
              <i className="nc-icon nc-minimal-left text-center visible-on-sidebar-regular" />
            </button>
          </div>

          <div
            className={classnames("navbar-toggle navbar-action", {
              toggled: sidebarOpen,
            })}
          >
            <button
              className="navbar-toggler"
              type="button"
              onClick={toggleSidebar}
            >
              <span className="navbar-toggler-bar bar1" />
              <span className="navbar-toggler-bar bar2" />
              <span className="navbar-toggler-bar bar3" />
            </button>
          </div>

          <div onClick={e => e.preventDefault()}>
            <span className="d-none d-md-block text-title">
              {pageName}
            </span>

            <span className="d-block d-md-none text-title">
              {pageName}
            </span>
          </div>
        </div>
      </Container>
    </Navbar>
  );
};

export default AppNavbar;
