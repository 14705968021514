import gql from "graphql-tag";

const LIST_ITEM_PROVIDERS = gql`
  query ListItemProviders($inventoryItemIds: [Int], $serviceItemIds: [Int]) {
    listItemProviders(inventoryItemIds: $inventoryItemIds, serviceItemIds: $serviceItemIds) {
      id
      name
    }
  }
`;

export default LIST_ITEM_PROVIDERS;
