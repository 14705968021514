
import { groupBy, sum } from "ramda";

const INVENTORY_ITEM_IDENTIFIER = "inventory";
const SERVICE_ITEM_IDENTIFIER = "service";

/**
 * Converts an array of purchase request items into
 * price quote items.
 *
 * It groups equal items together.
 *
 * @param {*} purchaseRequests
 */
const convertToQuoteItems = (purchaseRequests) => {
  const groupedItems = groupBy(({ inventoryItem, serviceItem, brand }) => {
    if (inventoryItem) {
      return [INVENTORY_ITEM_IDENTIFIER, inventoryItem?.id, brand?.id].filter(Boolean).join("-");
    }

    return `${SERVICE_ITEM_IDENTIFIER}-${serviceItem?.id}`;
  }, purchaseRequests || []);

  return Object.values(groupedItems).map((requests) => {
    const firstItem = requests[0];
    const totalQuantity = sum(requests.map(({ quantity }) => quantity));
    const purchaseRequestItemIds = requests.map(({ id }) => id);
    const inventoryItemId = firstItem.inventoryItem?.id;
    const serviceItemId = firstItem.serviceItem?.id;
    const brandId = firstItem.brand?.id;
    const id = [inventoryItemId, serviceItemId, brandId].filter(Boolean).join("-");

    return {
      id,
      inventoryItemId,
      brandId,
      serviceItemId,
      inventoryItem: firstItem.inventoryItem,
      brand: firstItem.brand,
      serviceItem: firstItem.serviceItem,
      purchaseRequestItemIds,
      totalQuantity,
    };
  });
};

export default convertToQuoteItems;
