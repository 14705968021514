import gql from "graphql-tag";

const MAINTENANCE_ACTIVITY_QUERY = gql`
  query MaintenanceActivityQuery($id: Int!) {
    maintenanceActivityOrder(id: $id) {
      id
      number
      kind
      status
      maintenanceOrderId
      maintenanceActivity {
        id
        serviceItem {
          id
          description
          serviceItemGroup {
            id
            name
          }
        }
        maintenanceMaterials {
          id
          inventoryItem {
            id
            name
            code
            unit {
              id
              unit
            }
          }
          quantity
        }
      }
      maintenanceWork {
        id
        startTime
        endTime
        comments
        totalPrice
        operator {
          id
          name
        }
      }
    }
  }
`;

export default MAINTENANCE_ACTIVITY_QUERY;
