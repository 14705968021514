import React from "react";
import { Card } from "react-bootstrap";
import {
  Chart,
  PieSeries,
  Title,
} from "@devexpress/dx-react-chart-bootstrap4";
import {
  Animation,
  Palette,
} from "@devexpress/dx-react-chart";

const scheme = ["#1EA131", "#CCCCCC"];

const ChartCard = ({
  title,
  value = 0,
}) => {
  const data = [
    { data: "value", val: value },
    { data: "fill", val: 100 - value },
  ];

  return (
    <Card>
      <Card.Body>
        <div className="chart-card">
          <div className="chart-container">
            <span>
              {`${value} %`}
            </span>

            <Chart
              data={data}
              height={300}
            >
              <Palette scheme={scheme} />
              <PieSeries
                valueField="val"
                argumentField="data"
                innerRadius={0.8}
              />
              <Title
                text={title}
              />
              <Animation />
            </Chart>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

export default ChartCard;
