import React, {
  useState,
  useCallback,
  useEffect,
} from "react";
import {
  Card,
  ProgressBar,
  Button,
  Modal,
} from "react-bootstrap";
import { useQuery } from "react-apollo";
import { useTranslation } from "react-i18next";
import { useParams, useHistory } from "react-router-dom";
import { groupBy, prop } from "ramda";

import EmptyData from "components/EmptyData/EmptyData";
import extractPreventiveMaintenanceInfo, { getMaintenanceMaterials } from "utils/extractors/extractPreventiveMaintenanceInfo";
import { MAINTENANCES_NEW_PATH } from "router/routes";
import useInventoryQuantity from "hooks/useInventoryQuantity";

import EQUIPMENT_MAINTENANCE_PLANS_QUERY from "./graphql/equipmentMaintenancePlansQuery";
import MAINTENANCE_PLAN_QUERY from "./graphql/maintenancePlanQuery";
import MeasurementGroup from "./MeasurementGroup";

const MaintenancePlansCard = () => {
  const [t] = useTranslation();
  const history = useHistory();
  const { id } = useParams();
  const [selectedPlans, setSelectedPlans] = useState([]);
  const [fetchedPlans, setFetchedPlans] = useState([]);
  const [open, setOpen] = useState(false);
  const [progress, setProgress] = useState(0);

  const {
    fetchAvailableQuantity,
  } = useInventoryQuantity();

  const {
    data,
    loading,
    error,
  } = useQuery(EQUIPMENT_MAINTENANCE_PLANS_QUERY, {
    variables: {
      id: parseInt(id),
    },
    skip: !id,
  });

  const {
    refetch: maintenancePlanRefetch,
  } = useQuery(MAINTENANCE_PLAN_QUERY, {
    skip: true,
    fetchPolicy: "no-cache",
  });

  const maintenancePlans = groupBy(prop("measurement"), data?.equipment?.maintenancePlans || []);
  const measurements = data?.equipment?.measurements || {};

  const onAddPlan = useCallback((planId) => () => {
    if (selectedPlans.includes(planId)) {
      setSelectedPlans((selectedPlans).filter((itemId) => itemId !== planId));
    } else {
      setSelectedPlans([...selectedPlans, planId]);
    }
  }, [selectedPlans, setSelectedPlans]);

  const onFetchPlan = useCallback((plan) => {
    setFetchedPlans((prev) => [...prev, plan]);
  }, [setFetchedPlans]);

  useEffect(() => {
    const currentProgress = parseFloat(
      ((fetchedPlans.length) * 100) / selectedPlans.length,
    ).toFixed(0);

    setProgress(Number.isNaN(currentProgress) ? 0 : currentProgress);
  }, [
    fetchedPlans,
    selectedPlans,
  ]);

  useEffect(() => {
    if (String(progress) === "100") {
      setTimeout(() => {
        Promise.all(getMaintenanceMaterials(fetchedPlans).map(fetchAvailableQuantity))
          .then((materials) => {
            const maintenanceOrder = extractPreventiveMaintenanceInfo({
              equipment: data?.equipment,
              maintenancePlans: fetchedPlans,
              materials,
            });

            setOpen(false);

            history.push({
              pathname: MAINTENANCES_NEW_PATH,
              state: {
                maintenanceOrder,
              },
            });
          })
          .catch(() => {});
      }, 100);
    }
  }, [
    progress,
    data,
    fetchedPlans,
    history,
    fetchAvailableQuantity,
  ]);

  const createMaintenanceOrder = useCallback(() => {
    setOpen(true);

    selectedPlans.forEach((planId) => maintenancePlanRefetch({
      id: planId,
    })
      .then(({ data: maintenanceOrderData }) => {
        onFetchPlan(maintenanceOrderData?.maintenancePlan);
      })
      .catch(() => {}));
  }, [
    setOpen,
    selectedPlans,
    onFetchPlan,
    maintenancePlanRefetch,
  ]);

  return (
    <Card>
      <Card.Body>
        {
          data
            ? (
              <>
                <h4 className="card-title">{t("equipments.maintenance_plans.title")}</h4>

                {
                  Object.keys(maintenancePlans).length
                    ? (
                      Object.keys(maintenancePlans).map((measurement) => (
                        <MeasurementGroup
                          key={measurement}
                          measurement={measurement}
                          measurements={measurements}
                          maintenancePlans={maintenancePlans}
                          onAddPlan={onAddPlan}
                        />
                      ))
                    )
                    : (
                      <p className="text-center">{t("equipments.maintenance_plans.empty_data")}</p>
                    )
                }

                <div className="d-flex justify-content-end align-items-center">
                  <Button
                    variant="primary"
                    onClick={createMaintenanceOrder}
                    disabled={!selectedPlans.length}
                  >
                    {t("equipments.new_preventive_maintenance")}
                  </Button>
                </div>
              </>
            )
            : (
              <EmptyData
                error={error}
                loading={loading}
              />
            )
        }

        <Modal
          show={open}
          backdrop="static"
          size="lg"
        >
          <Modal.Header>
            <Modal.Title>
              {t("equipments.creating_new_maintenance")}
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <ProgressBar now={progress} label={`${progress}%`} />

            <div className="d-flex justify-content-center align-items-center m-2 mt-5">
              {t("messages.please_wait")}
            </div>
          </Modal.Body>
        </Modal>
      </Card.Body>
    </Card>
  );
};

export default MaintenancePlansCard;
