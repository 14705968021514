import gql from "graphql-tag";

const CREATE_PROVIDER_MUTATION = gql`
  mutation CreateProvider($params: CreateProviderParams!) {
    createProvider(params: $params) {
      id
    }
  }
`;

export default CREATE_PROVIDER_MUTATION;
