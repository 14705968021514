import gql from "graphql-tag";

const OPERATORS_QUERY = gql`
  query OperatorsQuery($search: String, $allowedMaintenance: Boolean) {
    operators(search: $search, allowedMaintenance: $allowedMaintenance) {
      id
      name
    }
  }
`;

export default OPERATORS_QUERY;
