import React, { useCallback, useState } from "react";
import { useQuery, useMutation, useLazyQuery } from "react-apollo";
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  Modal,
} from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import EmptyData from "components/EmptyData/EmptyData";
import { useSnackbar } from "contexts/snackbar/SnackbarContext";
import { onQueryError } from "utils/queryHandlers";
import CurrencyInput from "components/Inputs/CurrencyInput/CurrencyInput";
import { getItemName, getItemType, getItemUnit } from "views/PurchaseRequests/getTableHeaders";
import booleanFormatter from "utils/formatters/booleanFormatter";
import Table from "components/Table/Table";
import TextInput from "components/Inputs/TextInput/TextInput";

import PURCHASE_ORDER_QUERY from "./graphql/purchaseOrderQuery";
import PURCHASE_ORDER_PDF_LINK from "./graphql/purchaseOrderPdfQuery";
import APPROVE_PURCHASE_ORDER_MUTATION from "./graphql/approvePurchaseOrderMutation";
import REJECT_PURCHASE_ORDER_MUTATION from "./graphql/rejectPurchaseOrderMutation";
import SEND_PURCHASE_ORDER_MUTATION from "./graphql/sendPurchaseOrderMutation";

const PurchaseOrdersDetails = () => {
  const notify = useSnackbar();
  const [t] = useTranslation();
  const params = useParams();
  const [open, setOpen] = useState(false);
  const [reason, setReason] = useState("");

  const id = parseInt(params.id);

  const closeModal = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const openModal = useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  const [approve, {
    loading: approveLoading,
  }] = useMutation(APPROVE_PURCHASE_ORDER_MUTATION);

  const [reject, {
    loading: rejectLoading,
  }] = useMutation(REJECT_PURCHASE_ORDER_MUTATION);

  const [sendEmail, {
    loading: sendEmailLoading,
  }] = useMutation(SEND_PURCHASE_ORDER_MUTATION);

  const handleChange = useCallback(({ target: { value } }) => {
    setReason(value);
  }, [
    setReason,
  ]);

  const handleSendEmail = useCallback(() => {
    sendEmail({
      variables: {
        id,
      },
    })
      .then(() => {
        notify.success(t("purchase_orders_details.email_sent_successfully"));
      })
      .catch(onQueryError);
  }, [
    t,
    id,
    notify,
    sendEmail,
  ]);

  const handleApprove = useCallback(() => {
    approve({
      variables: {
        id,
      },
      refetchQueries: [
        {
          query: PURCHASE_ORDER_QUERY,
          variables: { id },
        },
      ],
    })
      .then(() => {
        notify.success(t("purchase_orders_details.approved_successfully"));
      })
      .catch(onQueryError);
  }, [
    t,
    id,
    notify,
    approve,
  ]);

  const handleReject = useCallback(() => {
    if (reason.trim().length === 0) {
      notify.error(t("errors.all_fields_required"));
      return;
    }

    reject({
      variables: {
        id,
        reason,
      },
      refetchQueries: [
        {
          query: PURCHASE_ORDER_QUERY,
          variables: { id },
        },
      ],
    })
      .then(() => {
        notify.success(t("purchase_orders_details.rejected_successfully"));
      })
      .catch(onQueryError)
      .finally(closeModal);
  }, [
    t,
    id,
    notify,
    reject,
    reason,
    closeModal,
  ]);

  const {
    data,
    loading,
    error,
  } = useQuery(PURCHASE_ORDER_QUERY, {
    variables: {
      id,
    },
    skip: !id,
  });

  const [getLink, {
    loading: getLinkLoading,
  }] = useLazyQuery(PURCHASE_ORDER_PDF_LINK, {
    variables: {
      id,
    },
    skip: !id,
    onCompleted: (pdfLinkData) => {
      window.open(pdfLinkData?.purchaseOrderPdfLink, "_blank");
    },
  });

  const isLoading = approveLoading || rejectLoading || sendEmailLoading || getLinkLoading;
  const purchaseOrder = data?.purchaseOrder || {};
  const priceQuoteProvider = purchaseOrder?.priceQuoteProvider || {};

  if (!data) {
    return (
      <EmptyData
        error={error}
        loading={loading}
      />
    );
  }

  return (
    <Container>
      <Row>
        <Col md={12}>
          <Card>
            <Card.Body>
              <h5>
                {t("purchase_orders_new.order_info")}
              </h5>

              <Row>
                <Col md="4">
                  <strong className="mr-2">
                    {t("attributes.purchase_order.status")}
                  </strong>
                  <p>
                    {t(`enums.purchase_orders.status.${purchaseOrder?.status}`) || "-"}
                  </p>
                </Col>

                <Col md="4">
                  <strong className="mr-2">
                    {t("attributes.purchase_order.identification")}
                  </strong>
                  <p>
                    {purchaseOrder?.identification || "-"}
                  </p>
                </Col>

                <Col md="4">
                  <strong className="mr-2">
                    {t("attributes.purchase_order.code")}
                  </strong>
                  <p>
                    {purchaseOrder?.code || "-"}
                  </p>
                </Col>
              </Row>

              <hr />

              <Table
                hover={false}
                headers={[
                  {
                    label: t("price_quote_details.table_headers.request"),
                    key: "request",
                    formatter: (_, item) => getItemName(
                      item?.priceQuoteProviderItem?.priceQuoteItem,
                    ),
                  },
                  {
                    label: t("price_quote_details.table_headers.type"),
                    key: "type",
                    formatter: (_, item) => getItemType(
                      item?.priceQuoteProviderItem?.priceQuoteItem,
                    ),
                  },
                  {
                    label: t("price_quote_details.table_headers.quantity"),
                    key: "finalQuantity",
                  },
                  {
                    label: t("price_quote_details.table_headers.unit"),
                    key: "unit",
                    formatter: (_, item) => getItemUnit(
                      item?.priceQuoteProviderItem?.priceQuoteItem,
                    ),
                  },
                  {
                    label: "Disponível",
                    key: "priceQuoteProviderItem.available",
                    formatter: booleanFormatter,
                  },
                  {
                    label: "Valor",
                    key: "priceQuoteProviderItem.value",
                    formatter: (value) => (
                      <CurrencyInput
                        value={value}
                        displayType="text"
                      />
                    ),
                  },
                  {
                    label: "IPI",
                    key: "priceQuoteProviderItem.ipi",
                    formatter: (value) => (
                      <CurrencyInput
                        value={value}
                        displayType="text"
                      />
                    ),
                  },
                  {
                    label: "Desconto",
                    key: "priceQuoteProviderItem.discount",
                    formatter: (value) => (
                      <CurrencyInput
                        value={value}
                        displayType="text"
                      />
                    ),
                  },
                  {
                    label: "Subtotal",
                    key: "totalValue",
                    formatter: (value) => (
                      <CurrencyInput
                        value={value}
                        displayType="text"
                      />
                    ),
                  },
                ]}
                items={purchaseOrder?.purchaseOrderItems || []}
              >
                <tr>
                  <td colSpan="8">
                    <div className="d-flex justify-content-end pr-2 text-bold">
                      {t("attributes.price_quote_provider.freight")}
                    </div>
                  </td>
                  <td>
                    <CurrencyInput
                      value={priceQuoteProvider?.freight}
                      displayType="text"
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan="8">
                    <div className="d-flex justify-content-end pr-2 text-bold">
                      {t("attributes.price_quote_provider.icms")}
                    </div>
                  </td>
                  <td>
                    <CurrencyInput
                      value={priceQuoteProvider?.icms}
                      displayType="text"
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan="8">
                    <div className="d-flex justify-content-end pr-2 text-bold">
                      {t("attributes.price_quote_provider.total_value")}
                    </div>
                  </td>
                  <td>
                    <CurrencyInput
                      value={purchaseOrder?.totalValue}
                      displayType="text"
                    />
                  </td>
                </tr>
              </Table>

              <hr />

              <h5>
                {t("purchase_orders_new.payment_conditions")}
              </h5>

              <Row>
                <Col md="4">
                  <strong className="mr-2">
                    {t("attributes.price_quote_provider.payment_method")}
                  </strong>
                  <p>
                    {priceQuoteProvider?.paymentMethod?.name || "-"}
                  </p>
                </Col>

                <Col md="4">
                  <strong className="mr-2">
                    {t("attributes.price_quote_provider.number_of_installments")}
                  </strong>
                  <p>
                    {priceQuoteProvider?.numberOfInstallments || "-"}
                  </p>
                </Col>

                <Col md="4">
                  <strong className="mr-2">
                    {t("attributes.price_quote_provider.first_due_date")}
                  </strong>
                  <p>
                    {priceQuoteProvider?.firstDueDate}
                  </p>
                </Col>
              </Row>

              {
                purchaseOrder?.status === "pending" && (
                  <div className="d-flex justify-content-start align-items-center">
                    <Button
                      variant="primary"
                      disabled={isLoading}
                      onClick={handleApprove}
                    >
                      {t("actions.approve")}
                    </Button>

                    <Button
                      variant="danger"
                      disabled={isLoading}
                      onClick={openModal}
                    >
                      {t("actions.reject")}
                    </Button>
                  </div>
                )
              }

              {
                purchaseOrder?.status === "approved" && (
                  <div className="d-flex justify-content-start align-items-center">
                    <Button
                      variant="primary"
                      disabled={isLoading}
                      onClick={handleSendEmail}
                    >
                      {t("purchase_orders_details.send_email")}
                    </Button>

                    <Button
                      variant="outline-primary"
                      disabled={isLoading}
                      onClick={getLink}
                    >
                      {t("purchase_orders_details.download_pdf")}
                    </Button>
                  </div>
                )
              }
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Modal
        show={open}
        onHide={closeModal}
        backdrop="static"
        size="md"

      >
        <Modal.Header>
          <Modal.Title>
            {t("purchase_orders_details.reject_purchase_order")}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Row>
            <Col md={12} className="model-details">
              <TextInput
                label={t("attributes.purchase_order.reject_reason")}
                value={reason}
                onChange={handleChange}
              />
            </Col>
          </Row>
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="outline-primary"
            onClick={closeModal}
          >
            {t("actions.close")}
          </Button>

          <Button
            variant="primary"
            onClick={handleReject}
          >
            {t("actions.reject")}
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default PurchaseOrdersDetails;
