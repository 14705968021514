import * as R from "ramda";

import i18n from "translations/i18n";
import yup from "settings/yup";
import selectTransform from "settings/yup/transforms/selectTransform";
import dateTransform from "settings/yup/transforms/dateTransform";
import isValidId from "settings/yup/tests/isValidId";
import { nullableIntegerTransform } from "settings/yup/transforms/nullableTransform";

// Removes non Graphql attributes from the schema
const clearPriceQuoteItems = (items) => items.map((item) => R.omit(["inventoryItem", "serviceItem", "brand", "id"], item));

const priceQuoteItemSchema = yup
  .object()
  .shape({
    inventoryItemId: yup.number(),
    brandId: yup.number(),
    serviceItemId: yup.number(),
    totalQuantity: yup.number(),
  });

const priceQuoteProvidersAttributes = yup
  .object()
  .shape({
    providerId: yup
      .number()
      .required()
      .test(isValidId)
      .transform(selectTransform),
  });

const priceQuoteSchema = yup
  .object()
  .shape({
    code: yup
      .mixed()
      .label(i18n.t("attributes.price_quotes.code"))
      .transform(nullableIntegerTransform),
    observations: yup
      .string()
      .label(i18n.t("attributes.price_quotes.observations")),
    companyId: yup
      .mixed()
      .required()
      .transform(selectTransform)
      .test(isValidId)
      .label(i18n.t("attributes.price_quotes.company")),
    addressId: yup
      .mixed()
      .required()
      .transform(selectTransform)
      .test(isValidId)
      .label(i18n.t("attributes.price_quotes.address")),
    deliveryDeadline: yup
      .string()
      .required()
      .transform(dateTransform)
      .label(i18n.t("attributes.price_quotes.delivery_deadline")),
    priceQuoteItemsAttributes: yup
      .array()
      .required()
      .of(priceQuoteItemSchema)
      .transform(clearPriceQuoteItems),
    priceQuoteProvidersAttributes: yup
      .array()
      .required()
      .of(priceQuoteProvidersAttributes),
  });

export default priceQuoteSchema;
