import React, {
  useState,
  useCallback,
  useMemo,
} from "react";
import {
  Button,
  Modal,
  Row,
  Col,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useFormup } from "@formup/core";
import { useMutation } from "react-apollo";

import { useSnackbar } from "contexts/snackbar/SnackbarContext";
import { onQueryError } from "utils/queryHandlers";
import i18n from "translations/i18n";
import yup from "settings/yup";
import selectTransform from "settings/yup/transforms/selectTransform";
import {
  PENDING,
  EXECUTING,
  FINISHED,
} from "constants/maintenanceActivityStatus";
import {
  READY_TO_START,
  IN_EXECUTION,
} from "constants/maintenanceStatus";
import { EXTERNAL } from "constants/maintenanceActivityKinds";
import { MAINTENANCE_ACTIVITIES_LOCATION } from "router/locations";
import { formatDate, fullDateMask } from "utils/dateFormats";
import Selector from "components/Inputs/Selector/Selector";
import MAINTENANCE_ORDERS_QUERY from "views/MaintenanceBoards/graphql/maintenanceOrdersQuery";
import defaultOperatorParams from "constants/defaultOperatorParams";

import START_MAINTENANCE_ACTIVITY_MUTATION from "./graphql/startMaintenanceActivityMutation";

const schema = yup.object().shape({
  operatorId: yup
    .number()
    .required()
    .transform(selectTransform)
    .label(i18n.t("attributes.maintenance_activity.responsible")),
});

const MaintenanceActivityAction = ({
  activity,
}) => {
  const notify = useSnackbar();
  const history = useHistory();
  const [t] = useTranslation();
  const [open, setOpen] = useState(false);

  const {
    id,
    kind,
    status,
    maintenanceActivity,
  } = activity;

  const closeModal = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const seeDetails = useCallback(() => {
    history.push(MAINTENANCE_ACTIVITIES_LOCATION.toUrl({ id }));
  }, [
    history,
    id,
  ]);

  const [startMaintenanceActivity] = useMutation(START_MAINTENANCE_ACTIVITY_MUTATION);

  const handleSubmitForm = useCallback((values) => {
    startMaintenanceActivity({
      variables: {
        id,
        operatorId: values?.operatorId?.value,
      },
      refetchQueries: [
        {
          query: MAINTENANCE_ORDERS_QUERY,
          variables: { status: READY_TO_START },
        },
        {
          query: MAINTENANCE_ORDERS_QUERY,
          variables: { status: IN_EXECUTION },
        },
      ],
    })
      .then(() => {
        notify.success(t("maintenance_order_details.maintenance_activities.successfully_started"));
        closeModal();
        seeDetails();
      })
      .catch(onQueryError);
  }, [
    startMaintenanceActivity,
    id,
    notify,
    closeModal,
    seeDetails,
    t,
  ]);

  const {
    formikForm,
    submitForm,
    FormInput,
    Form,
  } = useFormup(schema, {
    onSubmit: handleSubmitForm,
    initialValues: {
      operatorId: null,
    },
  });

  const buttons = useMemo(() => ({
    [PENDING]: {
      text: t("actions.start"),
      action: () => {
        setOpen(true);
      },
    },
    [EXECUTING]: {
      text: t("actions.resume"),
      action: seeDetails,
    },
  }), [
    setOpen,
    seeDetails,
    t,
  ]);

  if (status === FINISHED || kind === EXTERNAL) {
    return null;
  }

  const {
    text,
    action,
  } = buttons[status];

  return (
    <>
      <Button
        variant="outline-primary"
        size="sm"
        onClick={action}
      >
        {text}
      </Button>

      <Form formikForm={formikForm}>
        <Modal
          show={open}
          onHide={closeModal}
          backdrop="static"
          size="lg"

        >
          <Modal.Header>
            <Modal.Title>
              {t("maintenance_order_details.maintenance_activities.start_activity")}
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Row>
              <Col md={6} className="model-details">
                <span>{t("attributes.maintenance_activity.description")}</span>
                <h5>{maintenanceActivity?.serviceItem?.description}</h5>
              </Col>

              <Col md={6} className="model-details">
                <span>{t("attributes.service_item.group")}</span>
                <h5>{maintenanceActivity?.serviceItem?.serviceItemGroup?.name}</h5>
              </Col>
            </Row>

            <Row>
              <Col md={6} className="model-details">
                <span>{t("attributes.maintenance_activity.start_time")}</span>
                <h5>{formatDate(new Date(), fullDateMask)}</h5>
              </Col>

              <Col md={6} className="model-details">
                <FormInput
                  name="operatorId"
                  component={Selector}
                  injectFormupData
                  extraParams={defaultOperatorParams}
                />
              </Col>
            </Row>
          </Modal.Body>

          <Modal.Footer>
            <Button
              variant="outline-primary"
              onClick={closeModal}
            >
              {t("actions.close")}
            </Button>

            <Button
              variant="primary"
              onClick={submitForm}
            >
              {t("actions.start")}
            </Button>
          </Modal.Footer>
        </Modal>
      </Form>
    </>
  );
};

export default MaintenanceActivityAction;
