import gql from "graphql-tag";

const MAINTENANCE_ORDERS_QUERY = gql`
  query MaintenanceOrdersQuery($filters: MaintenanceOrdersFilters) {
    maintenanceOrders(filters: $filters) {
      id
      code
      type
      description
      responsible {
        id
        name
      }
      equipment {
        id
        code
      }
    }
  }
`;

export default MAINTENANCE_ORDERS_QUERY;
