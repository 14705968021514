/**
 * Extractor function to build the inventory changes initialValues.
 *
 * Receives the maintenance materials and converts into the inventory changes used in this
 * activity.
 *
 * @param {array} materials Maintenance materials
 */
const extractInventoryChangeInfo = (materials) => materials.map(({
  id,
  inventoryItem,
  quantity,
}) => ({
  materialId: id,
  inventoryItemId: {
    value: inventoryItem?.id,
    label: inventoryItem?.name,
    code: inventoryItem?.code,
    unit: inventoryItem?.unit?.unit,
  },
  brandId: null,
  quantity,
}));

export default extractInventoryChangeInfo;
