import React from "react";
import classnames from "classnames";
import { useTranslation } from "react-i18next";

const WARNING = "warning";
const DANGER = "danger";
const NORMAL = "normal";

const ICONS = {
  [WARNING]: "fa fa-exclamation-triangle",
  [DANGER]: "fa fa-times-circle",
  [NORMAL]: "fa fa-check-circle",
};

export const ATTENTION_STATUSES = [WARNING, DANGER];

const StatusIcon = ({
  status,
}) => {
  const [t] = useTranslation();

  const statusInfoClasses = classnames("status-info", {
    "text-success": status === NORMAL,
    "text-warning": status === WARNING,
    "text-danger": status === DANGER,
  });

  return (
    <div className={statusInfoClasses}>
      <i className={ICONS[status]} />
      {t(`enums.equipment.status.${status}`)}
    </div>
  );
};

export default StatusIcon;
