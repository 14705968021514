import gql from "graphql-tag";

const CANCEL_MAINTENANCE_ORDER_MUTATION = gql`
mutation CancelMaintenanceOrder($id: Int!){
  cancelMaintenanceOrder(
    id: $id,
  ) {
    id
  }
}
`;

export default CANCEL_MAINTENANCE_ORDER_MUTATION;
