import React, { useCallback } from "react";
import {
  Form,
} from "react-bootstrap";
import isFunction from "lodash.isfunction";

const BaseInput = ({
  name,
  label,
  className,
  inputClassName,
  error: _error,
  showLabel = true,
  formupData = {},
  helpText,
  getHelpText,
  Component = Form.Control,
  onChange,
  afterChange,
  value,
  ...props
}) => {
  const { errorMessage } = formupData;

  const displayHelpText = helpText
    ?? (getHelpText && isFunction(getHelpText) && getHelpText(value));

  const handleOnChange = useCallback((event) => {
    if (onChange && isFunction(onChange)) {
      onChange(event);
    }

    if (afterChange && isFunction(afterChange)) {
      afterChange(event, name);
    }
  }, [
    name,
    onChange,
    afterChange,
  ]);

  return (
    <Form.Group className={className}>

      {
        showLabel && (
          <Form.Label className="label">
            {label}
          </Form.Label>
        )
      }

      <Component
        name={name}
        value={value}
        className={inputClassName}
        onChange={handleOnChange}
        autoComplete="off"
        {...props}
      />

      {
        displayHelpText && !errorMessage && (
          <Form.Text className="text-muted">{displayHelpText}</Form.Text>
        )
      }

      {
        errorMessage && (
          <Form.Text className="error-label text-muted">{errorMessage}</Form.Text>
        )
      }

    </Form.Group>
  );
};

export default BaseInput;
