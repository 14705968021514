import React from "react";
import {
  Container,
  Card,
  Row,
  Col,
  Button,
} from "react-bootstrap";
import { useQuery } from "react-apollo";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import permissions from "constants/permissions.json";
import Scoping from "components/Scoping/Scoping";
import useCancelMaintenanceOrder from "hooks/useCancelMaintenanceOrder";
import useCloseMaintenanceOrder from "hooks/useCloseMaintenanceOrder";
import maintenanceActivitySort from "utils/sorts/maintenanceActivitySort";
import CurrencyInput from "components/Inputs/CurrencyInput/CurrencyInput";
import { formatDate, dateTimeMask, datePickerDateMask } from "utils/dateFormats";
import EmptyData from "components/EmptyData/EmptyData";
import {
  EXTERNAL,
  INTERNAL,
} from "constants/maintenanceActivityKinds";

import MaintenanceActivitiesTable from "./MaintenanceActivitiesTable";
import MAINTENANCE_ORDER_QUERY from "./graphql/maintenanceOrderQuery";

const MaintenanceOrderDetails = () => {
  const [t] = useTranslation();
  const params = useParams();
  const id = parseInt(params.id ?? 0);

  const [cancelMaintenanceOrder, { loading: loadingCancel }] = useCancelMaintenanceOrder(id);
  const [closeMaintenanceOrder, { loading: loadingClose }] = useCloseMaintenanceOrder(id);

  const isLoading = loadingCancel || loadingClose;

  const {
    data,
    loading,
    error,
  } = useQuery(MAINTENANCE_ORDER_QUERY, {
    variables: {
      id,
    },
    skip: !id,
  });

  const maintenanceOrder = data?.maintenanceOrder;
  const internalActivities = maintenanceOrder?.activities
    ?.filter(({ kind }) => kind === INTERNAL)
    .sort(maintenanceActivitySort);
  const externalActivities = maintenanceOrder?.activities
    ?.filter(({ kind }) => kind === EXTERNAL)
    .sort(maintenanceActivitySort);

  if (!data) {
    return (
      <EmptyData
        error={error}
        loading={loading}
      />
    );
  }

  return (
    <Container fluid>
      <Row>
        <Col md={12}>
          <Card>
            <Card.Body>
              <h4 className="card-title">
                {t("maintenance_order_summary.internal_activities")}
              </h4>

              <MaintenanceActivitiesTable
                maintenanceOrderId={maintenanceOrder.id}
                headers={[
                  {
                    label: t("attributes.maintenance_activity.number"),
                    key: "number",
                  },
                  {
                    label: t("attributes.maintenance_activity.description"),
                    key: "maintenanceActivity.serviceItem.description",
                  },
                  {
                    label: t("attributes.maintenance_activity.start_time"),
                    key: "maintenanceWork.startTime",
                    formatter: (value) => formatDate(value, dateTimeMask, "-"),
                  },
                  {
                    label: t("attributes.maintenance_activity.end_time"),
                    key: "maintenanceWork.endTime",
                    formatter: (value) => formatDate(value, dateTimeMask, "-"),
                  },
                  {
                    label: t("attributes.maintenance_activity.comments"),
                    key: "maintenanceWork.comments",
                  },
                ]}
                items={internalActivities}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col md={12}>
          <Card>
            <Card.Body>
              <h4 className="card-title">
                {t("maintenance_order_summary.external_activities")}
              </h4>

              <MaintenanceActivitiesTable
                maintenanceOrderId={maintenanceOrder.id}
                headers={[
                  {
                    label: t("attributes.maintenance_activity.number"),
                    key: "number",
                  },
                  {
                    label: t("attributes.maintenance_activity.description"),
                    key: "maintenanceActivity.serviceItem.description",
                  },
                ]}
                items={externalActivities}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col>
          <Card>
            <Card.Body>
              <Row className="model-details">
                <Col md={3}>
                  <span>{t("attributes.maintenance_order.code")}</span>
                  <h5>{maintenanceOrder.code}</h5>
                </Col>

                <Col md={3}>
                  <span>{t("attributes.maintenance_order.type")}</span>
                  <h5>{t(`enums.maintenance_order.type.${maintenanceOrder.type}`)}</h5>
                </Col>

                <Col md={3}>
                  <span>{t("attributes.maintenance_order.responsible")}</span>
                  <h5>{maintenanceOrder.responsible?.name}</h5>
                </Col>

                <Col md={3}>
                  <span>{t("attributes.maintenance_order.total_price")}</span>
                  <h5>
                    <CurrencyInput value={maintenanceOrder.totalCost} displayType="text" />
                  </h5>
                </Col>
              </Row>

              <Row className="model-details">
                <Col md={3}>
                  <span>{t("attributes.maintenance_order.start_time")}</span>
                  <h5>{formatDate(maintenanceOrder.startTime, dateTimeMask, "-")}</h5>
                </Col>

                <Col md={3}>
                  <span>{t("attributes.maintenance_order.end_time")}</span>
                  <h5>{formatDate(maintenanceOrder.endTime, dateTimeMask, "-")}</h5>
                </Col>

                <Col md={3}>
                  <span>{t("attributes.maintenance_order.closing_date")}</span>
                  <h5>{formatDate(maintenanceOrder.closingDate, datePickerDateMask, "-")}</h5>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <div className="d-flex flex-row justify-content-end">
        <Scoping
          permission={permissions.CANCEL_MAINTENANCE_ORDERS}
        >
          <Button
            variant="danger"
            disabled={isLoading}
            onClick={cancelMaintenanceOrder}
          >
            {t("maintenance_order_summary.cancel_maintenance_order")}
          </Button>
        </Scoping>

        <Scoping
          permission={permissions.CLOSE_MAINTENANCE_ORDERS}
        >
          <Button
            variant="success"
            disabled={isLoading}
            onClick={closeMaintenanceOrder}
          >
            {t("maintenance_order_summary.close_maintenance_order")}
          </Button>
        </Scoping>
      </div>
    </Container>
  );
};

export default MaintenanceOrderDetails;
