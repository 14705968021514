import i18n from "translations/i18n";
import yup from "settings/yup";

const purchaseOrderItemSchema = yup
  .object()
  .shape({
    finalQuantity: yup
      .number()
      .required(),
    priceQuoteProviderItemId: yup
      .number()
      .required(),
  });

const purchaseOrderSchema = yup
  .object()
  .shape({
    code: yup
      .number()
      .nullable()
      .default(null)
      .label(i18n.t("attributes.purchase_order.code")),
    identification: yup
      .string()
      .required()
      .label(i18n.t("attributes.purchase_order.identification")),
    priceQuoteProviderId: yup
      .number()
      .required(),
    purchaseOrderItems: yup
      .array()
      .required()
      .of(purchaseOrderItemSchema),
  });

export default purchaseOrderSchema;
