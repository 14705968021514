import React from "react";
import {
  Container,
  Row,
  Col,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import KARTRAK_LOGO from "assets/images/kartrak.png";
import { MAINTENANCES_PATH } from "router/routes";

const NotFound = () => {
  const [t] = useTranslation();

  return (
    <div className="not-found">
      <Container>
        <Row>
          <Col>
            <div className="not-found-content">
              <img
                src={KARTRAK_LOGO}
                alt={t("app.title")}
              />

              <h3>
                {t("not_found.title")}
              </h3>

              <Link to={MAINTENANCES_PATH}>
                {t("not_found.go_back")}
              </Link>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default NotFound;
