export const backendUrl = (
  process.env.NODE_ENV === "production"
    ? `${window.location.protocol}//${window.location.host}`
    : `${window.location.protocol}//localhost:4000`
);

export const DASHBOARD_PAGE_PATH = "/dashboard";

export const EQUIPMENTS_PAGE_PATH = "/equipments/:id";

export const DAILY_CHECKLISTS_PAGE_PATH = "/daily_checklists/:id";

export const MAINTENANCES_PATH = "/maintenance_orders";
export const MAINTENANCES_BOARDS_PATH = `${MAINTENANCES_PATH}/boards`;
export const MAINTENANCES_NEW_PATH = `${MAINTENANCES_PATH}/new`;
export const MAINTENANCES_DETAIL_PATH = `${MAINTENANCES_PATH}/:id`;
export const MAINTENANCES_EDIT_PATH = `${MAINTENANCES_PATH}/:id/edit`;
export const MAINTENANCES_SUMMARY_PATH = `${MAINTENANCES_DETAIL_PATH}/summary`;

export const MAINTENANCE_ACTIVITIES_PATH = "/maintenance_activities/:id";

export const PURCHASE_REQUESTS_PATH = "/purchase_requests";

export const PRICE_QUOTES_PATH = "/price_quotes";
export const PRICE_QUOTES_NEW_PATH = `${PRICE_QUOTES_PATH}/new`;
export const PRICE_QUOTES_DETAIL_PATH = `${PRICE_QUOTES_PATH}/:id`;
export const PRICE_QUOTES_COMPARE_PATH = `${PRICE_QUOTES_PATH}/:id/compare`;

export const PURCHASE_ORDERS_PATH = "/purchase_orders";
export const PURCHASE_ORDERS_NEW_PATH = `${PURCHASE_ORDERS_PATH}/new`;
export const PURCHASE_ORDERS_DETAIL_PATH = `${PURCHASE_ORDERS_PATH}/:id`;
