import i18n from "translations/i18n";

const validReservedQuantity = ({
  name: "validReservedQuantity",
  exclusive: false,
  test(value) {
    const quantity = this?.parent?.quantity || 0;
    const requestedQuantity = this?.parent?.requestedQuantity || 0;

    const missingQuantity = Math.max(quantity - requestedQuantity, 0);
    const isValid = value <= missingQuantity;

    if (!String(value).length) {
      return this.createError({
        message: i18n.t("yup.mixed_required_field"),
        path: this.path,
      });
    }

    if (value < 0) {
      return this.createError({
        message: i18n.t("yup.tests.number_min", { min: 0 }),
        path: this.path,
      });
    }

    if (isValid) {
      return true;
    }

    return this.createError({
      message: i18n.t("yup.tests.invalid_reserved_quantity", { missingQuantity }),
      path: this.path,
    });
  },
});

export default validReservedQuantity;
