import i18n from "translations/i18n";

const getEnumSelectOptions = (constants, translationPath) => (
  constants.map((constant) => (
    {
      value: constant,
      label: i18n.t(`${translationPath}.${constant}`),
    }
  ))
);

export default getEnumSelectOptions;
