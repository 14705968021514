import gql from "graphql-tag";

const MAINTENANCE_ORDERS_QUERY = gql`
  query MaintenanceOrdersQuery($filters: MaintenanceOrdersFilters, $pagination: PaginationParams) {
    countMaintenanceOrders(filters: $filters)
    maintenanceOrders(filters: $filters, pagination: $pagination) {
      id
      code
      description
      type
      closingDate
      equipment {
        id
        code
      }
    }
  }
`;

export default MAINTENANCE_ORDERS_QUERY;
