import gql from "graphql-tag";

const EQUIPMENT_QUERY = gql`
  query EquipmentQuery($id: Int!) {
    equipment(id: $id) {
      id
      code
      status
      equipmentType {
        id
        name
      }
      equipmentDetail {
        id
        name
      }
      brand
      modelYear
    }
  }
`;

export default EQUIPMENT_QUERY;
