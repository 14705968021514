import "react-app-polyfill/stable";

import React from "react";
import ReactDOM from "react-dom";

import { configureSentry } from "settings/sentry";

import "bootstrap/dist/css/bootstrap.css";
import "@devexpress/dx-react-chart-bootstrap4/dist/dx-react-chart-bootstrap4.css";
import "react-datepicker/dist/react-datepicker.css";
import "react-image-gallery/styles/scss/image-gallery.scss";
import "icheck/skins/square/green.css";
import "assets/scss/app.scss";
import "translations/i18n";

import App from "./App";

configureSentry();

ReactDOM.render(<App />, document.getElementById("root"));

if (process.env.NODE_ENV === "production" && "serviceWorker" in navigator) {
  navigator.serviceWorker.register("/service-worker.js");
}
