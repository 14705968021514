import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { MAINTENANCES_PATH } from "router/routes";

import CANCEL_MAINTENANCE_ORDER_MUTATION from "./graphql/cancelMaintenanceOrderMutation";
import useConfirmMutation from "../useConfirmMutation";

/**
 * Hook that exposes de logic to cancel a given maintenance order,
 * with a confirmation modal.
 */
const useCancelMaintenanceOrder = (id) => {
  const [t] = useTranslation();

  const [handleCancel, { loading }] = useConfirmMutation({
    id,
    mutation: CANCEL_MAINTENANCE_ORDER_MUTATION,
    title: t("maintenance_order_summary.do_you_want_to_cancel_this_maintenance_order"),
    text: t("actions.this_action_cant_be_undone"),
    successMessage: t("maintenance_order_summary.maintenance_order_canceled"),
    redirectPath: MAINTENANCES_PATH,
  });

  const payload = useMemo(() => ([
    handleCancel,
    {
      loading,
    },
  ]), [
    handleCancel,
    loading,
  ]);

  return payload;
};

export default useCancelMaintenanceOrder;
