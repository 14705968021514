import gql from "graphql-tag";

const COMPANIES_QUERY = gql`
  query CompaniesQuery {
    companies {
      id
      companyName
      cnpj
      addresses {
        id
        fullAddress
      }
    }
  }
`;

export default COMPANIES_QUERY;
