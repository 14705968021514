export const ASC = "ASC";
export const DESC = "DESC";

export const getNextSortingOrder = (sortingOrder) => {
  if (!sortingOrder) {
    return ASC;
  }

  if (sortingOrder === ASC) {
    return DESC;
  }

  return undefined;
};

export default {
  ASC,
  DESC,
};
