import gql from "graphql-tag";

const ADD_PROVIDERS_MUTATION = gql`
  mutation AddProvidersToPriceQuote($id: Int!, $newProviderIds: [Int]!) {
    addProvidersToPriceQuote(id: $id, newProviderIds: $newProviderIds) {
      id
    }
  }
`;

export default ADD_PROVIDERS_MUTATION;
