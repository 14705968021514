import gql from "graphql-tag";

const END_EXTERNAL_MAINTENANCE_ACTIVITY_MUTATION = gql`
  mutation EndExternalMaintenanceActivity($id: Int!, $inventoryChanges: [MaintenanceChangesParams]){
    endExternalMaintenanceActivity(
      id: $id,
      inventoryChanges: $inventoryChanges,
    ) {
      id
    }
  }
`;

export default END_EXTERNAL_MAINTENANCE_ACTIVITY_MUTATION;
