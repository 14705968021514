import { getMaintenanceOrderType } from "constants/maintenanceOrderTypes";
import { getMaintenanceActivityKind } from "constants/maintenanceActivityKinds";

const extractMaintenanceMaterials = (maintenanceMaterials) => maintenanceMaterials.map(({
  id,
  inventoryItem,
  brand,
  quantity,
  reservedQuantity,
  requestedQuantity,
}) => ({
  id,
  inventoryItemId: {
    value: inventoryItem?.id,
    label: inventoryItem?.name,
    unit: inventoryItem?.unit?.unit,
  },
  brandId: brand
    ? {
      value: brand?.id,
      label: brand?.name,
    }
    : null,
  quantity: quantity ?? 0,
  reservedQuantity: reservedQuantity ?? 0,
  requestedQuantity,
}));

const extractActivities = activities => activities.map(({
  id,
  kind,
  number,
  maintenanceActivity,
}) => ({
  id,
  kind: getMaintenanceActivityKind(kind),
  number,
  maintenanceActivity: {
    id: maintenanceActivity?.id,
    serviceItemId: {
      value: maintenanceActivity?.serviceItem?.id,
      description: maintenanceActivity?.serviceItem?.description,
      serviceItemGroup: maintenanceActivity?.serviceItem?.serviceItemGroup,
    },
    isRequested: maintenanceActivity.isRequested,
    maintenanceMaterials: extractMaintenanceMaterials(maintenanceActivity?.maintenanceMaterials),
  },
}));

/**
 * Extractor function to build the maintenance order initialValues.
 *
 * Receives a maintenance order and transforms it into the maintenance form initial values.
 *
 * @param {object} maintenanceOrder An existing maintenance order
 */
const extractMaintenanceOrderInfo = (maintenanceOrder) => {
  if (!maintenanceOrder) {
    return undefined;
  }

  const {
    equipment,
    type,
    code,
    description,
    comments,
    responsible,
    closingDate,
    activities,
    measurements,
  } = maintenanceOrder;

  const {
    dia,
    hora,
    litro,
    km,
    m3,
    planIds,
  } = measurements || {};

  return {
    equipmentId: {
      value: equipment?.id,
      label: equipment?.code,
    },
    type: getMaintenanceOrderType(type),
    code,
    description,
    comments,
    closingDate: new Date(closingDate),
    responsibleId: {
      value: responsible?.id,
      label: responsible?.name,
    },
    activities: extractActivities(activities),
    measurements: {
      day: dia,
      hour: hora,
      liter: litro,
      km,
      m3,
      planIds,
    },
  };
};

export default extractMaintenanceOrderInfo;
