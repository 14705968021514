import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import { useCurrentUser } from "contexts/currentUser/CurrentUserContext";
import RouteContainer from "contexts/route/RouteContainer";
import AppWrapper from "components/AppWrapper/AppWrapper";
import Loader from "components/Loader/Loader";
import NotFound from "views/NotFound/NotFound";
import appRoutes from "router/appRoutes";
import { MAINTENANCES_PATH } from "router/routes";

const AppRouter = () => {
  const [currentUser] = useCurrentUser();

  if (!currentUser) {
    return <Loader fullPage />;
  }

  return (
    <Switch>
      <Route exact path="/">
        <Redirect to={MAINTENANCES_PATH} />
      </Route>

      {
        appRoutes.map(({
          component: Component,
          componentProps,
          strict,
          exact,
          path,
          ...routeProps
        }) => (
          <Route
            strict={strict}
            exact={exact}
            path={path}
            key={path}
          >
            <RouteContainer
              componentProps={componentProps}
              routeProps={routeProps}
            >
              <AppWrapper>
                <Component />
              </AppWrapper>
            </RouteContainer>
          </Route>
        ))
      }
      <Route component={NotFound} />
    </Switch>
  );
};

export default AppRouter;
