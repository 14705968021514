import React, { useCallback, useRef } from "react";
import {
  Modal,
  Row,
  Col,
  Button,
  Alert,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import {
  FormInput,
  useFormup,
  Form,
  FormArrayField,
} from "@formup/core";
import { MdDelete } from "react-icons/md";
import { useMutation } from "react-apollo";

import addProvidersSchema from "settings/yup/schemas/addProvidersSchema";
import Selector, { queries } from "components/Inputs/Selector/Selector";
import { onQueryError } from "utils/queryHandlers";
import { useSnackbar } from "contexts/snackbar/SnackbarContext";

import PRICE_QUOTE_QUERY from "./graphql/priceQuoteQuery";
import ADD_PROVIDERS_MUTATION from "./graphql/addProvidersMutation";

const AddProvidersModal = ({
  show,
  priceQuoteId,
  toggleModal,
}) => {
  const [t] = useTranslation();
  const notify = useSnackbar();
  const formRef = useRef(null);

  const [addProviders, {
    loading,
  }] = useMutation(ADD_PROVIDERS_MUTATION);

  const closeModal = useCallback(() => {
    if (formRef.current) {
      formRef.current.resetForm();
    }

    toggleModal();
  }, [
    toggleModal,
    formRef,
  ]);

  const handleFormError = useCallback(() => {
    notify.error(t("errors.please_check_if_your_form_is_correct"));
  }, [
    notify,
    t,
  ]);

  const handleSubmitForm = useCallback(({ newProviderIds }) => {
    addProviders({
      variables: {
        id: priceQuoteId,
        newProviderIds,
      },
      refetchQueries: [
        { query: PRICE_QUOTE_QUERY, variables: { id: priceQuoteId } },
      ],
    })
      .then(() => {
        notify.success(t("price_quote_details.providers_added_success"));

        closeModal();
      })
      .catch(onQueryError);
  }, [
    addProviders,
    priceQuoteId,
    closeModal,
    notify,
    t,
  ]);

  const {
    formikForm,
    submitForm,
  } = useFormup(addProvidersSchema, {
    onSubmit: handleSubmitForm,
    onError: handleFormError,
    transformOnSubmit: true,
    initialValues: {
      newProviderIds: [0],
    },
  });

  formRef.current = formikForm;

  return (
    <Modal
      show={show}
      backdrop="static"
      size="lg"
      onHide={closeModal}
    >
      <Modal.Body>
        <Form formikForm={formikForm}>
          <Row>
            <Col md={12}>
              <FormArrayField name="newProviderIds">
                {(items, arrayHelpers) => (
                  <>
                    <div className="d-flex justify-content-between align-items-center">
                      <h5 className="mt-3">{t("price_quote_details.add_providers")}</h5>

                      <Button
                        variant="outline-primary"
                        onClick={() => arrayHelpers.push()}
                      >
                        {t("price_quotes_new.add_provider")}
                      </Button>
                    </div>

                    {
                      !items.length && (
                        <Alert variant="warning">
                          <strong>{t("price_quotes_new.providers_title")}</strong>
                          <div>{t("price_quotes_new.providers_message")}</div>
                        </Alert>
                      )
                    }

                    {items.map((item, index) => (
                      <div
                        className="d-flex justify-content-between align-items-center"
                        key={item.path}
                      >
                        <div className="w-100">
                          <FormInput
                            name={item.path}
                            component={Selector}
                            injectFormupData
                            queryObject="providers"
                            query={queries.PROVIDERS}
                            className="mb-1 mr-3"
                          />
                        </div>

                        <Button
                          variant="light"
                          className="btn-simple"
                          onClick={() => arrayHelpers.remove(index)}
                        >
                          <MdDelete size="2em" />
                        </Button>
                      </div>
                    ))}
                  </>
                )}
              </FormArrayField>
            </Col>
          </Row>
        </Form>

        <Button
          variant="primary"
          onClick={submitForm}
          disabled={loading}
        >
          {t("actions.save")}
        </Button>

        <Button
          variant="outline-primary"
          onClick={closeModal}
          disabled={loading}
        >
          {t("actions.close")}
        </Button>
      </Modal.Body>
    </Modal>
  );
};

export default AddProvidersModal;
