
import gql from "graphql-tag";

const EQUIPMENT_MEASUREMENTS_QUERY = gql`
  query EquipmentMeasurementsQuery($id: Int!){
    equipment(id: $id) {
      id
      measurements {
        dia
        km
        m3
        litro
        hora
      }
    }
  }
`;

export default EQUIPMENT_MEASUREMENTS_QUERY;
