import React from "react";
import { NavLink, useHistory } from "react-router-dom";
import { Nav } from "react-bootstrap";
import { useQuery } from "react-apollo";

import Scoping from "components/Scoping/Scoping";
import i18n from "translations/i18n";
import logo from "assets/images/logo.png";
import APP_URL_QUERY from "graphql/appUrlQuery";

const Sidebar = ({
  routes,
}) => {
  const history = useHistory();
  const { data } = useQuery(APP_URL_QUERY);

  const activeRoute = (routeName) => (
    history.location.pathname === routeName
      ? "active"
      : ""
  );

  return (
    <div className="sidebar">
      <div className="logo">
        <div className="simple-text logo-mini">
          <div className="logo-img">
            <img src={logo} alt="kartrak" />
          </div>
        </div>

        <div className="simple-text logo-normal">
          <span>
            {i18n.t("app.title")}
          </span>
        </div>
      </div>

      <div className="sidebar-wrapper">
        <Nav>
          <div>
            {
              routes.map(({
                path,
                icon,
                name,
                mini,
                permission,
              }) => (
                <Scoping
                  key={path.replace("/", "_")}
                  permission={permission}
                >
                  <li className={activeRoute(path)}>
                    <NavLink to={path} activeClassName="">
                      {
                        icon !== undefined
                          ? (
                            <>
                              <i className={icon} />
                              <p>{name}</p>
                            </>
                          )
                          : (
                            <>
                              <span className="sidebar-mini-icon">{mini}</span>
                              <span className="sidebar-normal">{name}</span>
                            </>
                          )
                      }
                    </NavLink>
                  </li>
                </Scoping>
              ))
            }
          </div>

          <div id="erp-link">
            <li>
              <a href={data?.appUrl}>
                <i className="nc-icon nc-app" />
                <p>{i18n.t("app.link_to_base")}</p>
              </a>
            </li>
          </div>
        </Nav>
      </div>
    </div>
  );
};

export default Sidebar;
