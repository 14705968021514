import React, {
  useState,
} from "react";
import {
  Container,
  Card,
  Row,
  Col,
  Table,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-apollo";
import Pagination from "react-js-pagination";

import usePagination from "hooks/usePagination";
import TextInput from "components/Inputs/TextInput/TextInput";
import Loader from "components/Loader/Loader";
import OptionSelector from "components/Inputs/OptionSelector/OptionSelector";
import { PAGINATION_OPTIONS } from "constants/defaultPaginationOptions";
import { formatDate } from "utils/dateFormats";
import EmptyData from "components/EmptyData/EmptyData";
import MaintenanceOrderActions from "components/MaintenanceOrderActions";
import SortingHeader from "components/SortingHeader";

import MAINTENANCE_ORDERS_QUERY from "./graphql/maintenanceOrdersQuery";

const MaintenanceOrders = () => {
  const [t] = useTranslation();
  const [search, setSearch] = useState(null);
  const [sorting, setSorting] = useState({});

  const {
    page,
    perPage,
    setPage,
    handlePerPageChange,
  } = usePagination();

  const handleSearchChange = ({ target: { value } }) => {
    setSearch(value);
  };

  const {
    data,
    error,
    loading,
  } = useQuery(MAINTENANCE_ORDERS_QUERY, {
    variables: {
      pagination: {
        page,
        perPage,
        ...sorting,
      },
      filters: {
        search,
      },
    },
  });

  if (!data) {
    return (
      <EmptyData
        error={error}
        loading={loading}
      />
    );
  }

  return (
    <Container>
      <Row>
        <Col md={12}>
          <Card>
            <Card.Body>
              <h4 className="card-title">
                {t("maintenances.title")}
              </h4>

              <Row>
                <Col md={4}>
                  <OptionSelector
                    value={{ label: perPage, value: perPage }}
                    onChange={handlePerPageChange}
                    options={PAGINATION_OPTIONS}
                    label={t("tables.results_per_page")}
                  />
                </Col>

                <Col md={{ span: 4, offset: 4 }}>
                  <TextInput
                    onChange={handleSearchChange}
                    label={t("tables.search")}
                    value={search}
                  />
                </Col>
              </Row>

              <Row>
                <Col md={12}>
                  {
                    loading
                      ? (
                        <Loader />
                      )
                      : (
                        <Table hover>
                          <thead>
                            <tr>
                              <SortingHeader
                                label={t("attributes.maintenance_order.code")}
                                sortingColumn="code"
                                sorting={sorting}
                                onSortingChange={setSorting}
                              />

                              <SortingHeader
                                label={t("attributes.maintenance_order.equipment")}
                                sortingColumn="equipments_code"
                                sorting={sorting}
                                onSortingChange={setSorting}
                              />

                              <SortingHeader
                                label={t("attributes.maintenance_order.description")}
                                sortingColumn="description"
                                sorting={sorting}
                                onSortingChange={setSorting}
                              />

                              <SortingHeader
                                label={t("attributes.maintenance_order.type")}
                                sortingColumn="kind"
                                sorting={sorting}
                                onSortingChange={setSorting}
                              />

                              <SortingHeader
                                label={t("attributes.maintenance_order.closing_date")}
                                sortingColumn="closing_date"
                                sorting={sorting}
                                onSortingChange={setSorting}
                              />

                              <SortingHeader
                                label={t("actions.actions")}
                              />
                            </tr>
                          </thead>
                          <tbody>
                            {
                              data?.maintenanceOrders?.length
                                ? (
                                  data?.maintenanceOrders.map((maintenanceOrder) => (
                                    <tr key={maintenanceOrder.id}>
                                      <td>{maintenanceOrder.code}</td>
                                      <td>{maintenanceOrder.equipment?.code}</td>
                                      <td>{maintenanceOrder.description}</td>
                                      <td>
                                        {t(`enums.maintenance_order.type.${maintenanceOrder.type}`)}
                                      </td>
                                      <td>{formatDate(maintenanceOrder.closingDate)}</td>
                                      <td>
                                        <MaintenanceOrderActions id={maintenanceOrder.id} />
                                      </td>
                                    </tr>
                                  ))
                                )
                                : (
                                  <tr>
                                    <td className="text-center" colSpan="6">
                                      {t("messages.empty_data")}
                                    </td>
                                  </tr>
                                )
                            }
                          </tbody>
                        </Table>
                      )
                  }
                </Col>
              </Row>

              <Row>
                <Col md={12}>
                  <div className="d-flex justify-content-center align-items-center">
                    <Pagination
                      activePage={page}
                      itemsCountPerPage={perPage}
                      totalItemsCount={data?.countMaintenanceOrders || 0}
                      onChange={setPage}
                      activeClass="active"
                      disabledClass="disabled"
                    />
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default MaintenanceOrders;
