import gql from "graphql-tag";

const OPERATORS_QUERY = gql`
  query EquipmentsQuery($search: String) {
    equipments(search: $search) {
      id
      code
    }
  }
`;

export default OPERATORS_QUERY;
