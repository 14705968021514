import gql from "graphql-tag";

const EQUIPMENT_MAINTENANCE_PLANS_QUERY = gql`
  query EquipmentMaintenancePlansQuery($id: Int!) {
    equipment(id: $id) {
      id
      code
      maintenancePlans {
        id
        code
        interval
        warningInterval
        measurement
        lastMaintenanceMeasurement
      }
      measurements {
        dia
        km
        hora
        litro
        m3
      }
    }
  }
`;

export default EQUIPMENT_MAINTENANCE_PLANS_QUERY;
