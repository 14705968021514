import getEnumSelectOptions from "utils/getEnumSelectOptions";

export const PURCHASE_APPROVED = "purchase_approved";
export const AWAITING_PURCHASE_APPROVAL = "awaiting_purchase_approval";
export const IN_QUOTATION = "in_quotation";
export const AWAITING_QUOTATION = "awaiting_quotation";

const purchaseRequestItemStatus = {
  PURCHASE_APPROVED,
  AWAITING_PURCHASE_APPROVAL,
  IN_QUOTATION,
  AWAITING_QUOTATION,
};

export const purchaseRequestItemStatusOptions = getEnumSelectOptions(
  Object.values(purchaseRequestItemStatus),
  "enums.purchase_request_items.status",
);

export default purchaseRequestItemStatus;
