import gql from "graphql-tag";

import {
  PRICE_QUOTE_PROVIDER_FIELDS,
  PRICE_QUOTE_ITEMS_FIELDS,
} from "graphql/fragments/priceQuoteFragments";

const PRICE_QUOTE_COMPARE_QUERY = gql`
  ${PRICE_QUOTE_PROVIDER_FIELDS}
  ${PRICE_QUOTE_ITEMS_FIELDS}

  query PriceQuote($id: Int!) {
    priceQuote(id: $id, filter_answered: true) {
      id
      code
      responsible {
        id
        name
      }
      deliveryDeadline
      address {
        id
        fullAddress
      }
      company {
        id
        companyName
        cnpj
      }
      priceQuoteItems {
        ...priceQuoteItemFields
      }
      bestQuotation {
        ...priceQuoteProviderFields
      }
      priceQuoteProviders {
        ...priceQuoteProviderFields
      }
    }
  }
`;

export default PRICE_QUOTE_COMPARE_QUERY;
