import React from "react";
import { useTranslation } from "react-i18next";
import { Container, Row } from "react-bootstrap";

const Footer = () => {
  const [t] = useTranslation();

  return (
    <footer className="footer">
      <Container fluid>
        <Row>
          <div className="credits ml-auto">
            <span className="copyright">
              {`${new Date().getFullYear()} `}
              &copy;
              {` ${t("app.title")}`}
            </span>
          </div>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
