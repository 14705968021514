import React from "react";
import {
  Row,
  Col,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";

import MaintenanceActivityAction from "./MaintenanceActivityAction";

const MaintenanceActivity = ({
  activity,
}) => {
  const [t] = useTranslation();

  if (!activity) {
    return null;
  }

  return (
    <div className="maintenance-activity">
      <Row>
        <Col md={8}>
          <p className="kind">{t(`enums.maintenance_order.kind.${activity.kind}`)}</p>
          <p className="group">{activity.maintenanceActivity.serviceItem.serviceItemGroup?.name || "-"}</p>
          <p>
            <span className="number">
              {`${activity.number}.`}
            </span>
            {activity.maintenanceActivity.serviceItem.description}
          </p>

          {
            !!activity.maintenanceActivity.maintenanceMaterials?.length && (
              <>
                <div className="text-bold maintenance-materials-title">
                  {t("maintenance_order_details.maintenance_activities.maintenance_materials")}
                </div>
                <div className="maintenance-materials">
                  {
                    activity.maintenanceActivity.maintenanceMaterials.map((material) => (
                      <div className="maintenance-material" key={material.id}>
                        <div className="material-quantity">
                          <span>{material.quantity}</span>
                          <span>{material?.inventoryItem?.unit?.unit}</span>
                        </div>

                        <p>
                          <span className="text-bold">
                            {`${t("attributes.inventory_item.code")}: `}
                          </span>
                          {material.inventoryItem?.code}
                        </p>
                        <p>
                          <span className="text-bold">
                            {`${t("attributes.inventory_item.name")}: `}
                          </span>
                          {material.inventoryItem?.name}
                        </p>

                        {
                          material.isMissing && (
                            <div className="badge badge-danger mt-2">
                              {t("maintenance_order_details.maintenance_activities.missing_material")}
                            </div>
                          )
                        }
                      </div>
                    ))
                  }
                </div>
              </>
            )
          }
        </Col>

        <Col md={4}>
          <div className="activity-status">
            <span className="text-bold">
              {t("maintenance_order_details.maintenance_activities.status")}
            </span>
            <p>
              {t(`enums.maintenance_activity_order.status.${activity.status}`)}
            </p>
            <MaintenanceActivityAction
              activity={activity}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default MaintenanceActivity;
