import React from "react";
import {
  Table,
  ProgressBar,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { measurementFormatter } from "utils/formatters/decimalFormatter";

const getProgressVariant = (value, interval, warningInterval) => {
  if (value > interval) {
    return "danger";
  }

  if (value > warningInterval) {
    return "warning";
  }

  return "success";
};

const MeasurementGroup = ({
  maintenancePlans,
  measurements,
  measurement,
  onAddPlan,
}) => {
  const [t] = useTranslation();
  const plans = maintenancePlans[measurement] || [];
  const value = parseFloat(measurements[measurement] || 0);

  return (
    <div className="measurement-group" key={measurement}>
      <div className="group-title">
        <h5>
          {`${t("equipments.maintenance_plans.measurement")}: ${measurement}`}
        </h5>

        <span>
          {`${t("equipments.maintenance_plans.last_measurement")}: ${measurementFormatter(value, measurement)} ${measurement}`}
        </span>
      </div>

      <Table hover responsive>
        <thead>
          <tr>
            <th>
              {t("equipments.maintenance_plans.code")}
            </th>
            <th>
              {t("equipments.maintenance_plans.interval")}
            </th>
            <th>
              {t("equipments.maintenance_plans.last_maintenance")}
            </th>
            <th>
              {t("equipments.maintenance_plans.status")}
            </th>
            <th className="text-center">
              {t("equipments.maintenance_plans.select")}
            </th>
          </tr>
        </thead>
        <tbody>
          {
            plans.map(({
              id: planId,
              code,
              interval,
              warningInterval,
              lastMaintenanceMeasurement,
            }) => {
              const currentValue = value - lastMaintenanceMeasurement;
              const currentProgress = (currentValue * 100) / interval;
              const variant = getProgressVariant(currentValue, interval, warningInterval);

              return (
                <tr key={planId}>
                  <td>{code}</td>
                  <td>{`${interval} ${measurement}`}</td>
                  <td>{lastMaintenanceMeasurement ? `${measurementFormatter(lastMaintenanceMeasurement, measurement)} ${measurement}` : "-"}</td>
                  <td>
                    <ProgressBar
                      now={currentProgress}
                      label={`${currentProgress.toFixed(0)} %`}
                      variant={variant}
                    />
                  </td>
                  <td className="d-flex justify-content-center align-items-center">
                    <input
                      type="checkbox"
                      onClick={onAddPlan(planId)}
                    />
                  </td>
                </tr>
              );
            })
          }
        </tbody>
      </Table>
    </div>
  );
};

export default MeasurementGroup;
