import React, { useCallback, useMemo, useEffect } from "react";
import { useQuery, useMutation } from "react-apollo";
import {
  Container,
  Row,
  Col,
  Card,
  Button,
} from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  useFormup,
  FormArrayField,
  FormInput,
  Form,
} from "@formup/core";

import EmptyData from "components/EmptyData/EmptyData";
import Table from "components/Table/Table";
import { getItemName, getItemType, getItemUnit } from "views/PurchaseRequests/getTableHeaders";
import CurrencyInput from "components/Inputs/CurrencyInput/CurrencyInput";
import purchaseOrderSchema from "settings/yup/schemas/purchaseOrderSchema";
import TextInput from "components/Inputs/TextInput/TextInput";
import { useSnackbar } from "contexts/snackbar/SnackbarContext";
import { onQueryError } from "utils/queryHandlers";
import { PURCHASE_ORDERS_DETAIL_LOCATION } from "router/locations";

import PRICE_QUOTE_PROVIDER_QUERY from "./graphql/priceQuoteProviderQuery";
import CREATE_PURCHASE_ORDER_MUTATION from "./graphql/createPurchaseOrderMutation";

const PurchaseOrdersNew = () => {
  const notify = useSnackbar();
  const history = useHistory();
  const [t] = useTranslation();
  const { quotationId } = history.location.state || {};

  const [createPurchaseOrder, {
    loading: createPurchaseOrderLoading,
  }] = useMutation(CREATE_PURCHASE_ORDER_MUTATION);

  const {
    data,
    loading,
    error,
  } = useQuery(PRICE_QUOTE_PROVIDER_QUERY, {
    variables: {
      id: quotationId,
    },
    skip: !quotationId,
  });

  const priceQuoteProvider = useMemo(() => (
    data?.priceQuoteProvider || {}
  ), [data]);

  const handleSubmitForm = useCallback((form) => {
    const params = {
      ...form,
      priceQuoteProviderId: priceQuoteProvider?.id,
      purchaseOrderItems: (form?.purchaseOrderItems || []).map(({
        finalQuantity,
        priceQuoteProviderItemId,
      }) => ({
        finalQuantity: parseFloat(finalQuantity),
        priceQuoteProviderItemId,
      })),
    };

    createPurchaseOrder({
      variables: {
        params,
      },
    })
      .then(({ data: { createPurchaseOrder: result } }) => {
        notify.success(t("purchase_orders_new.successfully_created"));

        const url = PURCHASE_ORDERS_DETAIL_LOCATION.toUrl({
          id: result?.id,
        });

        history.push(url);
      })
      .catch(onQueryError);
  }, [
    t,
    notify,
    history,
    priceQuoteProvider,
    createPurchaseOrder,
  ]);

  const purchaseOrderItems = useMemo(() => (priceQuoteProvider?.priceQuoteProviderItems || [])
    .filter(({ available }) => available)
    .map((item) => ({
      priceQuoteProviderItemId: item.id,
      finalQuantity: item.priceQuoteItem.totalQuantity,
      item,
    })), [
    priceQuoteProvider,
  ]);

  const {
    formikForm,
    submitForm,
  } = useFormup(purchaseOrderSchema, {
    onSubmit: handleSubmitForm,
    transformOnSubmit: true,
  });

  useEffect(() => {
    formikForm.setFieldValue("purchaseOrderItems", purchaseOrderItems);
    // Disabling this rule because adding `formikForm` to the dependency
    // array would lead to an infinite loop.
    //
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [purchaseOrderItems]);

  const totalValue = useMemo(() => {
    const purchaseOrderItemsValue = (formikForm?.values?.purchaseOrderItems || [])
      .reduce((acc, cur) => (
        (parseInt(cur.finalQuantity) * parseFloat(cur.item.unitValue)) + parseFloat(acc)
      ), 0);

    const values = [
      priceQuoteProvider?.freight,
      priceQuoteProvider?.icms,
      purchaseOrderItemsValue,
    ];

    return values.reduce((acc, cur) => (parseFloat(cur) + parseFloat(acc)), 0);
  }, [
    priceQuoteProvider,
    formikForm,
  ]);

  if (!data) {
    return (
      <EmptyData
        error={error}
        loading={loading}
      />
    );
  }

  return (
    <Container>
      <Row>
        <Col md={12}>
          <Card>
            <Card.Body>
              <Form formikForm={formikForm}>
                <h5>
                  {t("purchase_orders_new.order_info")}
                </h5>

                <Row>
                  <Col md="6">
                    <FormInput
                      name="identification"
                      component={TextInput}
                      injectFormupData
                    />
                  </Col>

                  <Col md="6">
                    <FormInput
                      name="code"
                      component={TextInput}
                      injectFormupData
                      type="number"
                      helpText={t("purchase_orders_new.code_help")}
                    />
                  </Col>
                </Row>

                <hr />

                <h5>
                  {t("purchase_orders_new.provider_information")}
                </h5>

                <strong className="mr-2">
                  {t("attributes.provider.name")}
                </strong>
                <p>
                  {priceQuoteProvider?.provider?.name}
                </p>

                <Row>
                  <Col md="6">
                    <strong className="mr-2">
                      {t("attributes.provider.email")}
                    </strong>
                    <p>
                      {priceQuoteProvider?.provider?.email || "-"}
                    </p>
                  </Col>

                  <Col md="6">
                    <strong className="mr-2">
                      {t("attributes.provider.phone")}
                    </strong>
                    <p>
                      {priceQuoteProvider?.provider?.phone || "-"}
                    </p>
                  </Col>
                </Row>

                <hr />

                <FormArrayField name="purchaseOrderItems">
                  {(items) => (
                    <>
                      <Table
                        rowKey="path"
                        hover={false}
                        headers={[
                          {
                            label: t("price_quote_details.table_headers.request"),
                            key: "request",
                            formatter: (_, { value }) => getItemName(value?.item?.priceQuoteItem),
                          },
                          {
                            label: t("price_quote_details.table_headers.type"),
                            key: "type",
                            formatter: (_, { value }) => getItemType(value?.item?.priceQuoteItem),
                          },
                          {
                            label: t("price_quote_details.table_headers.quantity"),
                            key: "item.priceQuoteItem.totalQuantity",
                            formatter: (_, item) => (
                              <FormInput
                                name={item.getPath("finalQuantity")}
                                component={TextInput}
                                injectFormupData
                                type="number"
                                disabled={!item?.value?.item?.available}
                              />
                            ),
                          },
                          {
                            label: t("price_quote_details.table_headers.unit"),
                            key: "unit",
                            formatter: (_, { value }) => getItemUnit(value?.item?.priceQuoteItem),
                          },
                          {
                            label: "Valor",
                            key: "value.item.value",
                            formatter: (value) => (
                              <CurrencyInput
                                value={value}
                                displayType="text"
                              />
                            ),
                          },
                          {
                            label: "IPI",
                            key: "value.item.ipi",
                            formatter: (value) => (
                              <CurrencyInput
                                value={value}
                                displayType="text"
                              />
                            ),
                          },
                          {
                            label: "Desconto",
                            key: "value.item.discount",
                            formatter: (value) => (
                              <CurrencyInput
                                value={value}
                                displayType="text"
                              />
                            ),
                          },
                          {
                            label: "Subtotal",
                            key: "value.item.unitValue",
                            formatter: (unitValue, { value }) => (
                              <CurrencyInput
                                value={parseFloat(unitValue) * parseInt(value?.finalQuantity)}
                                displayType="text"
                              />
                            ),
                          },
                        ]}
                        items={items}
                      >
                        <tr>
                          <td colSpan="7">
                            <div className="d-flex justify-content-end pr-2 text-bold">
                              {t("attributes.price_quote_provider.freight")}
                            </div>
                          </td>
                          <td>
                            <CurrencyInput
                              value={priceQuoteProvider?.freight}
                              displayType="text"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td colSpan="7">
                            <div className="d-flex justify-content-end pr-2 text-bold">
                              {t("attributes.price_quote_provider.icms")}
                            </div>
                          </td>
                          <td>
                            <CurrencyInput
                              value={priceQuoteProvider?.icms}
                              displayType="text"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td colSpan="7">
                            <div className="d-flex justify-content-end pr-2 text-bold">
                              {t("attributes.price_quote_provider.total_value")}
                            </div>
                          </td>
                          <td>
                            <CurrencyInput
                              value={totalValue}
                              displayType="text"
                            />
                          </td>
                        </tr>
                      </Table>
                    </>
                  )}
                </FormArrayField>

                <hr />

                <h5>
                  {t("purchase_orders_new.payment_conditions")}
                </h5>

                <Row>
                  <Col md="4">
                    <strong className="mr-2">
                      {t("attributes.price_quote_provider.payment_method")}
                    </strong>
                    <p>
                      {priceQuoteProvider?.paymentMethod?.name || "-"}
                    </p>
                  </Col>

                  <Col md="4">
                    <strong className="mr-2">
                      {t("attributes.price_quote_provider.number_of_installments")}
                    </strong>
                    <p>
                      {priceQuoteProvider?.numberOfInstallments || "-"}
                    </p>
                  </Col>

                  <Col md="4">
                    <strong className="mr-2">
                      {t("attributes.price_quote_provider.first_due_date")}
                    </strong>
                    <p>
                      {priceQuoteProvider?.firstDueDate}
                    </p>
                  </Col>
                </Row>

                <Button
                  variant="primary"
                  onClick={submitForm}
                  disabled={createPurchaseOrderLoading}
                >
                  {t("actions.save")}
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default PurchaseOrdersNew;
