import React, {
  useState,
  useMemo,
  useEffect,
} from "react";
import { useQuery } from "react-apollo";

import CURRENT_USER_QUERY from "./graphql/currentUserQuery";
import { CurrentUserProvider } from "./CurrentUserContext";

const CurrentUserContainer = ({
  children,
}) => {
  const [currentUser, setCurrentUser] = useState(null);

  const {
    data,
    loading,
    error,
  } = useQuery(CURRENT_USER_QUERY, {
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    setCurrentUser(data?.currentUser);
  }, [data]);

  const contextValue = useMemo(() => [
    currentUser,
    {
      loading,
      error,
    },
  ], [
    currentUser,
    loading,
    error,
  ]);

  return (
    <CurrentUserProvider value={contextValue}>
      {children}
    </CurrentUserProvider>
  );
};

export default CurrentUserContainer;
