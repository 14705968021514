import React, {
  useState,
  useRef,
  useCallback,
  useMemo,
} from "react";
import { useTranslation } from "react-i18next";
import {
  Modal,
  Button,
} from "react-bootstrap";
import {
  FormInput,
  Form,
  useFormup,
} from "@formup/core";
import { useMutation } from "react-apollo";

import maintenanceWorkSchema from "settings/yup/schemas/maintenanceWorkSchema";
import Table from "components/Table/Table";
import CurrencyInput from "components/Inputs/CurrencyInput/CurrencyInput";
import { useSnackbar } from "contexts/snackbar/SnackbarContext";
import { onQueryError } from "utils/queryHandlers";

import MAINTENANCE_ORDER_QUERY from "./graphql/maintenanceOrderQuery";
import UPDATE_MAINTENANCE_WORK_MUTATION from "./graphql/updateMaintenanceWorkMutation";
import getTableHeaders from "./getTableHeaders";

const MaintenanceActivitiesTable = ({
  maintenanceOrderId,
  headers = [],
  items = [],
}) => {
  const notify = useSnackbar();
  const [t] = useTranslation();
  const formRef = useRef(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isPriceModalOpen, setPriceModalOpen] = useState(false);

  const openPriceModal = useCallback((item) => () => {
    if (formRef.current) {
      formRef.current.setFieldValue("id", parseInt(item?.maintenanceWork?.id || 0));
      formRef.current.setFieldValue("totalPrice", (item?.workersCost || ""));
    }

    setPriceModalOpen(true);
  }, [
    setPriceModalOpen,
  ]);

  const closePriceModal = useCallback(() => {
    if (formRef.current) {
      formRef.current.resetForm();
    }

    setPriceModalOpen(false);
  }, [
    setPriceModalOpen,
    formRef,
  ]);

  const [updateMaintenanceWork] = useMutation(UPDATE_MAINTENANCE_WORK_MUTATION);

  const handleSubmitForm = useCallback(({
    id,
    totalPrice,
  }) => {
    updateMaintenanceWork({
      variables: {
        id,
        totalPrice,
      },
      refetchQueries: [
        {
          query: MAINTENANCE_ORDER_QUERY,
          variables: { id: maintenanceOrderId },
        },
      ],
    })
      .then(() => {
        notify.success(t("maintenance_order_summary.workers_cost_successfully_updated"));
        closePriceModal();
      })
      .catch(onQueryError);
  }, [
    t,
    updateMaintenanceWork,
    notify,
    closePriceModal,
    maintenanceOrderId,
  ]);

  const {
    formikForm,
    submitForm,
  } = useFormup(maintenanceWorkSchema, {
    onSubmit: handleSubmitForm,
    transformOnSubmit: true,
    initialValues: {
      id: "",
      totalPrice: "",
    },
  });

  formRef.current = formikForm;

  const openMaterialsModal = useCallback((item) => () => {
    setSelectedItem(item);
  }, [setSelectedItem]);

  const closeMaterialsModal = useCallback(() => {
    setSelectedItem(null);
  }, [setSelectedItem]);

  const tableHeaders = useMemo(() => getTableHeaders({
    headers,
    openPriceModal,
    openMaterialsModal,
  }), [
    openMaterialsModal,
    openPriceModal,
    headers,
  ]);

  return (
    <>
      <Table
        headers={tableHeaders}
        items={items}
      />

      <Modal
        show={!!selectedItem}
        onHide={closeMaterialsModal}
        backdrop="static"
        size="lg"
      >
        <Modal.Header>
          <Modal.Title>
            {t("maintenance_order_summary.used_materials")}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Table
            headers={[
              {
                label: t("attributes.inventory_change.inventory_item"),
                key: "inventoryItem.name",
              },
              {
                label: t("attributes.inventory_change.brand"),
                key: "brand.name",
              },
              {
                label: t("attributes.inventory_change.quantity"),
                key: "quantity",
              },
              {
                label: t("attributes.inventory_change.item_price"),
                key: "itemValue",
                formatter: (value) => <CurrencyInput value={value} displayType="text" />,
              },
            ]}
            items={selectedItem?.maintenanceWork?.inventoryChanges}
          />
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="outline-primary"
            onClick={closeMaterialsModal}
          >
            {t("actions.close")}
          </Button>
        </Modal.Footer>
      </Modal>

      <Form formikForm={formikForm}>
        <Modal
          show={isPriceModalOpen}
          onHide={closePriceModal}
          backdrop="static"
          size="lg"
        >
          <Modal.Header>
            <Modal.Title>
              {t("maintenance_order_summary.update_workers_cost")}
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <FormInput
              name="totalPrice"
              component={CurrencyInput}
              injectFormupData
              helpText={t("maintenance_order_summary.total_price_help")}
            />
          </Modal.Body>

          <Modal.Footer>
            <Button
              variant="outline-primary"
              onClick={closePriceModal}
            >
              {t("actions.close")}
            </Button>

            <Button
              variant="primary"
              onClick={submitForm}
            >
              {t("actions.finish")}
            </Button>
          </Modal.Footer>
        </Modal>
      </Form>
    </>
  );
};

export default MaintenanceActivitiesTable;
