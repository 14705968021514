
import yup from "settings/yup";
import i18n from "translations/i18n";
import selectTransform from "settings/yup/transforms/selectTransform";
import dateTransform from "settings/yup/transforms/dateTransform";
import isValidId from "settings/yup/tests/isValidId";
import validReservedQuantity from "settings/yup/tests/reservedQuantity";

const maintenanceMaterialSchema = yup
  .object()
  .shape({
    id: yup
      .number(),
    inventoryItemId: yup
      .number()
      .required()
      .test(isValidId)
      .transform(selectTransform)
      .label(i18n.t("attributes.maintenance_material.inventory_item")),
    brandId: yup
      .mixed()
      .transform(selectTransform)
      .label(i18n.t("attributes.maintenance_material.brand")),
    quantity: yup
      .number()
      .min(0)
      .required()
      .label(i18n.t("attributes.maintenance_material.quantity")),
    reservedQuantity: yup
      .number()
      .test(validReservedQuantity)
      .label(i18n.t("attributes.maintenance_material.reserved_quantity")),
  });

const activitySchema = yup
  .object()
  .shape({
    id: yup
      .number(),
    serviceItemId: yup
      .number()
      .required()
      .test(isValidId)
      .transform(selectTransform)
      .label(i18n.t("attributes.maintenance_activity.description")),
    maintenanceMaterials: yup
      .array()
      .of(maintenanceMaterialSchema),
  });

const activityOrderSchema = yup
  .object()
  .shape({
    id: yup
      .number(),
    number: yup
      .number()
      .required()
      .label(i18n.t("attributes.maintenance_activity.number")),
    kind: yup
      .string()
      .required()
      .transform(selectTransform)
      .label(i18n.t("attributes.maintenance_activity.kind")),
    maintenanceActivity: activitySchema,
  });

const measurementSchema = yup
  .object()
  .shape({
    m3: yup
      .number()
      .required()
      .label(i18n.t("enums.maintenance_plans.measurement.m3")),
    km: yup
      .number()
      .required()
      .label(i18n.t("enums.maintenance_plans.measurement.km")),
    hour: yup
      .number()
      .required()
      .label(i18n.t("enums.maintenance_plans.measurement.hour")),
    liter: yup
      .number()
      .required()
      .label(i18n.t("enums.maintenance_plans.measurement.liter")),
    day: yup
      .number()
      .required()
      .label(i18n.t("enums.maintenance_plans.measurement.day")),
    planIds: yup
      .array()
      .of(
        yup
          .number()
          .integer(),
      )
      .default([]),
  });

export const maintenanceOrderSchema = yup
  .object()
  .shape({
    code: yup
      .string()
      .label(i18n.t("attributes.maintenance_order.code")),
    description: yup
      .string()
      .required()
      .label(i18n.t("attributes.maintenance_order.description")),
    equipmentId: yup
      .number()
      .required()
      .test(isValidId)
      .transform(selectTransform)
      .label(i18n.t("attributes.maintenance_order.equipment")),
    responsibleId: yup
      .number()
      .required()
      .test(isValidId)
      .transform(selectTransform)
      .label(i18n.t("attributes.maintenance_order.responsible")),
    type: yup
      .string()
      .required()
      .transform(selectTransform)
      .label(i18n.t("attributes.maintenance_order.type")),
    closingDate: yup
      .string()
      .required()
      .transform(dateTransform)
      .label(i18n.t("attributes.maintenance_order.closing_date")),
    comments: yup
      .string()
      .label(i18n.t("attributes.maintenance_order.comments")),
    measurements: measurementSchema,
    activities: yup
      .array()
      .required()
      .of(activityOrderSchema),
  });
