
import isFunction from "lodash.isfunction";

/**
 * Stop event propagation of an event, but calls a callback if provided.
 *
 * Useful when adding clickable elements such as buttons on top of other clickable
 * elements.
 *
 * @param {*} callback
 */
const handleStopPropagation = (callback) => (event) => {
  event.stopPropagation();

  if (callback && isFunction(callback)) {
    callback();
  }
};

export default handleStopPropagation;
