import gql from "graphql-tag";

const DAILY_CHECKLISTS_QUERY = gql`
  query DailyChecklistsQuery {
    dailyChecklists {
      id
      equipment {
        id
        code
        status
        equipmentType {
          id
          name
        }
        equipmentDetail {
          id
          name
        }
      }
      operator {
        id
        name
      }
    }
  }
`;

export default DAILY_CHECKLISTS_QUERY;
