import gql from "graphql-tag";

const MAINTENANCE_ORDER_QUERY = gql`
  query MaintenanceOrderSummaryQuery($id: Int!) {
    maintenanceOrder(id: $id) {
      id
      code
      type
      responsible {
        id
        name
      }
      totalCost
      startTime
      endTime
      closingDate
      activities {
        id
        kind
        number
        status
        maintenanceOrderId
        maintenanceActivity {
          id
          serviceItem {
            id
            description
            serviceItemGroup {
              id
              name
            }
          }
          maintenanceMaterials {
            id
            inventoryItem {
              id
              name
              code
              unit {
                id
                unit
              }
            }
            quantity
          }
        }
        maintenanceWork {
          id
          startTime
          endTime
          operator {
            id
            name
          }
          comments
          inventoryChanges {
            id
            quantity
            itemValue
            inventoryItem {
              id
              code
              name
            }
            brand {
              id
              name
            }
          }
        }
        inventoryCost
        workersCost
        totalCost
      }
    }
  }
`;

export default MAINTENANCE_ORDER_QUERY;
