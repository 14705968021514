import React, { useCallback } from "react";
import { Helmet } from "react-helmet";

import i18n from "translations/i18n";
import appRoutes from "router/appRoutes";
import { useRouteProps } from "contexts/route/RouteContext";
import Footer from "components/Footer/Footer";
import Sidebar from "components/Sidebar/Sidebar";
import AppNavbar from "components/AppNavbar/AppNavbar";

/**
 * App wrapper. Can be used to render Header, Footer, etc.
 * @param {*} param0 options
 */
const AppWrapper = ({
  children,
}) => {
  const {
    name,
    showFooter,
  } = useRouteProps();

  const htmlAttributes = {
    lang: i18n.language,
  };

  const toggleNavbar = useCallback(() => {
    document.body.classList.toggle("sidebar-mini");
  }, []);

  return (
    <div className="wrapper">
      <Helmet htmlAttributes={htmlAttributes} />

      <Sidebar routes={appRoutes.filter((route) => route.showInSidebar)} />

      <div className="main-panel">
        <AppNavbar
          pageName={name}
          handleMiniClick={toggleNavbar}
        />

        <div className="content">
          {children}
        </div>

        {
          showFooter && (
            <Footer />
          )
        }
      </div>
    </div>
  );
};

export default AppWrapper;
