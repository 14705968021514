import React, { useEffect } from "react";

import statuses from "constants/maintenanceStatus";

import Board from "./Board";

const MaintenanceBoards = () => {
  useEffect(() => {
    document.body.classList.add("sidebar-mini");

    return () => {
      document.body.classList.remove("sidebar-mini");
    };
  });

  return (
    <div className="maintenance-boards">
      <div className="boards-wrapper">
        {
          Object.keys(statuses).map((status) => (
            <Board
              key={status}
              status={status}
            />
          ))
        }
      </div>
    </div>
  );
};

export default MaintenanceBoards;
