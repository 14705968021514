import React from "react";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import ptBR from "date-fns/locale/pt-BR";

import BaseInput from "components/Inputs/BaseInput";

const DEFAULT_LOCALE = "pt-BR";

registerLocale(DEFAULT_LOCALE, ptBR);
setDefaultLocale(DEFAULT_LOCALE);

const DateSelector = ({
  value,
  locale = DEFAULT_LOCALE,
  ...props
}) => (
  <BaseInput
    {...props}
    selected={value}
    Component={DatePicker}
    inputClassName="form-control"
    dateFormat="dd/MM/yyyy"
    locale={locale}
  />
);

export default DateSelector;
