import React from "react";
import {
  Container,
  Row,
  Col,
} from "react-bootstrap";
import { useParams } from "react-router-dom";

import EquipmentDetailsCard from "components/EquipmentDetailsCard/EquipmentDetailsCard";

import MaintenancePlansCard from "./MaintenancePlansCard";
import LastMaintenancesCard from "./LastMaintenancesCard";

const Equipments = () => {
  const { id } = useParams();

  return (
    <Container>
      <Row>
        <Col md={12}>
          <EquipmentDetailsCard equipmentId={id} />
        </Col>
      </Row>

      <Row>
        <Col md={12}>
          <MaintenancePlansCard />
        </Col>
      </Row>

      <Row>
        <Col md={12}>
          <LastMaintenancesCard />
        </Col>
      </Row>
    </Container>
  );
};

export default Equipments;
