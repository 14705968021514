/**
 * Converts an unit from the GraphQL query and converts into a form value. (to be used
 * as a Selector.jsx value)
 *
 * @param {object} unit An existing unit
 */
const extractUnitInfo = ({ unit: { unit } }) => ({
  unit,
});

export default extractUnitInfo;
