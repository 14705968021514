import * as R from "ramda";
import update from "immutability-helper";

const filterMaintenanceParams = (params) => {
  const activities = (params.activities || [])
    .map((activity) => (
      update(activity, {
        maintenanceActivity: {
          maintenanceMaterials: {
            $apply: (materials) => (materials || []).map((material) => R.omit(["requestedQuantity"], material)),
          },
        },
      })
    ));

  return {
    ...R.omit(["code"], params),
    activities,
  };
};

export default filterMaintenanceParams;
