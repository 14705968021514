const defaultPaginationOptions = {
  PAGE: 1,
  PER_PAGE: 10,
};

export const PAGINATION_OPTIONS = [
  { value: 10, label: 10 },
  { value: 25, label: 25 },
  { value: 50, label: 50 },
];

export default defaultPaginationOptions;
