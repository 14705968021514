import React from "react";
import Select from "react-select";
import classnames from "classnames";
import { useTranslation } from "react-i18next";

import BaseInput from "components/Inputs/BaseInput";

const OptionSelector = ({
  className,
  ...props
}) => {
  const [t] = useTranslation();
  const fieldClasses = classnames(className, "select");

  return (
    <BaseInput
      {...props}
      className={fieldClasses}
      Component={Select}
      placeholder={t("selector.placeholder")}
    />
  );
};

export default OptionSelector;
