import { ptBR, enUS, de } from "date-fns/locale";

import i18n from "./i18n";

/**
 * Provides the Locale object to translate date-fns functions.
 * Defaults to enUS if not valid.
 */
const getDateFnsLocale = () => ({
  pt: ptBR,
  en: enUS,
  de,
})[i18n.language] || enUS;

export default getDateFnsLocale;
