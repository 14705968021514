import getQuerySelectOptions from "utils/getQuerySelectOptions";

const providerNameFormatter = (provider) => provider.name;

const convertToQuoteProviders = (providers) => {
  const options = getQuerySelectOptions(providers, providerNameFormatter);

  return options.map((option) => ({
    providerId: option,
  }));
};

export default convertToQuoteProviders;
