import gql from "graphql-tag";

const RESEND_EMAIL_MUTATION = gql`
  mutation ResendPriceQuoteProviderEmail($id: Int!) {
    resendPriceQuoteProviderEmail(id: $id) {
      id
    }
  }
`;

export default RESEND_EMAIL_MUTATION;
