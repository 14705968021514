export const AWAITING_MATERIALS = "AWAITING_MATERIALS";
export const READY_TO_START = "READY_TO_START";
export const IN_EXECUTION = "IN_EXECUTION";
export const FINISHED = "FINISHED";

export default {
  AWAITING_MATERIALS,
  READY_TO_START,
  IN_EXECUTION,
  FINISHED,
};
