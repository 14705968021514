import { useCallback, useMemo } from "react";
import {
  useQueryParam,
  NumberParam,
  withDefault,
} from "use-query-params";

import defaultPaginationOptions from "constants/defaultPaginationOptions";

const usePagination = () => {
  const [page, setPage] = useQueryParam("page", withDefault(NumberParam, defaultPaginationOptions.PAGE));
  const [perPage, setPerPage] = useQueryParam("per_page", withDefault(NumberParam, defaultPaginationOptions.PER_PAGE));

  const handlePerPageChange = useCallback(({ value }) => {
    setPerPage(value);
    setPage(1);
  }, [
    setPage,
    setPerPage,
  ]);

  const payload = useMemo(() => ({
    page,
    setPage,
    perPage,
    handlePerPageChange,
  }), [
    handlePerPageChange,
    perPage,
    setPage,
    page,
  ]);

  return payload;
};

export default usePagination;
