import React from "react";
import {
  Card,
  Table,
} from "react-bootstrap";
import { useQuery } from "react-apollo";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { EQUIPMENTS_PAGE_LOCATION } from "router/locations";
import EmptyData from "components/EmptyData/EmptyData";

import EQUIPMENTS_NEXT_MAINTENANCE_PLANS_QUERY from "./graphql/equipmentsNextMaintenancePlansQuery";

const MaintenancePlansCard = () => {
  const [t] = useTranslation();

  const {
    data,
    loading,
    error,
  } = useQuery(EQUIPMENTS_NEXT_MAINTENANCE_PLANS_QUERY);

  if (!data) {
    return (
      <EmptyData
        error={error}
        loading={loading}
      />
    );
  }

  return (
    <Card>
      <Card.Body>
        <h4 className="card-title">{t("dashboard.maintenance_plans.title")}</h4>

        <Table hover responsive>
          <thead>
            <tr>
              <th>{t("attributes.shared.equipment_code")}</th>
              <th>{t("dashboard.maintenance_plans.expired_maintenances")}</th>
              <th>{t("dashboard.maintenance_plans.expiring_maintenances")}</th>
              <th className="text-center">
                {t("dashboard.maintenance_plans.details")}
              </th>
            </tr>
          </thead>
          <tbody>
            {
              data.equipmentsNextMaintenancePlans.length
                ? (
                  data.equipmentsNextMaintenancePlans.map(({
                    id,
                    code,
                    expiredMaintenances,
                    expiringMaintenances,
                  }) => (
                    <tr key={id}>
                      <td>
                        {code}
                      </td>
                      <td>
                        {expiredMaintenances}
                      </td>
                      <td>
                        {expiringMaintenances}
                      </td>
                      <td className="text-center">
                        <Link to={EQUIPMENTS_PAGE_LOCATION.toUrl({ id })}>
                          {t("actions.see_details")}
                        </Link>
                      </td>
                    </tr>
                  ))
                )
                : (
                  <tr>
                    <td className="text-center" colSpan="5">
                      {t("dashboard.maintenance_plans.empty_data")}
                    </td>
                  </tr>
                )
            }
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
};

export default MaintenancePlansCard;
