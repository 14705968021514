import React from "react";
import { ListGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import MaintenanceActivity from "./MaintenanceActivity";

const MaintenanceActivities = ({
  activities,
}) => {
  const [t] = useTranslation();

  return (
    <>
      <h5>{t("maintenance_order_details.maintenance_activities.title")}</h5>

      {
        activities?.length
          ? (
            <ListGroup variant="flush">
              {
                activities.map((activity) => (
                  <ListGroup.Item key={activity.id}>
                    <MaintenanceActivity activity={activity} />
                  </ListGroup.Item>
                ))
              }
            </ListGroup>
          )
          : (
            <p>{t("maintenance_order_details.maintenance_activities.empty_data")}</p>
          )
      }
    </>
  );
};

export default MaintenanceActivities;
