import React, { useState, useCallback, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useQuery } from "react-apollo";
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  Badge,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import useSelectedItems from "use-selected-items-hook";

import EmptyData from "components/EmptyData/EmptyData";
import Tooltip from "components/Tooltip/Tooltip";
import Table from "components/Table/Table";
import {
  getItemName,
  getItemType,
  getItemUnit,
  getItemBrand,
} from "views/PurchaseRequests/getTableHeaders";
import CurrencyInput from "components/Inputs/CurrencyInput/CurrencyInput";
import { useSnackbar } from "contexts/snackbar/SnackbarContext";
import { PURCHASE_ORDERS_NEW_PATH } from "router/routes";
import ReadMoreText from "components/ReadMoreText";

import PRICE_QUOTE_COMPARE_QUERY from "./graphql/priceQuoteCompareQuery";

const PriceQuoteCompare = () => {
  const params = useParams();
  const id = parseInt(params?.id);
  const [t] = useTranslation();
  const [selectedItem, setSelectedItem] = useState(null);
  const notify = useSnackbar();
  const history = useHistory();

  const {
    data,
    loading,
    error,
  } = useQuery(PRICE_QUOTE_COMPARE_QUERY, {
    variables: {
      id,
    },
    skip: !id,
  });

  const priceQuote = data?.priceQuote || {};

  const initialItems = priceQuote?.priceQuoteProviders;

  const {
    items,
    selectedItems,
    toggleSingleItem,
  } = useSelectedItems({
    initialItems,
    itemIdentifierKey: "id",
  });

  const toggleCompare = useCallback((itemId) => () => {
    toggleSingleItem(itemId);
  }, [
    toggleSingleItem,
  ]);

  const toggleSelectedItem = useCallback((item) => () => {
    if (selectedItem?.id === item.id) {
      setSelectedItem(null);
    } else {
      setSelectedItem(item);
    }
  }, [
    selectedItem,
    setSelectedItem,
  ]);

  const handleCreatePurchaseOrder = () => {
    if (!selectedItem) {
      notify.error(t("price_quote_compare.quotation_is_required"));
      return;
    }

    if (selectedItem.priceQuoteProviderItems.every(({ available }) => !available)) {
      notify.error(t("price_quote_compare.all_items_are_unavailable"));
      return;
    }

    history.push({
      pathname: PURCHASE_ORDERS_NEW_PATH,
      state: {
        quotationId: selectedItem?.id,
      },
    });
  };

  useEffect(() => {
    if (selectedItem !== null) {
      const isComparing = !!selectedItems
        .find((comparedItem) => comparedItem.id === selectedItem.id);

      if (!isComparing) {
        toggleSelectedItem(selectedItem)();
      }
    }
  }, [
    selectedItems,
    selectedItem,
    toggleSelectedItem,
  ]);

  const handleSelectBestBuy = useCallback((item) => () => {
    if (!item?.answeredAt) {
      return;
    }

    const isComparing = !!selectedItems.find((comparedItem) => comparedItem.id === item.id);

    if (!isComparing) {
      toggleSingleItem(item.id);
    }

    if (selectedItem?.id !== item.id) {
      toggleSelectedItem(item)();
      notify.success(t("price_quote_compare.suggestion_selected"));
    } else {
      notify.warning(t("price_quote_compare.suggestion_already_selected"));
    }
  }, [
    t,
    notify,
    selectedItem,
    selectedItems,
    toggleSingleItem,
    toggleSelectedItem,
  ]);

  if (!data) {
    return (
      <EmptyData
        error={error}
        loading={loading}
      />
    );
  }

  return (
    <>
      <Container fluid>
        <Row>
          <Col md={12}>
            <Card>
              <Card.Body>
                <h4 className="card-title">
                  {t("price_quote_details.code", { code: priceQuote?.code })}
                </h4>

                <h5>
                  {t("price_quote_compare.providers_answers")}
                </h5>
                <div>
                  {
                    items.map((provider) => (
                      <Tooltip
                        text={(provider.isSelected
                          ? t("price_quote_compare.actions.remove_from_compare")
                          : t("price_quote_compare.actions.add_to_compare")
                        )}
                        key={provider.id}
                      >
                        <Button
                          size="sm"
                          variant={provider.isSelected ? "primary" : "outline-primary"}
                          onClick={toggleCompare(provider.id)}
                        >
                          {provider?.provider?.name}
                        </Button>
                      </Tooltip>
                    ))
                  }
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>

      <div className="price-quote-compare">
        <div className="compare-header">
          <Card>
            <Card.Body>
              <h5>
                {t("price_quote_compare.quotation_items")}
              </h5>

              <Table
                headers={[
                  {
                    label: t("purchase_requests.table_headers.request"),
                    key: "request",
                    formatter: (_, item) => getItemName(item),
                  },
                  {
                    label: t("purchase_requests.table_headers.brand"),
                    key: "brand",
                    formatter: (_, item) => getItemBrand(item),
                  },
                  {
                    label: t("purchase_requests.table_headers.type"),
                    key: "type",
                    formatter: (_, item) => getItemType(item),
                  },
                  {
                    label: t("purchase_requests.table_headers.quantity"),
                    key: "totalQuantity",
                  },
                  {
                    label: t("purchase_requests.table_headers.unit"),
                    key: "unit",
                    formatter: (_, item) => getItemUnit(item),
                  },
                ]}
                items={priceQuote?.priceQuoteItems}
              />

              <div className="d-flex flex-row justify-content-between align-items-center">
                <h5>
                  {t("price_quote_compare.best_buy")}
                </h5>

                <Button
                  disabled={!priceQuote?.bestQuotation?.answeredAt}
                  onClick={handleSelectBestBuy(priceQuote?.bestQuotation)}
                  size="sm"
                  variant="outline-primary"
                >
                  {t("price_quote_compare.select_suggestion")}
                </Button>
              </div>

              <strong>
                {priceQuote?.bestQuotation?.provider?.name}
              </strong>

              <ul>
                <li>
                  {t("price_quote_compare.smaller_price")}
                </li>
                <li>
                  {t("price_quote_compare.most_items_available")}
                </li>
              </ul>
            </Card.Body>
          </Card>
        </div>

        <div className="compare-container">
          {
            selectedItems.map((provider) => (
              <Card
                key={provider.id}
                className={`clickable ${(provider.id === selectedItem?.id ? "selected" : "")}`}
                onClick={toggleSelectedItem(provider)}
              >
                <Card.Body>
                  <h5>
                    {provider?.provider?.name}
                  </h5>

                  <Table
                    headers={[
                      {
                        label: t("attributes.price_quote_provider_item.unit_value"),
                        key: "unitValue",
                        formatter: (value, item) => (
                          <>
                            {
                              item.available
                                ? (
                                  <CurrencyInput
                                    value={value}
                                    displayType="text"
                                  />
                                )
                                : (
                                  <Badge variant="danger">
                                    {t("price_quote_compare.not_available")}
                                  </Badge>
                                )
                            }
                          </>
                        ),
                      },
                      {
                        label: t("attributes.price_quote_provider_item.provider_brand"),
                        key: "providerBrand",
                      },
                      {
                        label: t("attributes.price_quote_provider_item.total_value"),
                        key: "totalValue",
                        formatter: (value, item) => (
                          <>
                            {item.available && (
                              <CurrencyInput
                                value={value}
                                displayType="text"
                              />
                            )}
                          </>
                        ),
                      },
                    ]}
                    items={provider?.priceQuoteProviderItems}
                  />

                  <ul className="compare-info">
                    <li>
                      <span className="compare-label">
                        {`${t("attributes.price_quote_provider.freight")}:`}
                      </span>
                      <CurrencyInput
                        value={provider?.freight}
                        displayType="text"
                      />
                    </li>
                    <li>
                      <span className="compare-label">
                        {`${t("attributes.price_quote_provider.icms")}:`}
                      </span>
                      <CurrencyInput
                        value={provider?.icms}
                        displayType="text"
                      />
                    </li>
                    <li>
                      <span className="compare-label">
                        {`${t("attributes.price_quote_provider.total_value")}:`}
                      </span>
                      <CurrencyInput
                        value={provider?.totalValue}
                        displayType="text"
                      />
                    </li>
                    <li>
                      <span className="compare-label">
                        {`${t("attributes.price_quote_provider.estimated_delivery_date")}:`}
                      </span>
                      {provider?.estimatedDeliveryDate}
                    </li>
                    <li>
                      <span className="compare-label">
                        {`${t("attributes.price_quote_provider.payment_method")}:`}
                      </span>
                      {provider?.paymentMethod?.name}
                    </li>
                    <li>
                      <span className="compare-label">
                        {`${t("attributes.price_quote_provider.first_due_date")}:`}
                      </span>
                      {provider?.firstDueDate}
                    </li>
                    <li>
                      <span>
                        {`${t("attributes.price_quote_provider.observations")}:`}
                      </span>

                      <ReadMoreText text={provider?.observations} />
                    </li>
                  </ul>
                </Card.Body>
              </Card>
            ))
          }
        </div>
      </div>

      <Container fluid>
        <Row>
          <Col>
            <Button onClick={handleCreatePurchaseOrder}>
              {t("price_quote_compare.create_purchase_order")}
            </Button>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default PriceQuoteCompare;
