import gql from "graphql-tag";

const SERVICE_ITEM_GROUPS_QUERY = gql`
  query ServiceItemGroups($search: String) {
    serviceItemGroups(search: $search,) {
      id
      name
    }
  }
`;

export default SERVICE_ITEM_GROUPS_QUERY;
