import {
  DASHBOARD_PAGE_PATH,
  EQUIPMENTS_PAGE_PATH,
  MAINTENANCES_PATH,
  MAINTENANCES_BOARDS_PATH,
  MAINTENANCES_NEW_PATH,
  MAINTENANCES_EDIT_PATH,
  MAINTENANCES_DETAIL_PATH,
  MAINTENANCES_SUMMARY_PATH,
  DAILY_CHECKLISTS_PAGE_PATH,
  MAINTENANCE_ACTIVITIES_PATH,
  PURCHASE_REQUESTS_PATH,
  PRICE_QUOTES_NEW_PATH,
  PRICE_QUOTES_DETAIL_PATH,
  PRICE_QUOTES_PATH,
  PRICE_QUOTES_COMPARE_PATH,
  PURCHASE_ORDERS_NEW_PATH,
  PURCHASE_ORDERS_DETAIL_PATH,
} from "router/routes";
import Dashboard from "views/Dashboard/Dashboard";
import EquipmentDetails from "views/EquipmentDetails/EquipmentDetails";
import DailyChecklistDetails from "views/DailyChecklistDetails/DailyChecklistDetails";
import MaintenanceOrderDetails from "views/MaintenanceOrderDetails/MaintenanceOrderDetails";
import MaintenanceOrderSummary from "views/MaintenanceOrderSummary/MaintenanceOrderSummary";
import MaintenanceBoards from "views/MaintenanceBoards/MaintenanceBoards";
import MaintenanceOrders from "views/MaintenanceOrders/MaintenanceOrders";
import MaintenanceNew from "views/Maintenances/MaintenanceNew";
import MaintenanceEdit from "views/Maintenances/MaintenanceEdit";
import MaintenanceActivityDetails from "views/MaintenanceActivityDetails/MaintenanceActivityDetails";
import PurchaseRequests from "views/PurchaseRequests/PurchaseRequests";
import PriceQuoteNew from "views/PriceQuotes/PriceQuoteNew";
import PriceQuoteDetails from "views/PriceQuoteDetails/PriceQuoteDetails";
import PriceQuotes from "views/PriceQuotes/PriceQuotes";
import PriceQuoteCompare from "views/PriceQuoteCompare/PriceQuoteCompare";
import PurchaseOrdersNew from "views/PurchaseOrders/PurchaseOrdersNew";
import PurchaseOrdersDetails from "views/PurchaseOrders/PurchaseOrdersDetails";
import i18n from "translations/i18n";
import permissions from "constants/permissions.json";

const defaultRouteProps = {
  exact: true,
  strict: false,
  showInSidebar: true,

  /**
   * Defines if the footer will render in this route.
   */
  showFooter: true,

  /**
   * Props that will be injected into the rendered component.
   * Can be used with the useRouteComponentProps hook.
   */
  componentProps: {},
};

const appRoutes = [
  {
    ...defaultRouteProps,
    path: DASHBOARD_PAGE_PATH,
    component: Dashboard,
    name: i18n.t("dashboard.title"),
    icon: "nc-icon nc-sound-wave",
    permission: permissions.DASHBOARD_MAINTENANCE_ORDERS,
  },
  {
    ...defaultRouteProps,
    path: EQUIPMENTS_PAGE_PATH,
    component: EquipmentDetails,
    name: i18n.t("equipments.title"),
    showInSidebar: false,
  },
  {
    ...defaultRouteProps,
    path: DAILY_CHECKLISTS_PAGE_PATH,
    component: DailyChecklistDetails,
    name: i18n.t("daily_checklists.title"),
    showInSidebar: false,
  },
  {
    ...defaultRouteProps,
    path: MAINTENANCES_BOARDS_PATH,
    component: MaintenanceBoards,
    name: i18n.t("maintenance_boards.title"),
    icon: "nc-icon nc-layout-11",
    permission: permissions.BOARDS_MAINTENANCE_ORDERS,
  },
  {
    ...defaultRouteProps,
    path: MAINTENANCES_NEW_PATH,
    component: MaintenanceNew,
    name: i18n.t("new_maintenance.title"),
    icon: "nc-icon nc-simple-add",
    permission: permissions.CREATE_MAINTENANCE_ORDERS,
  },
  {
    ...defaultRouteProps,
    path: MAINTENANCES_EDIT_PATH,
    component: MaintenanceEdit,
    name: i18n.t("edit_maintenance.title"),
    showInSidebar: false,
  },
  {
    ...defaultRouteProps,
    path: MAINTENANCES_SUMMARY_PATH,
    component: MaintenanceOrderSummary,
    name: i18n.t("maintenance_order_summary.title"),
    showInSidebar: false,
  },
  {
    ...defaultRouteProps,
    path: MAINTENANCES_DETAIL_PATH,
    component: MaintenanceOrderDetails,
    name: i18n.t("maintenance_order_details.title"),
    showInSidebar: false,
  },
  {
    ...defaultRouteProps,
    path: MAINTENANCES_PATH,
    component: MaintenanceOrders,
    name: i18n.t("maintenances.title"),
    icon: "nc-icon nc-single-copy-04",
    permission: permissions.READ_MAINTENANCE_ORDERS,
  },
  {
    ...defaultRouteProps,
    path: MAINTENANCE_ACTIVITIES_PATH,
    component: MaintenanceActivityDetails,
    name: i18n.t("maintenance_activity_details.title"),
    showInSidebar: false,
  },
  {
    ...defaultRouteProps,
    path: PURCHASE_REQUESTS_PATH,
    component: PurchaseRequests,
    name: i18n.t("purchase_requests.title"),
    icon: "nc-icon nc-cart-simple",
    permission: permissions.CREATE_PRICE_QUOTES,
  },
  {
    ...defaultRouteProps,
    path: PRICE_QUOTES_NEW_PATH,
    component: PriceQuoteNew,
    name: i18n.t("price_quotes_new.title"),
    showInSidebar: false,
  },
  {
    ...defaultRouteProps,
    path: PRICE_QUOTES_COMPARE_PATH,
    component: PriceQuoteCompare,
    name: i18n.t("price_quote_compare.title"),
    showInSidebar: false,
  },
  {
    ...defaultRouteProps,
    path: PRICE_QUOTES_DETAIL_PATH,
    component: PriceQuoteDetails,
    name: i18n.t("price_quote_details.title"),
    showInSidebar: false,
  },
  {
    ...defaultRouteProps,
    path: PRICE_QUOTES_PATH,
    component: PriceQuotes,
    name: i18n.t("price_quotes.title"),
    icon: "nc-icon nc-send",
    permission: permissions.CREATE_PRICE_QUOTES,
  },
  {
    ...defaultRouteProps,
    path: PURCHASE_ORDERS_NEW_PATH,
    component: PurchaseOrdersNew,
    name: i18n.t("purchase_orders_new.title"),
    showInSidebar: false,
  },
  {
    ...defaultRouteProps,
    path: PURCHASE_ORDERS_DETAIL_PATH,
    component: PurchaseOrdersDetails,
    name: i18n.t("purchase_orders_details.title"),
    showInSidebar: false,
  },
];

export default appRoutes;
