import i18n from "translations/i18n";
import yup from "settings/yup";

const maintenanceWorkSchema = yup.object().shape({
  id: yup
    .number()
    .integer()
    .required(),
  totalPrice: yup
    .number()
    .min(0)
    .required()
    .label(i18n.t("attributes.maintenance_activity.total_price")),
});

export default maintenanceWorkSchema;
