import React from "react";
import { Button } from "react-bootstrap";
import {
  MdMailOutline,
  MdInfo,
  MdDrafts,
} from "react-icons/md";

import i18n from "translations/i18n";
import Tooltip from "components/Tooltip/Tooltip";
import { getItemName, getItemType, getItemUnit } from "views/PurchaseRequests/getTableHeaders";
import { formatDate, dateTimeMask } from "utils/dateFormats";

export const getRequestTableHeaders = () => ([
  {
    label: i18n.t("price_quote_details.table_headers.request"),
    key: "request",
    formatter: (_, item) => getItemName(item),
  },
  {
    label: i18n.t("price_quote_details.table_headers.code"),
    key: "code",
    formatter: (_, item) => item?.inventoryItem?.code,
  },
  {
    label: i18n.t("price_quote_details.table_headers.type"),
    key: "type",
    formatter: (_, item) => getItemType(item),
  },
  {
    label: i18n.t("price_quote_details.table_headers.group"),
    key: "group",
    formatter: (_, item) => item?.serviceItem?.serviceItemGroup?.name,
  },
  {
    label: i18n.t("price_quote_details.table_headers.quantity"),
    key: "totalQuantity",
  },
  {
    label: i18n.t("price_quote_details.table_headers.unit"),
    key: "unit",
    formatter: (_, item) => getItemUnit(item),
  },
]);

export const getProviderTableHeaders = (handleResendEmail) => ([
  {
    label: i18n.t("attributes.price_quote_provider.name"),
    key: "provider.name",
  },
  {
    label: i18n.t("attributes.provider.phone"),
    key: "provider.phone",
  },
  {
    label: i18n.t("attributes.provider.email"),
    key: "provider.email",
  },
  {
    label: i18n.t("attributes.price_quote_provider.sent_at"),
    key: "lastSentAt",
    formatter: (date) => formatDate(date, dateTimeMask),
  },
  {
    label: i18n.t("attributes.price_quote_provider.answered_at"),
    key: "answeredAt",
    formatter: (date) => formatDate(date, dateTimeMask),
  },
  {
    label: i18n.t("actions.actions"),
    key: "actions",
    formatter: (_, item) => (
      <div className="d-flex justify-content-center align-items-center">
        <Tooltip text={(
          item.answeredAt
            ? i18n.t("price_quote_details.delete_and_resend_email")
            : i18n.t("price_quote_details.resend_email")
        )}
        >
          <Button
            variant="light"
            className="btn-simple p-0"
            size="sm"
            onClick={handleResendEmail(item)}
          >
            {
              item.answeredAt
                ? <MdDrafts size="1.5em" />
                : <MdMailOutline size="1.5em" />
            }
          </Button>
        </Tooltip>
      </div>
    ),
  },
]);

export const getPurchaseOrderHeaders = (seePurchaseOrder) => ([
  {
    label: i18n.t("price_quote_details.table_headers.code"),
    key: "code",
  },
  {
    label: i18n.t("price_quote_details.table_headers.identification"),
    key: "identification",
  },
  {
    label: i18n.t("price_quote_details.table_headers.status"),
    key: "status",
    formatter: (value) => i18n.t(`enums.purchase_orders.status.${value}`),
  },
  {
    label: i18n.t("actions.actions"),
    key: "actions",
    formatter: (_, item) => (
      <div className="d-flex justify-content-center align-items-center">
        <Tooltip text={i18n.t("actions.see_details")}>
          <Button
            variant="light"
            className="btn-simple p-0"
            size="sm"
            onClick={seePurchaseOrder(item)}
          >
            <MdInfo size="1.5em" />
          </Button>
        </Tooltip>
      </div>
    ),
  },
]);
