/**
 * Defines how many decimal places we should use for each measurement.
 *
 * NOTE: these measurements came in portuguese from backend and it's the same
 * we use to display it on screen.
 */
const formats = {
  dia: 0,
  hora: 1,
  km: 0,
  litro: 1,
  m3: 1,
};

const decimalFormatter = (value, precision = 0) => parseFloat(value || 0).toFixed(precision);

export const measurementFormatter = (value, measurement) => {
  const decimals = formats[measurement] || 0;

  return decimalFormatter(value, decimals);
};

export default decimalFormatter;
