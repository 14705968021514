import gql from "graphql-tag";

const PURCHASE_REQUEST_ITEMS = gql`
  query PurchaseRequestItems($filters: PurchaseRequestItemsFilters, $pagination: PaginationParams) {
    countPurchaseRequestItems(filters: $filters)
    purchaseRequestItems(filters: $filters, pagination: $pagination) {
      id
      quantity
      status
      inventoryItem {
        id
        name
        code
        unit {
          id
          unit
        }
      }
      brand {
        id
        name
      }
      serviceItem {
        id
        description
      }
      purchaseRequest {
        id
        createdAt
        responsible {
          id
          name
        }
      }
    }
  }
`;

export default PURCHASE_REQUEST_ITEMS;
