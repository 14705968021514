
import selectTransform from "settings/yup/transforms/selectTransform";
import i18n from "translations/i18n";
import yup from "settings/yup";

const schema = yup.object().shape({
  comments: yup
    .string()
    .label(i18n.t("attributes.maintenance_activity.comments")),
  inventoryChanges: yup
    .array()
    .of(
      yup
        .object()
        .shape({
          materialId: yup
            .mixed(),
          inventoryItemId: yup
            .mixed()
            .transform(selectTransform)
            .label(i18n.t("attributes.inventory_change.inventory_item")),
          brandId: yup
            .mixed()
            .transform(selectTransform)
            .label(i18n.t("attributes.inventory_change.brand")),
          quantity: yup
            .number()
            .required()
            .label(i18n.t("attributes.inventory_change.quantity")),
        }),
    ),
});

export default schema;
