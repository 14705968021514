import React, {
  useState, useRef, useCallback, useMemo,
} from "react";
import update from "immutability-helper";
import Toast from "react-bootstrap/Toast";
import { useTranslation } from "react-i18next";

import getRandomString from "utils/getRandomString";

import { SnackbarProvider } from "./SnackbarContext";
import NOTIFICATION_TYPE from "./notificationType";

const ICONS = {
  [NOTIFICATION_TYPE.success]: "fa fa-check-circle",
  [NOTIFICATION_TYPE.error]: "fa fa-times-circle",
  [NOTIFICATION_TYPE.warning]: "fa fa-warning",
  [NOTIFICATION_TYPE.info]: "fa fa-info",
};

// eslint-disable-next-line import/no-mutable-exports
export let notify = null;

const SnackbarContainer = ({
  children,
}) => {
  const [t] = useTranslation();
  const [notifications, setNotifications] = useState([]);

  const notificationsRef = useRef(notifications);
  notificationsRef.current = notifications;

  const handleClose = useCallback((id) => {
    const index = notificationsRef.current.findIndex(x => x.id === id);

    if (index !== -1) {
      setNotifications(
        update(
          notificationsRef.current,
          {
            $splice: [[index, 1]],
          },
        ),
      );
    }
  }, []);

  const fireNotification = useCallback((
    type,
    message,
    timeout = 4000,
  ) => {
    const notification = {
      id: getRandomString(),
      variant: type,
      message,
    };

    setNotifications(update(notificationsRef.current, {
      $push: [notification],
    }));

    if (timeout > 0) {
      setTimeout(() => {
        handleClose(notification.id);
      }, timeout);
    }
  }, [
    handleClose,
  ]);

  notify = useMemo(() => (
    Object.keys(NOTIFICATION_TYPE)
      .map((key) => NOTIFICATION_TYPE[key])
      .map((type) => ({
        [type]: (message, timeout) => {
          fireNotification(type, message, timeout);
        },
      }))
      .reduce((prev, curr) => ({
        ...prev,
        ...curr,
      }), {})
  ), [fireNotification]);

  return (
    <SnackbarProvider value={notify}>
      <div id="snackbar-container">
        {
          notifications.map(notification => (
            <Toast
              key={notification.id}
              onClose={() => {
                handleClose(notification.id);
              }}
            >
              <Toast.Header>
                <div className={`${notification.variant} title`}>
                  <i className={ICONS[notification.variant]} />
                  <span>{t(`snackbar.${notification.variant}`)}</span>
                </div>
              </Toast.Header>
              <Toast.Body>
                {notification.message}
              </Toast.Body>
            </Toast>
          ))
        }
      </div>
      {children}
    </SnackbarProvider>
  );
};

export default SnackbarContainer;
