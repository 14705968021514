import React from "react";

import Tooltip from "components/Tooltip/Tooltip";
import CurrencyInput from "components/Inputs/CurrencyInput/CurrencyInput";
import i18n from "translations/i18n";
import EndActivityAction from "components/EndActivityAction/EndActivityAction";
import { FINISHED } from "constants/maintenanceActivityStatus";
import { EXTERNAL } from "constants/maintenanceActivityKinds";

const getTableHeaders = ({
  headers,
  openPriceModal,
  openMaterialsModal,
}) => [
  ...headers,
  {
    label: i18n.t("attributes.maintenance_activity.inventory_price"),
    key: "inventoryCost",
    formatter: (value, item) => {
      const text = i18n.t("maintenance_order_summary.see_used_materials");

      return (
        <Tooltip text={text}>
          {
            item.status === FINISHED
              ? (
                <CurrencyInput
                  value={value}
                  displayType="text"
                  className="clickable primary"
                  onClick={openMaterialsModal(item)}
                />
              )
              : (
                <EndActivityAction
                  activity={item}
                  currentKind={EXTERNAL}
                />
              )
          }
        </Tooltip>
      );
    },
  },
  {
    label: i18n.t("attributes.maintenance_activity.workers_price"),
    key: "workersCost",
    formatter: (value, item) => (
      <Tooltip text={i18n.t("maintenance_order_summary.update_workers_cost")}>
        <CurrencyInput
          value={value}
          displayType="text"
          className="clickable primary"
          onClick={openPriceModal(item)}
        />
      </Tooltip>
    ),
  },
  {
    label: i18n.t("attributes.maintenance_activity.total_price"),
    key: "totalCost",
    formatter: (value) => <CurrencyInput value={value} displayType="text" />,
  },
];

export default getTableHeaders;
