import React from "react";
import {
  Row,
  Col,
  Button,
} from "react-bootstrap";
import { MdDelete } from "react-icons/md";
import { useTranslation } from "react-i18next";
import {
  FormInput,
  FormArrayField,
} from "@formup/core";

import OptionSelector from "components/Inputs/OptionSelector/OptionSelector";
import TextInput from "components/Inputs/TextInput/TextInput";
import { maintenanceActivityKindOptions } from "constants/maintenanceActivityKinds";
import SideModal from "components/SideModal";

import MaintenanceMaterialsForm from "./maintenanceMaterials/MaintenanceMaterialsForm";

const MaintenanceActivityModal = ({
  activity,
  onModalClose,
  onRemove,
}) => {
  const [t] = useTranslation();

  const deleteButton = activity && (
    <Button
      variant="light"
      className="btn-simple"
      onClick={onRemove(activity.index)}
    >
      <MdDelete size="2em" />
    </Button>
  );

  return (
    <SideModal
      isOpen={!!activity}
      onModalClose={onModalClose}
      headerChildren={deleteButton}
    >
      {
        activity && (
          <>
            <Row className="model-details">
              <Col md={8}>
                <span>{t("attributes.maintenance_activity.description")}</span>
                <h5>{activity?.value?.maintenanceActivity?.serviceItemId?.description}</h5>
              </Col>

              <Col md={4}>
                <span>{t("attributes.maintenance_activity.group")}</span>
                <h5>
                  {activity?.value?.maintenanceActivity?.serviceItemId?.serviceItemGroup?.name}
                </h5>
              </Col>
            </Row>

            <Row>
              <Col md={4}>
                <FormInput
                  component={OptionSelector}
                  options={maintenanceActivityKindOptions}
                  injectFormupData
                  name={activity.getPath("kind")}
                />
              </Col>

              <Col md={4}>
                <FormInput
                  component={TextInput}
                  injectFormupData
                  name={activity.getPath("number")}
                />
              </Col>
            </Row>

            <FormArrayField name={activity.getPath("maintenanceActivity.maintenanceMaterials")}>
              <MaintenanceMaterialsForm />
            </FormArrayField>
          </>
        )
      }
    </SideModal>
  );
};

export default MaintenanceActivityModal;
